import * as React from "react";

const MicOn = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.499 14.637a3.236 3.236 0 0 0 2.266-.922 3.119 3.119 0 0 0 .94-2.234l.011-6.31a3.15 3.15 0 0 0-.941-2.242A3.236 3.236 0 0 0 11.502 2c-.853 0-1.67.334-2.273.929a3.15 3.15 0 0 0-.941 2.243v6.31c.002.838.342 1.641.944 2.233a3.228 3.228 0 0 0 2.267.922Zm5.678-3.156a5.403 5.403 0 0 1-.464 2.093 5.465 5.465 0 0 1-1.244 1.755c-.527.499-1.15.89-1.831 1.15a5.605 5.605 0 0 1-2.139.366 5.605 5.605 0 0 1-2.139-.366 5.55 5.55 0 0 1-1.831-1.15 5.465 5.465 0 0 1-1.244-1.755 5.404 5.404 0 0 1-.464-2.093H4a7.297 7.297 0 0 0 1.902 4.696 7.48 7.48 0 0 0 4.526 2.371V22h2.144v-3.452a7.48 7.48 0 0 0 4.526-2.371A7.298 7.298 0 0 0 19 11.48h-1.823Z"
      fill="#fff"
    />
  </svg>
);

export default MicOn;
