import React, { useEffect, useMemo, useState } from "react";
import MeetingContainer from "./meetingContainer/MeetingContainer";
import { MeetingProvider } from "@videosdk.live/react-sdk";
import {
  MeetingAppProvider,
  meetingLayoutPriorities,
  meetingLayouts,
  meetingLayoutTopics,
  appThemes,
} from "./MeetingAppContextDef";
import JoinMeeting from "./components/JoinScreen";
import ClickAnywhereToContinue from "./components/ClickAnywhereToContinue";
import { Box, CircularProgress, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import MeetingLeftScreen from "./components/MeetingLeftScreen";
import ConfirmBox from "./components/ConfirmBox";
import {
  maxParticipantGridCount_large_desktop,
  maxParticipantGridCount_desktop,
  maxParticipantGridCount_tab,
  maxParticipantGridCount_mobile,
} from "./utils/common";
import useIsSMDesktop from "./utils/useIsSMDesktop";
import useIsLGDesktop from "./utils/useIsLGDesktop";
import useIsTab from "./utils/useIsTab";
// import { version as prebuiltSDKVersion } from "../package.json";
import { version as prebuiltSDKVersion } from "../../package.json";
import { meetingModes } from "./CONSTS";
import animationData from "./animations/meeting-left.json";
import lightThemeAnimationData from "./animations/meeting_left_white.json";
// import {mainURL}  from './CommonService'
import { mainURL } from "../Service/CommonService";
//import { getprofile, getAuth } from "../../Service/Helper";
import { getprofile, getAuth } from "../Service/Helper";
import InterpreterFeedbackform from "../InterpreterFeedbackComponent/InterpreterFeedbackform";
import { Navigate ,useNavigate} from "react-router-dom";

const VideosdkApp = (props) => {
  const [meetingIdValidation, setMeetingIdValidation] = useState({
    isLoading: true,
    meetingId: null,
    reqError: null,
    reqStatusCode: null,
  });
  const authdata = getAuth();
  console.log("authdata",authdata)
  // const userNameGot=authdata.userName
  const userNameGot=authdata.first_name
  console.log("userNameGot",userNameGot)
  const [tokenReceived, settokenReceived] = useState("");
  const [selectedCall, setselectedCall] = useState([]);
  const [MeetingData, setMeetingData] = useState([]);
  const [MeetingId, setMeetingId] = useState("");
  const [sampleKeys, setsampleKeys] = useState({});
  const [inputname,setinputname]=useState("")
  const [meetingError, setMeetingError] = useState({
    message: null,
    code: null,
    isVisible: false,
  });

  const [meetingLeft, setMeetingLeft] = useState(false);
  const BaseUrl=mainURL

  useEffect(()=>{
    console.log("props--------------->",props.configstate.name)

    setinputname(props.configstate.name)
  },[])

  useEffect(()=>{
    // console.log("participantsprop--------------->",props.participantsprop[0]._id)
  })

  //api for get token
  const getToken = async () => {
    // try {
    //     const response = await fetch(`https://signableapi.disctesting.in/api/get-token`, {
    //         method: "GET",
    //         headers: {
    //             Accept: "application/json",
    //             "Content-Type": "application/json",
    //         },
    try {
      const response = await fetch(mainURL + `/get-token`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (response && response != null) {
        const { token } = await response.json();
        // console.log("token...>",tokenReceived)
        settokenReceived(token);
        // getMeetingData(token);
        return token;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const createMeeting = async (token) => {
    // try {
    //   const VIDEOSDK_API_ENDPOINT = `https://signableapi.disctesting.in/api//create-meeting`;
    //   const options = {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ token }),
    //   };
    try {
      const VIDEOSDK_API_ENDPOINT = BaseUrl + `/create-meeting`;
      const options = {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify({ token }),
      };
      const response = await fetch(VIDEOSDK_API_ENDPOINT, options)
        .then(async (result) => {
          const response = await result.json();
          return response;
        })
        .catch((error) => console.log("error", error));
      return response;
    } catch (e) {
      console.log(e);
    }
  };

  const getMeetingData = async (token) => {
    var query = "";
    const response = await createMeeting(token);
    // console.log("response---------------->", response);
    // console.log("MeetingId.......", MeetingId);
    if (response && response != null) {
      setMeetingData(response);
      // setMeetingId(response.meetingId);
      // console.log("hii");
      // console.log("response.meetingId", response.meetingId);
      // return response.meetingId;
      return response;
      //startmeeting(response.meetingId)
      // settingCall(response)
    }
  };

  // const tokenfun = async () => {
  //   const token = await getToken();
  //  const meetingIdFound= getMeetingData(token);

  //  }

  const playNotificationErr = async () => {
    const errAudio = new Audio(
      `https://static.videosdk.live/prebuilt/notification_err.mp3`
    );

    await errAudio.play();
  };

  const getParams = async ({ maxGridSize }) => {
    const location = window.location;

    const urlParams = new URLSearchParams(location.search);

    const paramKeys = {
      token: "token",
      micEnabled: "micEnabled",
      webcamEnabled: "webcamEnabled",
      name: "name",
      meetingId: "meetingId",
      redirectOnLeave: "redirectOnLeave",
      // chatEnabled: "chatEnabled",
      chatEnabled: true,
      // screenShareEnabled: "screenShareEnabled",
      screenShareEnabled: true,
      pollEnabled: "pollEnabled",
      whiteboardEnabled: "whiteboardEnabled",
      raiseHandEnabled: "true",
      // raiseHandEnabled: "raiseHandEnabled",
      theme: "theme",
      //
      participantCanToggleSelfWebcam: "participantCanToggleSelfWebcam",
      participantCanToggleSelfMic: "participantCanToggleSelfMic",
      participantTabPanelEnabled: "participantTabPanelEnabled",
      participantCanToggleRecording: "participantCanToggleRecording",
      participantCanLeave: "participantCanLeave",
      participantCanToggleOtherWebcam: "participantCanToggleOtherWebcam",
      participantCanToggleOtherMic: "participantCanToggleOtherMic",
      partcipantCanToogleOtherScreenShare:
        "partcipantCanToogleOtherScreenShare",
      participantCanToggleOtherMode: "participantCanToggleOtherMode",
      participantCanToggleLivestream: "participantCanToggleLivestream",
      participantCanEndMeeting: "participantCanEndMeeting",
      participantCanToggleHls: "participantCanToggleHls",
      //
      recordingEnabled: "recordingEnabled",
      recordingWebhookUrl: "recordingWebhookUrl",
      recordingAWSDirPath: "recordingAWSDirPath",
      autoStartRecording: "autoStartRecording",
      recordingTheme: "recordingTheme",
      //
      brandingEnabled: "brandingEnabled",
      brandLogoURL: "brandLogoURL",
      brandName: "brandName",
      poweredBy: "poweredBy",
      //
      liveStreamEnabled: "liveStreamEnabled",
      autoStartLiveStream: "autoStartLiveStream",
      liveStreamOutputs: "liveStreamOutputs",
      liveStreamTheme: "liveStreamTheme",
      //
      askJoin: "askJoin",
      //
      waitingScreenImageUrl: "waitingScreenImageUrl",
      waitingScreenText: "waitingScreenText",
      //
      joinScreenEnabled: "joinScreenEnabled",
      joinScreenMeetingUrl: "joinScreenMeetingUrl",
      joinScreenTitle: "joinScreenTitle",
      //
      notificationSoundEnabled: "notificationSoundEnabled",
      canPin: "canPin",
      canRemoveOtherParticipant: "canRemoveOtherParticipant",
      canDrawOnWhiteboard: "canDrawOnWhiteboard",
      canToggleWhiteboard: "canToggleWhiteboard",
      canCreatePoll: "canCreatePoll",
      canToggleParticipantTab: "canToggleParticipantTab",
      //
      leftScreenActionButtonLabel: "leftScreenActionButtonLabel",
      leftScreenActionButtonHref: "leftScreenActionButtonHref",
      leftScreenRejoinButtonEnabled: "leftScreenRejoinButtonEnabled",
      //
      maxResolution: "maxResolution",
      animationsEnabled: "animationsEnabled",
      topbarEnabled: "topbarEnabled",
      notificationAlertsEnabled: "notificationAlertsEnabled",
      debug: "debug",
      participantId: "participantId",
      //
      layoutType: "layoutType",
      layoutGridSize: "layoutGridSize",
      layoutPriority: "layoutPriority",
      meetingLayoutTopic: "meetingLayoutTopic",
      //
      isRecorder: "isRecorder",
      hideLocalParticipant: "hideLocalParticipant",
      alwaysShowOverlay: "alwaysShowOverlay",
      sideStackSize: "sideStackSize",
      reduceEdgeSpacing: "reduceEdgeSpacing",
      joinWithoutUserInteraction: "joinWithoutUserInteraction",
      rawUserAgent: "rawUserAgent",
      canChangeLayout: "canChangeLayout",
      region: "region",
      preferredProtocol: "preferredProtocol",
      //
      mode: "mode",
      //
      hlsEnabled: "hlsEnabled",
      autoStartHls: "autoStartHls",
      hlsPlayerControlsVisible: "hlsPlayerControlsVisible",
      hlsTheme: "hlsTheme",
      //
      // liveStreamLayoutType: "liveStreamLayoutType",
      // liveStreamLayoutPriority: "liveStreamLayoutPriority",
      // liveStreamLayoutGridSize: "liveStreamLayoutGridSize",
      // recordingLayoutType: "recordingLayoutType",
      // recordingLayoutPriority: "recordingLayoutPriority",
      // recordingLayoutGridSize: "recordingLayoutGridSize",

      maintainVideoAspectRatio: "maintainVideoAspectRatio",
      networkBarEnabled: "networkBarEnabled",
    };

    Object.keys(paramKeys).forEach((key) => {
      paramKeys[key] = urlParams.get(key)
        ? decodeURIComponent(urlParams.get(key))
        : null;
    });

    const token = await getToken();
    // const sample = await getMeetingData(token);

    // const Meet=MeetingId
    // response.meetingId
    // console.log("MeetingIDDDD>", sample.meetingId);
    console.log("tokenReceived", token);
    // paramKeys.meetingId = sample.meetingId;
    paramKeys.meetingId = props.newsetmeetingid;
    paramKeys.token = token;
    paramKeys.name = props.userName;
    paramKeys.chatEnabled = true;
    paramKeys.screenShareEnabled = true;
    // paramKeys.name = "Arti";
    // paramKeys.name = inputname;
    // console.log("paramKeys.name-------->",paramKeys.name)
    // paramKeys.participantId = props.participantsprop[0]._id;
    // paramKeys.participantId = "61c56ffbdcc609b4ac687466";///
    // paramKeys.participantId = sample.userId;
    // paramKeys.participantId = "6350fd8ffd4cd384c15b7145"

    // required options
    let configErr;

    if (typeof paramKeys.token !== "string") {
      configErr = `"token" not provided`;
      playNotificationErr();
      setMeetingError({ message: configErr, code: 4001, isVisible: true });
      //
      // throw new Error(configErr);
    }
    if (typeof paramKeys.meetingId !== "string") {
      configErr = `"meetingId" not provided`;
      playNotificationErr();
      setMeetingError({ message: configErr, code: 4001, isVisible: true });
      //
      // throw new Error(configErr);
    }
    if (typeof paramKeys.name !== "string") {
      if (paramKeys.joinScreenEnabled !== "true") {
        configErr = `"name" not provided when joinScreen is disabled`;
        playNotificationErr();
        setMeetingError({ message: configErr, code: 4001, isVisible: true });
        //
        // throw new Error(configErr);
      }
    }

    // default options

    if (typeof paramKeys.micEnabled !== "string") {
      paramKeys.micEnabled = "true";
    }
    if (typeof paramKeys.webcamEnabled !== "string") {
      paramKeys.webcamEnabled = "true";
    }
    if (typeof paramKeys.chatEnabled !== "string") {
      // paramKeys.chatEnabled = "true";
      paramKeys.chatEnabled = true;
    }
    if (typeof paramKeys.screenShareEnabled !== "string") {
      // paramKeys.screenShareEnabled = "true";
      paramKeys.screenShareEnabled = true;
    }
    if (typeof paramKeys.pollEnabled !== "string") {
      paramKeys.pollEnabled = "true";
    }
    if (typeof paramKeys.whiteboardEnabled !== "string") {
      paramKeys.whiteboardEnabled = "true";
    }
    if (typeof paramKeys.participantCanToggleSelfWebcam !== "string") {
      paramKeys.participantCanToggleSelfWebcam = "true";
    }
    if (typeof paramKeys.participantCanToggleSelfMic !== "string") {
      paramKeys.participantCanToggleSelfMic = "true";
    }
    if (typeof paramKeys.participantTabPanelEnabled !== "string") {
      paramKeys.participantTabPanelEnabled = "true";
    }
    if (typeof paramKeys.raiseHandEnabled !== "string") {
      paramKeys.raiseHandEnabled = "true";
    }
    if (typeof paramKeys.recordingEnabled !== "string") {
      paramKeys.recordingEnabled = "false";
    }
    if (typeof paramKeys.hlsEnabled !== "string") {
      paramKeys.hlsEnabled = "false";
    }
    if (typeof paramKeys.poweredBy !== "string") {
      paramKeys.poweredBy = "true";
    }
    if (typeof paramKeys.liveStreamEnabled !== "string") {
      paramKeys.liveStreamEnabled = "false";
    }
    if (typeof paramKeys.autoStartLiveStream !== "string") {
      paramKeys.autoStartLiveStream = "false";
    }
    if (typeof paramKeys.participantCanToggleLivestream !== "string") {
      paramKeys.participantCanToggleLivestream = "false";
    }

    if (typeof paramKeys.canCreatePoll !== "string") {
      paramKeys.canCreatePoll = "false";
    }

    if (typeof paramKeys.canToggleParticipantTab !== "string") {
      paramKeys.canToggleParticipantTab = "true";
    }

    if (paramKeys.autoStartLiveStream === "true") {
      try {
        paramKeys.liveStreamOutputs = JSON.parse(paramKeys.liveStreamOutputs);
        if (
          paramKeys.liveStreamOutputs === null ||
          !paramKeys.liveStreamOutputs.length
        ) {
          paramKeys.liveStreamOutputs = [];
        }
      } catch (err) {
        paramKeys.liveStreamOutputs = [];
      }
    }

    if (typeof paramKeys.joinScreenEnabled !== "string") {
      paramKeys.joinScreenEnabled = "true";
    }

    if (
      paramKeys.joinScreenMeetingUrl === null ||
      !paramKeys.joinScreenMeetingUrl.length
    ) {
      paramKeys.joinScreenMeetingUrl = "";
    }

    if (
      paramKeys.joinScreenTitle === null ||
      !paramKeys.joinScreenTitle.length
    ) {
      paramKeys.joinScreenTitle = "";
    }

    if (typeof paramKeys.notificationSoundEnabled !== "string") {
      paramKeys.notificationSoundEnabled = "true";
    }

    if (typeof paramKeys.maintainVideoAspectRatio !== "string") {
      paramKeys.maintainVideoAspectRatio = "false";
    }

    if (typeof paramKeys.networkBarEnabled !== "string") {
      paramKeys.networkBarEnabled = "true";
    }

    if (typeof paramKeys.canPin !== "string") {
      paramKeys.canPin = "false";
    }

    switch (paramKeys?.layoutType?.toUpperCase()) {
      case meetingLayouts.GRID:
      case meetingLayouts.SPOTLIGHT:
      case meetingLayouts.SIDEBAR:
        paramKeys.layoutType = paramKeys.layoutType.toUpperCase();
        break;
      default:
        paramKeys.layoutType = meetingLayouts.GRID;
        break;
    }

    switch (paramKeys.layoutPriority?.toUpperCase()) {
      case meetingLayoutPriorities.PIN:
      case meetingLayoutPriorities.SPEAKER:
        paramKeys.layoutPriority = paramKeys.layoutPriority.toUpperCase();
        break;
      default:
        paramKeys.layoutPriority = meetingLayoutPriorities.SPEAKER;
        break;
    }

    paramKeys.layoutGridSize = parseInt(paramKeys.layoutGridSize);

    if (paramKeys.layoutGridSize <= 0 || isNaN(paramKeys.layoutGridSize)) {
      paramKeys.layoutGridSize = maxGridSize;
    }

    if (paramKeys.isRecorder === "true") {
      paramKeys.micEnabled = "false";
      paramKeys.webcamEnabled = "false";
      paramKeys.hideLocalParticipant = "true";
      paramKeys.alwaysShowOverlay = "true";
      paramKeys.sideStackSize = "5";
      paramKeys.reduceEdgeSpacing = "true";
      paramKeys.topbarEnabled = "true";
      paramKeys.notificationSoundEnabled = "true";
      paramKeys.notificationAlertsEnabled = "true";
      paramKeys.animationsEnabled = "false";
      paramKeys.redirectOnLeave = undefined;
    }

    paramKeys.sideStackSize = parseInt(paramKeys.sideStackSize);

    if (
      typeof paramKeys.sideStackSize === "number" &&
      paramKeys.sideStackSize <= 0
    ) {
      configErr = `"sideStackSize" is not a valid number`;
      playNotificationErr();
      setMeetingError({ message: configErr, code: 4001, isVisible: true });
    }

    // validate meetingLayoutTopic here
    switch (paramKeys.meetingLayoutTopic?.toUpperCase()) {
      case meetingLayoutTopics.MEETING_LAYOUT:
      case meetingLayoutTopics.RECORDING_LAYOUT:
      case meetingLayoutTopics.LIVE_STREAM_LAYOUT:
      case meetingLayoutTopics.HLS_LAYOUT:
        paramKeys.meetingLayoutTopic =
          paramKeys.meetingLayoutTopic.toUpperCase();
        break;
      default:
        paramKeys.meetingLayoutTopic = meetingLayoutTopics.MEETING_LAYOUT;
        break;
    }

    if (!paramKeys.region || typeof paramKeys.region !== "string") {
      paramKeys.region = "sg001";
    }
    if (!paramKeys.theme || typeof paramKeys.theme !== "string") {
      paramKeys.theme = "DEFAULT";
    }
    if (
      !paramKeys.recordingTheme ||
      typeof paramKeys.recordingTheme !== "string"
    ) {
      paramKeys.recordingTheme = "DEFAULT";
    }
    if (
      !paramKeys.liveStreamTheme ||
      typeof paramKeys.liveStreamTheme !== "string"
    ) {
      paramKeys.liveStreamTheme = "DEFAULT";
    }
    if (!paramKeys.hlsTheme || typeof paramKeys.hlsTheme !== "string") {
      paramKeys.hlsTheme = "DEFAULT";
    }

    if (typeof paramKeys.preferredProtocol !== "string") {
      paramKeys.preferredProtocol = "UDP_ONLY";
    }

    switch (paramKeys.preferredProtocol.toUpperCase()) {
      case "UDP_ONLY":
      case "UDP_OVER_TCP":
        paramKeys.preferredProtocol = paramKeys.preferredProtocol.toUpperCase();
        break;
      default:
        paramKeys.preferredProtocol = "UDP_ONLY";
        break;
    }

    if (typeof paramKeys.mode !== "string") {
      paramKeys.mode = meetingModes.CONFERENCE;
    }

    switch (paramKeys.mode.toUpperCase()) {
      case meetingModes.CONFERENCE:
      case meetingModes.VIEWER:
        paramKeys.mode = paramKeys.mode.toUpperCase();
        break;
      default:
        paramKeys.mode = meetingModes.CONFERENCE;
        break;
    }

    return paramKeys;
  };

  const isLGDesktop = useIsLGDesktop();
  const isSMDesktop = useIsSMDesktop();
  const isTab = useIsTab();

  const maxGridSize = useMemo(() => {
    return isLGDesktop
      ? maxParticipantGridCount_large_desktop
      : isSMDesktop
      ? maxParticipantGridCount_desktop
      : isTab
      ? maxParticipantGridCount_tab
      : maxParticipantGridCount_mobile;
  }, [isLGDesktop, isSMDesktop, isTab]);

  const paramKeys = useMemo(() => getParams({ maxGridSize }), [maxGridSize]);

  const [userHasInteracted, setUserHasInteracted] = useState(
    paramKeys.joinWithoutUserInteraction === "true"
  );

  const [name, setName] = useState(paramKeys.name || "");
  const [joinScreenWebCam, setJoinScreenWebCam] = useState(
    paramKeys.joinScreenEnabled === "true"
      ? paramKeys.participantCanToggleSelfWebcam === "true" &&
          paramKeys.webcamEnabled === "true"
      : paramKeys.webcamEnabled === "true"
  );

  const [joinScreenMic, setJoinScreenMic] = useState(
    paramKeys.joinScreenEnabled === "true"
      ? paramKeys.participantCanToggleSelfMic === "true" &&
          paramKeys.micEnabled === "true"
      : paramKeys.micEnabled === "true"
  );
  const [selectedMic, setSelectedMic] = useState({ id: null });
  const [selectedWebcam, setSelectedWebcam] = useState({ id: null });

  const validateMeetingId = async ({ meetingId, token, debug, region }) => {
    const BASE_URL = "https://api.videosdk.live";

    const urlMeetingId = `${BASE_URL}/v1/prebuilt/meetings/${meetingId}`;

    const resMeetingId = await fetch(urlMeetingId, {
      method: "POST",
      headers: { "Content-type": "application/json", Authorization: token },
      body: JSON.stringify({ region }),
    });

    const meetingIdJson = await resMeetingId.json();

    const validatedMeetingId = meetingIdJson.meetingId;

    if (validatedMeetingId) {
      setMeetingIdValidation({
        isLoading: false,
        meetingId: validatedMeetingId,
        reqError: null,
        reqStatusCode: null,
      });
    } else {
      setMeetingIdValidation({
        isLoading: false,
        meetingId: null,
        reqError: meetingIdJson.error,
        reqStatusCode: meetingIdJson.statusCode,
      });

      playNotificationErr();

      setMeetingError({
        message: debug ? meetingIdJson.error : "Unable to join meeting!",
        code: meetingIdJson.statusCode,
        isVisible: true,
      });
    }
  };

  useEffect(async () => {
    // getToken()
    // createMeeting()
    //getMeetingData()
    // tokenfun()

    const paramKeys = await getParams({ maxGridSize });
    setsampleKeys(paramKeys);
    console.log("paramKeys", paramKeys);
    console.log(" paramKeys.meetingId", paramKeys.meetingId, paramKeys.token);
    if (paramKeys.meetingId && paramKeys.token) {
      validateMeetingId({
        meetingId: paramKeys.meetingId,
        // meetingId: props.newsetmeetingid,
        token: paramKeys.token,
        debug: paramKeys.debug === "true",
        region: paramKeys.region,
      });
    }
  }, [paramKeys]);

  const theme = useTheme();

  const isXStoSM = useMediaQuery(theme.breakpoints.only("xs"));

  useEffect(() => {
    if (isXStoSM) {
      window.onbeforeunload = () => {
        return "Are you sure you want to exit?";
      };
    }
  }, [isXStoSM]);


  var someNavigate=useNavigate()
  function sampleFunction(){
    
    someNavigate('/InterpreterFeedbackform')
  }

  return (
    <>
     {meetingLeft ? (
        sampleKeys.isRecorder === "true" ? null : (
          // <MeetingLeftScreen
          //   brandLogoURL={sampleKeys.brandLogoURL}
          //   leftScreenActionButtonLabel={sampleKeys.leftScreenActionButtonLabel}
          //   leftScreenActionButtonHref={sampleKeys.leftScreenActionButtonHref}
          //   leftScreenRejoinButtonEnabled={
          //     sampleKeys.leftScreenRejoinButtonEnabled !== "false"
          //   }
          //   backgroundColor={
          //     sampleKeys.theme === appThemes.DARK
          //       ? theme.palette.darkTheme.main
          //       : sampleKeys.theme === appThemes.LIGHT
          //       ? theme.palette.lightTheme.main
          //       : theme.palette.background.default
          //   }
          //   color={
          //     sampleKeys.theme === appThemes.LIGHT
          //       ? theme.palette.lightTheme.contrastText
          //       : theme.palette.common.white
          //   }
          //   animationData={
          //     sampleKeys.theme === appThemes.LIGHT
          //       ? lightThemeAnimationData
          //       : animationData
          //   }
          //   setMeetingLeft={setMeetingLeft}
          // />
          // <InterpreterFeedbackform />
          sampleFunction()
        )
       ) :
       meetingIdValidation.isLoading ? (
        <Box
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor:
              sampleKeys.theme === appThemes.DARK
                ? theme.palette.darkTheme.main
                : sampleKeys.theme === appThemes.LIGHT
                ? theme.palette.lightTheme.main
                : theme.palette.background.default,
          }}
        >
          <CircularProgress size={"4rem"} />
        </Box>
      ) : meetingIdValidation.reqError ? (
        <>
          {/* <ErrorPage
            errMsg={meetingIdValidation.reqError}
            statusCode={meetingIdValidation.reqStatusCode}
          /> */}
        </>
      ) : userHasInteracted && meetingIdValidation.meetingId ? (
        <MeetingAppProvider
          {...{
            redirectOnLeave: sampleKeys.redirectOnLeave,
            chatEnabled: sampleKeys.chatEnabled === true,
            screenShareEnabled: sampleKeys.screenShareEnabled === true,
            pollEnabled: sampleKeys.pollEnabled === "true",
            whiteboardEnabled: sampleKeys.whiteboardEnabled === "true",
            participantCanToggleSelfWebcam:
              sampleKeys.participantCanToggleSelfWebcam === "true",
            participantCanToggleSelfMic:
              sampleKeys.participantCanToggleSelfMic === "true",
            participantTabPanelEnabled:
              sampleKeys.participantTabPanelEnabled === "true",
            raiseHandEnabled: sampleKeys.raiseHandEnabled === "true",
            canChangeLayout: sampleKeys.canChangeLayout === "true",
            meetingLayoutTopic: sampleKeys.meetingLayoutTopic,
            recordingEnabled: sampleKeys.recordingEnabled === "true",
            hlsEnabled: sampleKeys.hlsEnabled === "true",
            recordingWebhookUrl: sampleKeys.recordingWebhookUrl,
            recordingAWSDirPath: sampleKeys.recordingAWSDirPath,
            recordingTheme: sampleKeys.recordingTheme,
            autoStartRecording: sampleKeys.autoStartRecording === "true",
            autoStartHls: sampleKeys.autoStartHls === "true",
            hlsPlayerControlsVisible:
              sampleKeys.hlsPlayerControlsVisible === "true",
            hlsTheme: sampleKeys.hlsTheme,
            participantCanToggleRecording:
              sampleKeys.participantCanToggleRecording === "true",
            participantCanToggleHls:
              sampleKeys.participantCanToggleHls === "true",
            brandingEnabled: sampleKeys.brandingEnabled === "true",
            poweredBy: sampleKeys.poweredBy === "true",
            liveStreamEnabled: sampleKeys.liveStreamEnabled === "true",
            autoStartLiveStream: sampleKeys.autoStartLiveStream === "true",
            liveStreamOutputs: sampleKeys.liveStreamOutputs,
            liveStreamTheme: sampleKeys.liveStreamTheme,
            brandLogoURL:
              sampleKeys.brandLogoURL?.length > 0
                ? sampleKeys.brandLogoURL
                : null,
            brandName:
              sampleKeys.brandName?.length > 0 ? sampleKeys.brandName : null,
            waitingScreenImageUrl:
              sampleKeys.waitingScreenImageUrl?.length > 0
                ? sampleKeys.waitingScreenImageUrl
                : null,
            waitingScreenText:
              sampleKeys.waitingScreenText?.length > 0
                ? sampleKeys.waitingScreenText
                : null,
            participantCanLeave: sampleKeys.participantCanLeave !== "false",
            askJoin: sampleKeys.askJoin === "true",
            participantCanToggleOtherMic:
              sampleKeys.participantCanToggleOtherMic === "true",
            participantCanToggleOtherWebcam:
              sampleKeys.participantCanToggleOtherWebcam === "true",
            partcipantCanToogleOtherScreenShare:
              sampleKeys.partcipantCanToogleOtherScreenShare === "true",
            participantCanToggleLivestream:
              sampleKeys.participantCanToggleLivestream === "true",
            participantCanToggleOtherMode:
              sampleKeys.participantCanToggleOtherMode === "true",
            notificationSoundEnabled:
              sampleKeys.notificationSoundEnabled === "true",
            layoutType: sampleKeys.layoutType,
            mode: sampleKeys.mode,
            layoutPriority: sampleKeys.layoutPriority,
            canPin: sampleKeys.canPin === "true",
            selectedMic,
            selectedWebcam,
            joinScreenWebCam,
            joinScreenMic,
            canRemoveOtherParticipant:
              sampleKeys.canRemoveOtherParticipant === "true",
            participantCanEndMeeting:
              sampleKeys.participantCanEndMeeting === "true",
            canDrawOnWhiteboard: sampleKeys.canDrawOnWhiteboard === "true",
            canToggleWhiteboard: sampleKeys.canToggleWhiteboard === "true",
            canCreatePoll: sampleKeys.canCreatePoll === "true",
            canToggleParticipantTab:
              sampleKeys.canToggleParticipantTab === "true",
            meetingLeft,
            setMeetingLeft,
            animationsEnabled: sampleKeys.animationsEnabled !== "false",
            topbarEnabled: sampleKeys.topbarEnabled !== "false",
            notificationAlertsEnabled:
              sampleKeys.notificationAlertsEnabled !== "false",
            debug: sampleKeys.debug === "true",
            layoutGridSize: sampleKeys.layoutGridSize,
            hideLocalParticipant: sampleKeys.hideLocalParticipant === "true",
            alwaysShowOverlay: sampleKeys.alwaysShowOverlay === "true",
            sideStackSize: sampleKeys.sideStackSize,
            reduceEdgeSpacing: sampleKeys.reduceEdgeSpacing === "true",
            isRecorder: sampleKeys.isRecorder === "true",
            appTheme: sampleKeys.theme,
            //
            // recordingLayoutType: sampleKeys.recordingLayoutType,
            // recordingLayoutPriority: sampleKeys.recordingLayoutPriority,
            // recordingLayoutGridSize: sampleKeys.recordingLayoutGridSize,
            // liveStreamLayoutType: sampleKeys.liveStreamLayoutType,
            // liveStreamLayoutPriority: sampleKeys.liveStreamLayoutPriority,
            // liveStreamLayoutGridSize: sampleKeys.liveStreamLayoutGridSize,
            //
            maintainVideoAspectRatio:
              sampleKeys.maintainVideoAspectRatio === "true",
            networkBarEnabled: sampleKeys.networkBarEnabled === "true",
          }}
        >
          <MeetingProvider
            config={{
              meetingId: meetingIdValidation.meetingId,
              // micEnabled: joinScreenMic,
              // webcamEnabled: joinScreenWebCam,
              micEnabled: true,
              webcamEnabled: true,
              chatEnabled: true,
              screenShareEnabled: true,
              name: name,
              maxResolution:
                sampleKeys.isRecorder === "true"
                  ? "hd"
                  : sampleKeys.maxResolution === "sd" ||
                    sampleKeys.maxResolution === "hd"
                  ? sampleKeys.maxResolution
                  : "sd",
              participantId: sampleKeys.participantId,
              preferredProtocol: sampleKeys.preferredProtocol,
              autoConsume: false,
            }}
            token={sampleKeys.token}
            joinWithoutUserInteraction
            deviceInfo={{
              sdkType: "prebuilt",
              sdkVersion: prebuiltSDKVersion,
              rawUserAgent:
                sampleKeys.rawUserAgent || typeof window !== "undefined"
                  ? window?.navigator?.userAgent
                  : null,
            }}
          >
            <MeetingContainer />
          </MeetingProvider>
        </MeetingAppProvider>
      ) :
      sampleKeys.joinScreenEnabled === "true" ? (
        // <JoinMeeting sentname={props.userName} webcamOn={true} micOn={true}/>
        <JoinMeeting
          onClick={({ name, webcamOn, micOn }) => {
            setName(props.userName);
            setJoinScreenMic(micOn);
            setJoinScreenWebCam(webcamOn);
            setUserHasInteracted(true);
          }}
          {...{
            micEnabled:
              sampleKeys.mode === meetingModes.VIEWER
                ? false
                : sampleKeys.micEnabled === "true",
            webcamEnabled:
              sampleKeys.mode === meetingModes.VIEWER
                ? false
                : sampleKeys.webcamEnabled === "true",
          }}

          name={name}
          // name="arti"
          setName={setName}
          setSelectedMic={setSelectedMic}
          setSelectedWebcam={setSelectedWebcam}
          meetingUrl={sampleKeys.joinScreenMeetingUrl}
          meetingTitle={sampleKeys.joinScreenTitle}
          participantCanToggleSelfWebcam={
            sampleKeys.mode === meetingModes.VIEWER
              ? "false"
              : sampleKeys.participantCanToggleSelfWebcam
          }
          participantCanToggleSelfMic={
            sampleKeys.mode === meetingModes.VIEWER
              ? "false"
              : sampleKeys.participantCanToggleSelfMic
          }
          mode={sampleKeys.mode}
          appTheme={sampleKeys.theme}
        />
        // <JoinMeeting
        //   onClick={({ name, webcamOn, micOn }) => {
        //     setName(name);
        //     setJoinScreenMic(micOn);
        //     setJoinScreenWebCam(webcamOn);
        //     setUserHasInteracted(true);
        //   }}
        //   {...{
        //     micEnabled:
        //       sampleKeys.mode === meetingModes.VIEWER
        //         ? false
        //         : sampleKeys.micEnabled === "true",
        //     webcamEnabled:
        //       sampleKeys.mode === meetingModes.VIEWER
        //         ? false
        //         : sampleKeys.webcamEnabled === "true",
        //   }}

        //   name={name}
        //   // name="arti"
        //   setName={setName}
        //   setSelectedMic={setSelectedMic}
        //   setSelectedWebcam={setSelectedWebcam}
        //   meetingUrl={sampleKeys.joinScreenMeetingUrl}
        //   meetingTitle={sampleKeys.joinScreenTitle}
        //   participantCanToggleSelfWebcam={
        //     sampleKeys.mode === meetingModes.VIEWER
        //       ? "false"
        //       : sampleKeys.participantCanToggleSelfWebcam
        //   }
        //   participantCanToggleSelfMic={
        //     sampleKeys.mode === meetingModes.VIEWER
        //       ? "false"
        //       : sampleKeys.participantCanToggleSelfMic
        //   }
        //   mode={sampleKeys.mode}
        //   appTheme={sampleKeys.theme}
        // />
      ) :
      (
        <ClickAnywhereToContinue
          onClick={() => {
            setUserHasInteracted(true);
          }}
          title="Click anywhere to continue"
          brandLogoURL={sampleKeys.brandLogoURL}
        />
      )}
      <ConfirmBox
        open={meetingError.isVisible}
        successText="OKAY"
        onSuccess={() => {
          setMeetingError(({ message }) => {
            throw new Error(message);

            // return {
            //   message: null,
            //   code: null,
            //   isVisible: false,
            // };
          });
        }}
        title={`Error Code: ${meetingError.code}`}
        subTitle={meetingError.message}
      />
    </>
  );
};

export default VideosdkApp;

