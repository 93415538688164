// import firebase from 'firebase/compat/app';

// //  import firebase fro./firebasease";
// // //import { initializeApp } from "firebase/app";
// // // import { getAnalytics } from "firebase/analytics";
// // import { initializeApp } from 'firebase/app';

// // import firebase from "firebase/app";
// // import "firebase/messaging";

// const Config = {
//   apiKey: "AIzaSyAvLCimFdTmB25ItxPPNeHWPiNtoXcbCE",
//   authDomain: "first-cloud-messaging-4e9c0.firebaseapp.com",
//   projectId: "first-cloud-messaging-4e9c0",
//   storageBucket: "first-cloud-messaging-4e9c0.appspot.com",
//   messagingSenderId: "461463883678",
//   appId: "1:461463883678:web:a25151704bf094d329108f",
//   measurementId: "G-BJCMB24L0L"
// };

//login ko end krna logout ko start karna



// // Initialize Firebase
// //firebase.initializeApp(Config);
// // const analytics = getAnalytics(app);
// firebase.initializeApp(Config);

//  export default firebase

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { requestForToken } from './firebase';

export const onMessageListener = () =>
 new Promise((resolve) => {
   onMessage(messaging, (payload) => {
     resolve(payload);
   });
});


export const getTokenInApp = (setTokenFound) => {
 return getToken(messaging, {vapidKey: 'BBJzu_eMwGFD1twaLQLgdlkvk_Hbr3ojT3f8wEqAbzznD6fwVlsbNNVx-rJGmORyZwGQnARhaDRcNmKp8XoH9io'}).then((currentToken) => {
   if (currentToken) {
     console.log('current token for client: ', currentToken);
     // save currrent token in local storage
     setTokenFound(true);
     localStorage.setItem('NotificationToken',currentToken)
     // Track the token -> client mapping, by sending to backend server
     // show on the UI that permission is secured
   } else {
     console.log('No registration token available. Request permission to generate one.');
     setTokenFound(false);
     // shows on the UI that permission is required 
   }
 }).catch((err) => {
   console.log('An error occurred while retrieving token. ', err);
   // catch error while creating client token
 });
}





var firebaseConfig = {
 apiKey: "AIzaSyAbBVP1udDO0p7L1ML3c2QrIna4xgMnNMY",
 authDomain: "signable-a161b.firebaseapp.com",
 projectId: "signable-a161b",
 storageBucket: "signable-a161b.appspot.com",
 messagingSenderId: "1028060224648",
 appId: "1:1028060224648:web:3f57ec97274ada1091133d",
 measurementId: "G-WHJSGP2LXC"
};
// const messaging = (async () => {
//   try {
//       const isSupportedBrowser = await isSupported();
//       if (isSupportedBrowser) {
//           return getMessaging(firebaseConfig);
//       }
//       console.log('Firebase not supported this browser');
//       return null;
//   } catch (err) {
//       console.log(err);
//       return null;
//   }
//   })();
initializeApp(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);
//const messaging = firebaseApp.messaging.isSupported() ? getMessaging(firebaseApp) : null
// if (firebase.messaging.isSupported()) {
const messaging = getMessaging(firebaseApp);
// }


onMessage(messaging, (payload) => {
  console.log('Message received---------------> ', payload);
  // ...
});