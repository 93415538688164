import React, { useEffect } from "react";
import {
  Typography,
  Paper,
  Grid,
  Box,
  TextField,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Modal,
  makeStyles,
} from "@material-ui/core";
import { Routes, Route, NavLink, HashRouter } from "react-router-dom";
import MainLogo from "../../Assets/header-main.svg";
import { ReactComponent as Icon_Headerhome } from "../../Assets/header-home-icon.svg";
import { ReactComponent as Icon_profile } from "../../Assets/home-profile-icon.svg";
import { ReactComponent as Icon_HeaderCall } from "../../Assets/home-call-icon.svg";
import { ReactComponent as Icon_HeaderNoti } from "../../Assets/home-notifiation-icon.svg";
import { ReactComponent as Icon_HeaderHelp } from "../../Assets/home-help-icon.svg";
import HeaderUserProfile from "../../Assets/home-user-img.svg";
import HeaderUserArrow from "../../Assets/user-arrow.svg";
import Style from "../../Assets/css/style.css";
import Main_sidebar from "./Sidebar";
import Profile from "../ProfileComponent/Profile";
import Call from "../CallComponent/Call";
import Notification from "../NotificationComponent/Notification";
import Help from "../HelpComponent/Help";
import { getprofile, getAuth } from "../../Service/Helper";
import {
  postDataFromApi,
  getDataFromApi,
  putDataFromApi,
} from "../../Service/CommonService";


const Main_header = () => {
  const [show, setShowprofile] = React.useState();
  const [receivedinterpreterid, setReceivedinterpreterid] = React.useState("");
  const authdata = getAuth();
  function logout() {
    window.localStorage.clear();
    getAvailableInterpreter();
  }


  useEffect(() => {
	const authdata = getAuth();
	// console.log("authdata------>", authdata);
	setReceivedinterpreterid(authdata._id)
  },[]);


  const getAvailableInterpreter = async () => {
    //console.log('newreason',newreason)
    // var query ="reason="+newreason+"&interpreter_id="+interpreterid
    var query = {
      reason: "logout",
    //   interpeter_id: interpreterid,
      interpeter_id: receivedinterpreterid,
    };
    console.log("query", query);
    const response = await postDataFromApi(
      "add/interpretorAvailibilityLog",
      query
    );
    if (response.code) {
      //	setmybreak(response.data.data);
    }
  };
  // code for click outside on login button
  useEffect(() => {
    const handleClickOutside = (event) => {
      console.log("logoutcheck", event.target.id);
      if (event.target.id != "logoutlink") {
        setShowprofile(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, []);
  return (
    <>
      <Box className="header">
        <div className="header-main">
          <div className="header-inner">
            <div className="header-main-block">
              <div className="header-main-logo">
                <NavLink to="/Home">
                  <img src={MainLogo} alt="SignAble" />
                </NavLink>
                <span className="signable-text-header">
                  Welcome to SignAble!
                </span>
              </div>
              <div className="header-main-nav">
                <div className="header-main-nav-inner">
                  <div className="header-nav home">
                    <NavLink to="/Home">
                      <div className="header-icon">
                        <Icon_Headerhome alt="home" />
                      </div>
                      <div className="header-text">
                        <p>Home</p>
                      </div>
                    </NavLink>
                  </div>
                  <div className="header-nav profile">
                    <NavLink to="/Profile">
                      <div className="header-icon">
                        <Icon_profile alt="profile" />
                      </div>
                      <div className="header-text">
                        <p>Profile</p>
                      </div>
                    </NavLink>
                  </div>
                  {/* <div className="header-nav call">
									<NavLink to="/Call">
										<div className="header-icon">
											<Icon_HeaderCall alt="call"/>
										</div>
										<div className="header-text">
											<p>Call</p>
										</div>
									</NavLink>
								</div> */}
                  <div className="header-nav notification">
                    <NavLink to="/Notification">
                      <div className="header-icon">
                        <Icon_HeaderNoti alt="notification" />
                      </div>
                      <div className="header-text">
                        <p>Notification</p>
                      </div>
                    </NavLink>
                  </div>
                  <div className="header-nav help">
                    <NavLink to="/Help">
                      <div className="header-icon">
                        <Icon_HeaderHelp alt="help" />
                      </div>
                      <div className="header-text">
                        <p>Setting</p>
                      </div>
                    </NavLink>
                  </div>
                </div>
                <div
                  className="header-user-profile"
                  onClick={() => setShowprofile(!show)}
                >
                  <div className="user-profile">
                    <p className="profile_intial">
                      {authdata
                        ? getprofile(authdata.first_name, authdata.last_name)
                        : ""}
                    </p>
                  </div>
                  <div className="user-profile-details">
                    <p className="user-name">
                      {authdata
                        ? authdata.first_name + " " + authdata.last_name
                        : ""}
                    </p>
                    <a href="javascript:">
                      <img src={HeaderUserArrow} />
                    </a>
                  </div>
                </div>
                <div
                  className={`box-shadow user-profile-details-menu ${
                    show ? "show" : ""
                  }`}
                >
                  {/*<NavLink to="/Profile">
									<div className="profile-menu-link">Profile</div>
								</NavLink>
								<NavLink to="/help">
									<div className="profile-menu-link">Help</div>
								</NavLink>*/}
                  <NavLink to="/" onClick={logout} id="logoutlink">
                    <div
                      className="profile-menu-link logoutlink"
                      id="logoutlink"
                    >
                      Logout
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
      <Main_sidebar />
    </>
  );
};

export default Main_header;
