import React from "react";

const CustomerSupport_page = () => {
  return (
    <div className="page-main">
      <div className="page-main-inner">
        <h2 style={{ color: "gray" }}>Under Development</h2>
      </div>
    </div>
  );
};

export default CustomerSupport_page;
