import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import App from "./App";
// import generateMuiTheme from "./mui/theme";
// import generateMuiTheme from "../src/VideoSdkComponents/mui/theme";
// import { ThemeProvider } from "@material-ui/styles";

ReactDOM.render(
  <HashRouter>
    {/* <ThemeProvider theme={generateMuiTheme()}> */}
      <App />
    {/* </ThemeProvider> */}
  </HashRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
