import React  , { useState } from 'react'
import { ReactComponent  as Vector} from "../../Assets/icon.svg";
import {NavLink} from "react-router-dom";
import Input from '../../CommonComponent/Input'
import { Typography, Paper, Grid, Box, TextField, Select,Radio, RadioGroup,FormControlLabel, Button, Modal,makeStyles, } from "@material-ui/core";

import {postDataFromApi,getDataFromApi} from '../../Service/CommonService'
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import style from '../../Assets/css/style.css'
import {getprofile,getAuth} from '../../Service/Helper';
import {
  HashRouter as Router,
  Link,
  Route,
  Routes,
  useNavigate
} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
      flex: 1,
      backgroundColor: '#fff',
      justifyContent: 'center',
      paddingHorizontal: 40,
  },
  inputContainer: {
      backgroundColor: '#FFFFFF',
      paddingHorizontal: 15,
      borderRadius: 8,
      borderColor: '#D0E1F4',
      border: '2px solid ',
      borderWidth: 2,
      
      // height: 60,
      // width: '80%'
  },
  row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      border: "1px solid rgb(42, 84, 250)",
      width:"136px",
      height:"86px",
      borderRadius:"5px",
      paddingTop:"2px",
      justifyContent:"center",
      alignItems:"center",
      marginTop:"45px",
      marginRight:"8px",
      marginLeft:"6px",
     
     
  },
  inputLabel: {
      paddingTop: 5,
      fontSize: 13,
      color: '#5490D6',
     marginBottom:"2px",
     textAlign:"center",
     marginTop:"30px"
  },
  input: {
      color: '#515151',
      fontWeight: 'bold',
      fontSize: 14,
      flex: 1,
      // width:"397px",
      textAlign:"center"
     
  },
  mainDiv:{
      justifyContent:"center",
      alignItems:"center",
      display: "flex",
     backgroundColor:"white",
     opacity:"1",
     padding:"20",
     height:"341px",
     width:"825px"
    },
    div1:{
      height:"190px",
      width:"343px",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
     

    },
    div2:{
      height:"190px",
      width:"343px",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
    },
   image:{
      position: "absolute",
      width: "50px",
      height: "60px",
       top:"-39px",
       marginLeft:"38px"
       
      // zIndex: -1,
      
    },
    
      signText:{
        fontWeight:"bolder",
        fontSize:"38px", 
        color:"#2760A3",
        textAlign:"center"
      },
      ableText:{
        display:"center",
        fontSize:"38px",
        color:"#FF9D00",
        fontWeight:"bolder",
        textAlign:"center"
      },
    btn_nt_blur:{
      width:"302px",
      borderRadius:"8px",
      backgroundColor:"#2760A3"
    },
    btn_blur:{
      width:"302px",
      borderRadius:"8px",
      backgroundColor:"#D0E1F4 !important"
    },
    button:{
      display:"flex",
      justifyContent:"center",
     

    }


}))


export default function Login() {
const [isOpen, setIsOpen] = useState(false);
const [showerror, setshowerror] = useState(false);
const [loginData,setloginData]=useState([])
const navigate=useNavigate();
const [errormessage, seterrormessage] = useState('');
const [is_show,setShow]=useState(false);
const authdata=getAuth()
const interpreterid=authdata['_id'];

// let validationSchema =""
let validationSchema = Yup.object({
    
    password: Yup.string()
    .required('Password is required')
    .min(3, 'Password must be at 3 char long'),
    confirmPassword: Yup.string()
    .required('Password is required')
    .oneOf([Yup.ref('password')], 'Passwords does not match'),
})



const FormData = useFormik({
  initialValues: {
      password: "",
      confirmPassword:""
     
  },
  validationSchema:validationSchema,

  onSubmit:async (value)=>{

    var query={password:value.confirmPassword,id:interpreterid }
    const response = await postDataFromApi('changePassword', query);
    // navigate('/home')
    if(response.data && response.data.code){
     setloginData(response.data.data)
     navigate('/home')
       
    }else{
    //  alert('test')
       setshowerror(true)
       seterrormessage(response.data.message)
       console.log('responses',errormessage);
    }
    
  }
})
function displaybtn(e){
  if(FormData.email!="" && FormData.password!=""){
    setshowerror(false)
    seterrormessage('')
    // if(FormData.email.test(e.target.value)){
    //   setShow(true)
    // }else{
    //   setShow(false)
    // }
    setShow(true)
  }
  else{
    setShow(false)
  }
}
  const classes=useStyles()
  return (
    <Grid container className="login-container interpretlogin" spacing={0}
       direction="column"
       alignItems="center"
       justifyContent="center"
      >
          <Grid style={{ whiteSpace: "nowrap",textAlign: "center",marginTop:"20px"}}>
         <Vector  style={{width: '120px', height: '110px'}}/>
      
        <div >  
           <span className={classes.signText}>Sign</span> 
           <span className={classes.ableText}>Able</span>
       </div> 
        </Grid>
<Grid className='header-login'>
  <div className='login-text-top'>
   <span className='login-text-interpreter'>Reset Password</span> 
    </div>
<form onSubmit={FormData.handleSubmit}>
<Grid item sx={10} style={{marginTop:"15px",marginLeft:"4%"}} className='phone-input-container'>
<Input
        label=" New Password*"
        type="password"
        id="password"
        className='input-box'
        placeholder="Enter your New Password"
        onChange={FormData.handleChange}
        onKeyUp={(e)=>displaybtn(e)}
        error={Boolean(FormData.touched.password && FormData.errors.password)}
        helperText={FormData.touched.password && FormData.errors.password}
         onBlur={FormData.handleBlur}
      
/>
</Grid>
<Grid item sx={10} style={{marginTop:"15px",marginLeft:"4%"}} className='phone-input-container'>
<Input
        label=" Confirm New Password*"
        type="password"
        id=" confirmPassword"
        className='input-box'
        placeholder="Confirm your Password"
        onChange={FormData.handleChange}
        onKeyUp={(e)=>displaybtn(e)}
        error={Boolean(FormData.touched. confirmPassword && FormData.errors. confirmPassword)}
        helperText={FormData.touched. confirmPassword && FormData.errors. confirmPassword}
         onBlur={FormData.handleBlur}
      
/>
</Grid>
     
      <Grid className={classes.button}>
      
      <Button variant="filled"
       type="submit" 
       disabled={!is_show}
       className={is_show ? classes.btn_nt_blur : classes.btn_blur}
       style={{ color: "white",
        width: "325px", borderRadius: "8px", 
        height: "43px",
         backgroundColor: "#2760A3", 
         marginTop: "4%" }} 

         >
       Submit
         </Button>
         
         </Grid>
         {showerror?
         <Grid style={{display:"flex",justifyContent:"center",marginTop: "3%" }}><span className='Responseerr'>{errormessage}</span></Grid>:''}
      <Grid>
     
      </Grid>
       </form>
      </Grid>

    

      </Grid>
    
    
  )
}

