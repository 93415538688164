import React from "react";
import { Typography, Paper, Grid, Box, TextField, Select,Radio, RadioGroup,FormControlLabel, Button, Modal,makeStyles, } from "@material-ui/core";
import {
	Routes,
	Route,
	NavLink,
	HashRouter
} from "react-router-dom";

const Main_footer=() => {
	return (
		<>
			<Box className="footer">
			   <div className="copyright">© SignAble 2022 All rights reserved</div>
	        </Box>
		</>
	)
}

export default Main_footer;