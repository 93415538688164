import React from "react";

function WebCamOnIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.474 7.192a.933.933 0 0 0-1.05 0l-3.675 1.95a3.362 3.362 0 0 0-.976-2.21A3.163 3.163 0 0 0 14.6 6H5.15a3.01 3.01 0 0 0-1.214.231c-.386.16-.736.4-1.03.703a3.202 3.202 0 0 0-.681 1.062A3.282 3.282 0 0 0 2 9.25v6.5c-.008.429.068.855.224 1.253.156.397.387.759.681 1.062.294.303.644.542 1.03.703.385.16.798.24 1.214.232h9.45a3.163 3.163 0 0 0 2.173-.931 3.362 3.362 0 0 0 .976-2.21l3.78 1.95c.24.104.508.119.757.04.25-.08.464-.247.605-.474.085-.166.121-.354.105-.541V8.167a1.326 1.326 0 0 0-.154-.549 1.281 1.281 0 0 0-.368-.426Z"
        fill="#fff"
      />
    </svg>
  );
}

export default WebCamOnIcon;
