import React,{useEffect,useState} from "react";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// import TabsContents from './TabsContents';
import {ReactComponent as TabImg_1} from "../../Assets/call-a-person-tab-1.svg";
import {ReactComponent as TabImg_2} from "../../Assets/call-a-person-tab-2.svg";
import {ReactComponent as TabImg_3} from "../../Assets/call-a-person-tab-3.svg";
import {ReactComponent as TabImg_4} from "../../Assets/call-a-person-tab-4.svg";
import Flag_Ind from "../../Assets/call-a-person-ind-flag.svg";
import Submit_btn from "../../Assets/call-a-person-main-form-v-call.png";
import {postDataFromApi,getDataFromApi,putDataFromApi} from '../../Service/CommonService';
import CheckAudioVideomain from "./CheckAudioVideomain"
import "../../Assets/css/style.css"


const Checkmain = () => {
	const [tabvalue, setTabvalue] = React.useState(0);
	const [languages,setlanguages]=useState([])
	const tabchange=(newValue)=>{
		setTabvalue(newValue);
	}

	useEffect(() => {
	    getlanguages();
	}, []);
	const getlanguages = async () => {
	    var query = "model=LanguageMasterData"
	    const response = await postDataFromApi('all/getAllMaster', query);
	    if(response.data.code && response.data.data!=null){
	    	setlanguages(response.data.data);
	    }

	}
	return (
		<div className="page-main">
			<div className="page-main-inner">
				{/* <h2 style={{color:"gray"}}>Under Development</h2> */}
				<CheckAudioVideomain />
			</div>
		</div>
	)
}

export default Checkmain;