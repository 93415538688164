import React from 'react';
import {
    HashRouter as Router,
    Link,
    Route,
    Routes
} from 'react-router-dom';
import { render } from "react-dom";
import Home from "./Components/HomeComponent/Home";
import Profile from "./Components/ProfileComponent/Profile";
import Call from "./Components/CallComponent/Call";
import Notification from "./Components/NotificationComponent/Notification";

// import Wallet from "./Components/WalletComponent/Wallet";
import Login from "./Components/LoginComponent/Login";
// import RegisterForm from './Components/RegisterComponent/RegisterForm';
// import Register from './Components/RegisterComponent/Register'

// import RegisterFormCorporate from "./Components/RegisterComponent/RegisterFormCorporate"

import Help from "./Components/HelpComponent/Help";
import CallaPerson from "./Components/CallaPersonComponent/CallaPerson";
import GetInterpreter from "./Components/GetInterpreterComponent/GetInterpreter";
import Conference from "./Components/ConferenceComponent/Conference";
import CallHistory from "./Components/CallHistoryComponent/CallHistory";
import ViewBalance from "./Components/ViewBalanceComponent/ViewBalance";
import ViewAllplans from "./Components/ViewBalanceComponent/ViewAllplans";
import CustomerSupport from "./Components/CustomerSupportComponent/CustomerSupport";
import VideoCall from "./Components/VideoCallComponent/VideoCall";
// import LoginPage from "./Interpreterloginpage/LoginPage"
import InterpreterHomepage from "./InterpreterHomepage/InterpreterHomePage"
import InterpreterProfilepage from "./InterpreterProfileComponent/Interpreterprofile"
import InterpreterProfileEditpage from "./InterpreterProfileComponent/InterpreterProfileEdit"
import InterpreterBreakPage from "./InterpreterProfileComponent/InterpreterBreak"
import InterpreterFeedbackform from './InterpreterFeedbackComponent/InterpreterFeedbackform';
import ForgetPassword from "./Components/LoginComponent/ForgotPassword"
import ResetPassword from "./Components/LoginComponent/ResetPassword";
import IncomingCallPage from "./Components/HomeComponent/IncomingCallPage"
import RatingPage from "./InterpreterFeedbackComponent/Rating"
import Checkmain from "./Components/CheckAudioVideo/Checkmain"
import VideosdkApp from "./VideoSdkComponents/VideosdkApp"

class MyRoutes extends React.Component
{
    render()
    {
        return (
         <Routes>
            <Route exact path="/" element={<Login/>}/>
            <Route path="/home" element={<Home/>}/>
            <Route path="/profile" element={<Profile/>}/>
            <Route path="/call" element={<Call/>}/>
            <Route path="/notification" element={<Notification/>}/>
            {/* <Route path="/wallet" element={<Wallet/>}/> */}
            {/* <Route path="/register" element={<Register/>}/>
            <Route path="/register-form" element={<RegisterForm/>}/>
            <Route path="/register-form-corporate" element={<RegisterFormCorporate/>}/> */}
           
            {/* <Route path="/InterpreterLogin" element={<LoginPage/>}/> */}
            
            <Route path="/InterpreterHome"  element={<InterpreterHomepage/>}/>
            <Route path="/InterpreterProfile" element={<InterpreterProfilepage/>}/>
            <Route path="/InterpreterProfileEdit" element={<InterpreterProfileEditpage/>}/>
            <Route path="/InterpreterBreak" element={<InterpreterBreakPage/>}/>
            <Route path="/Rating" element={<RatingPage/>}/>
            <Route path="/InterpreterFeedbackform" element={<InterpreterFeedbackform/>}/>
            <Route path="/help" element={<Help/>}/>

            <Route path="/CallaPerson" element={<CallaPerson/>}/>
            <Route path="/GetInterpreter" element={<GetInterpreter/>}/>
            <Route path="/Conference" element={<Conference/>}/>
            <Route path="/CallHistory" element={<CallHistory/>}/>
            <Route path="/ViewBalance" element={<ViewBalance/>}/>
            <Route path="/ViewAllplans" element={<ViewAllplans/>}/>
            <Route path="/CustomerSupport" element={<CustomerSupport/>}/>
            <Route path="/VideoCall" element={<VideoCall/>}/>
            <Route path ="/Forgetpassword" element={<ForgetPassword/>}/>
            <Route path='/resetpassword' element={<ResetPassword/>}/>
            <Route path='/IncomingCall' element={<IncomingCallPage/>}/>
            <Route path='/CheckAudioVideo' element={<Checkmain/>}/>
            <Route path='/VideoSdkCalling' element={<VideosdkApp/>}/>
            
        </Routes>
       )
    }
}

export default MyRoutes;