import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "../../Assets/css/style.css";
import { Typography, Button, Box } from "@material-ui/core";
import { ReactMic } from "react-mic";
import sound from "../../Assets/audio/StarWars60.wav";

function Audiotesting() {
  const [record, setRecord] = useState(false);
  const [data, setData] = useState(false);
  const [somevar, setSomevar] = useState(0);
  const [soundvar, setSoundvar] = useState(0);
  const [show, setShow] = useState(false);

  const startRecording = () => {
    setShow(true);
    setRecord(true);
  };
  const stopRecording = () => {
    setShow(false);
    setRecord(false);
  };
  function onData(recordedBlob) {
    console.log("chunk of real-time data is: ", recordedBlob.size);
    {
      if (recordedBlob.size > 1200) {
        // setData(true)
        // console.log("audio");
        // setSomevar(somevar + 1);
        console.log(somevar);
      }
    }
  }
  function onStop(recordedBlob) {
    console.log("recordedBlob is: ", recordedBlob);
  }
  const x = new Audio(sound);
  function play() {
    x.play();
  }
  function pause() {
    x.pause();
  }

  console.log("show", show);
  console.log("record", record);
  return (
    <>
      <div>
        <Typography variant="h6" className="micclass ">
          Microphone
        </Typography>
        <div className="micmaindiv ">
          <div className="micdivinner1">
            <Button
            // color="primary"
            style={{backgroundColor:"#2760A3",color:"white"}}
              variant="contained"
              className="micbutton1 buttonrad"
              onClick={startRecording}
            >
              Start
            </Button>
            <Button
            // color="primary"
            style={{backgroundColor:"#2760A3",color:"white"}}
              variant="contained"
              className="micbutton2 buttonrad"
              onClick={stopRecording}
            >
              stop
            </Button>
          </div>

          <div className={!show?"micdivinner2":""}>
            <Box>
              {/* {record ? (
                <div className="micdiv">
                  <ReactMic
                    record={record}
                    className="sound-wave"
                    visualSetting="frequencyBars"
                    //   visualSetting="sinewave"
                    onStop={onStop}
                    onData={onData}
                    //   strokeColor="#000000"
                    strokeColor={"grey"}
                    backgroundColor="white"
                  />
                </div>
              ) : (
                ""
              )} */}
              <div className="micdiv">
                <ReactMic
                  record={record}
                  className="sound-wave"
                  visualSetting="frequencyBars"
                //   visualSetting="sinewave"
                  onStop={onStop}
                  onData={onData}
                //   strokeColor="#000000"
                  strokeColor={"grey"}
                  backgroundColor="white"
                />
              </div>
            </Box>
          </div>
        </div>
      </div>
      <div className={!show?"speakermain":""}>
        <Typography variant="h6" className="speakerclass">
          Speakers
        </Typography>
        <div className="micbuttons3">
          <Button
          // color="primary"
          style={{backgroundColor:"#2760A3",color:"white"}}
            variant="contained"
            className="micbutton3 buttonrad"
            onClick={() => {
              play();
            }}
            // onClick={() => {
            //   setSoundvar(1)
            // }}
          >
            Test
          </Button>
          <Button
          // color="primary"
          style={{backgroundColor:"#2760A3",color:"white"}}
            variant="contained"
            className="micbutton4 buttonrad"
            onClick={() => {
              pause();
            }}
            // onClick={() => {
            //     setSoundvar(0)
            // }}
          >
            stop
          </Button>
        </div>
      </div>
    </>
  );
}

export default Audiotesting;
