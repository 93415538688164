import React, { useEffect, useState, useRef } from "react";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
// import ListItemButton from '@mui/material/ListItemButton'
import SearchIcon from "../../Assets/call-a-person-search-img.svg";
import Contact_img from "../../Assets/IncomingcallLogo.svg";
import iconVideo from "../../Assets/icon-video-call.svg";
import Contact_img2 from "../../Assets/call-a-person-contact-img2.svg";
import {ReactComponent as Icon_HeaderCall} from "../../Assets/home-call-icon.svg";
import { ReactComponent as Clock } from "../../Assets/clock 1.svg";
import Spinner_150 from "../../Assets/Spinner-1s-150px.svg";
import { ReactComponent as Icon_closegrey } from "../../Assets/close-grey.svg";
import { postDataFromApi, getDataFromApi, putDataFromApi } from '../../Service/CommonService';
import { getprofile, getAuth } from '../../Service/Helper';
import moment from 'moment';
import { TextField, Box } from "@material-ui/core";
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
import $ from 'jquery';
import {sdkAPIKey,CallStatusUpdateData} from '../../Service/videoskp';
import swal from "sweetalert";
import { useNavigate } from 'react-router-dom'
import Popup from 'reactjs-popup';
const IncomingCall = (props) => {
    const authdata = getAuth()
    const [is_Callloaded, setCallLoaded] = useState(false)
    const [onBreak, setonBreak] = useState(false)
    const [open, setOpen] = useState(false);
    const userid=authdata['_id'];
    const [mycontacts, setmycontacts] = useState([]);
    const [isvideocallstarted,setisvideocallstarted]=useState(false)
    const navigate = useNavigate()
 
    

	const receiveCall= async (call,index) => {
		console.log(mycontacts)
		console.log(call)
		var inputData = JSON.stringify({
            "call_setup_id": call._id,
            "user_type": "1",
            "signable_user_type": "1",
            "user_id": userid,
            "is_call_end": "3",
            "call_end_type": "0",
            "call_status": "2",
            "call_attendee_status": "3",
            "internet_bandwidth": '',
            "interpreter_sequence": call.interpreter_sequence
        })
		const response = await CallStatusUpdateData(inputData);
        console.log('CallStatusUpdate',response)

        localStorage.setItem('callSetupid', call._id)
        
        if (response && response.code == 1) {
        	const config = {
		    	name: authdata['first_name'],
		    	meetingId: call.meetingId,
		    	apiKey:sdkAPIKey,
		    	// joinScreen: {
		    	// 	visible: false,

		    	// },
		    	containerId: "meeting-container-id",
		    	micEnabled: true,
		    	webcamEnabled: true,
		    	participantCanToggleSelfWebcam: true,
		    	participantCanToggleSelfMic: true,

		    	chatEnabled: true,
		    	screenShareEnabled: true,
		    	canChangeLayout: true,
		    	redirectOnLeave:window.location.origin+'/InterpreterFeedbackform',
		    	joinScreen: {
		    		visible: false,
		    	},
		    };

		    const meeting = new VideoSDKMeeting();
		    meeting.init(config);
		   	$(".full-screen").insertAfter(".page-main");
		   	setisvideocallstarted(true)
        }else{
           swal({
				// title: "client added!",
				text: response.message,
				icon: "error",
		    }).then(function() {
			    navigate('/home')
			});
        }
        
	  /*console.log(call)
	  console.log(call.meetingId)
	  */

	   	
	}

    if(!isvideocallstarted){
    return (
        <Grid item sm={8} className="my-contacts" style={{ marginTop: "3%", marginLeft: "2%" ,backgroundColor:"#1D487A"}}>
            <div className="IncomingCallLogo">
                <img src={Contact_img} style={{width:"65px",height:"65px"}} />
                </div>
                <div className="calltext">
                <span>Incoming video call</span>
                </div>
                <div className="home-user-profile-img">
					<img src={Contact_img2} className="incoming-call-profile-img"/>
                </div>
                <div className="calltext">
                <span className="video-call-person-name">{authdata.first_name+" "+authdata.last_name}</span>
                </div>

                <div className="incoming-call-button">
                    <Button variant="contained" className="video-button" onClick={()=>props.receiveCall(props.selectcalldetails,props.index)}> <img src={iconVideo}/>Join with video</Button>
                    {/* <Button>decline</Button> */}
                </div>
                <div className="decline-button">
                    <Button   onClick={()=>props.setIncomingCallPagestatus(false)} >Decline</Button>
                    {/* <Button>decline</Button> */}
                </div>
          


        </Grid>

    )
 }
 else{
	return (<Box className="full-screen"><div id="meeting-container-id"></div></Box>)
}
}
// }


export default IncomingCall;