import React, { useEffect ,useState} from "react";
import Logo from "./CommonComponent/Logo"
/*import RegisterForm from "./Components/RegisterComponent/RegisterForm"*/
import Login from "./Components/LoginComponent/Login";
/*import Register from "./Components/RegisterComponent/Register"*/
import Main_header from "./Components/Header/Header";
import Main_footer from "./Components/Header/Footer";
/*import Main_sidebar from "./Components/Header/Sidebar"*/
import Home_page from "./Components/HomeComponent/Home";
import MyRoutes from './MyRoutes';
import { useNavigate } from 'react-router-dom'
import { requestForToken, onMessageListener } from './firebase';
import { getTokenInApp } from "./firebase";
import SweetAlert from 'react-bootstrap-sweetalert';
import useSound from 'use-sound';
//import boopSfx from 'sounds/';
import { Button, Snackbar ,Checkbox} from "@material-ui/core";
//import boopSfx from './Assets/boop.mpeg';
import sound from './Assets/audio/LandlineSound.mp3'


import { useLocation } from 'react-router-dom';
function App() {
  const location = useLocation();
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false);
  const [isTokenFound, setTokenFound] = useState(false);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});
  const [alertShow, setalertShow] = useState(false);
  const [notificationBody,setnotificationBody] = useState('');
  const [notificationTitle, setnotificationTitle] = useState('');
  const [playOn,{ stop }] = useSound(sound,{ volume: 0.25 });
  const [playOff] = useSound(sound,{ volume: 0.20 });

  // useEffect(() => {
    getTokenInApp(setTokenFound);
  // },[])
  const authurls=['/','/otp','/register','/register-form','/otp-phone','/InterpreterLogin','/Forgetpassword','/resetpassword','/emailOtp']
  if(authurls.includes(location.pathname))
  {

    if(localStorage.getItem("accessToken")!=null){
      if(localStorage.getItem("callSetupid")!=null){
        navigate('/InterpreterFeedbackform')
      }
      else{
        navigate('/home')
      }
      
    }

    const reDirectNotification=()=>{
      setalertShow(false)
      //  playOff()
      stop()
      // navigate('/home')
    }

    
    return (
      <>
     {alertShow ? 
     <SweetAlert
    title={notificationTitle}
    onConfirm={reDirectNotification}
    style={{top: "15px",left: "25px",position: "none !important",
    justifyContent: "center",
    marginTop: "12px"}}
   >{notificationBody}
   {/* customButtons={
    <Button onClick={reDirectNotification}>Click</Button>
      } */}
 </SweetAlert> : ''}
      <MyRoutes/>
     </>
    );
  }
  else{
    if(localStorage.getItem("accessToken")==null){
      navigate('/')
    }

    // useEffect(() => {
    // console.log('title',notificationTitle);
    onMessageListener().then(payload => {
      // console.log('testteste---->',payload)
      setnotificationTitle(payload.notification.title)
      setnotificationBody(payload.notification.body)
      // console.log("notificationBody",payload.notification.body);
      // console.log("notificationTitle",payload.notification.title);
      setShow(true);
      setNotification({title: payload.notification.title, body: payload.notification.body})
      setalertShow(true)
      setTimeout(notificationFunction,16000);
    // useof()
    playOn()
    

    }).catch(err => console.log('failed: ', err));
  // },[])

  function notificationFunction(){
    console.log("notification closes now")
    setalertShow(false)

  }

    const reDirectNotification=()=>{
      setalertShow(false)
      stop()
      //  playOff()
      // navigate('/home')
    }

    return (
      <>
    
     {/* <Logo/> */}
      {/* <Login/> */}
      {/* <SweetAlert
     customButtons={
     <Button onClick={reDirectNotification}>Click</Button>
      }
    title="Woot!"
    style={{
    //position: "fixed",
    top: "15px",
    left: "25px",
    position: "none !important",
    justifyContent: "center",
    marginTop: "12px"
   }}
>
  I did it!
</SweetAlert> */}
  {alertShow ? 
  <SweetAlert
    title={notificationTitle}
    onConfirm={reDirectNotification}
    style={{top: "15px",left: "25px",position: "none !important",
    justifyContent: "center",
    marginTop: "12px"}}
   >{notificationBody}
   {/* customButtons={
   
      <Button onClick={reDirectNotification}>Click</Button>
      
    
  } */}
 </SweetAlert> : ''}
       <Main_header/>

        {/* <div >
        {isTokenFound && <h1> Notification permission enabled 👍🏻 </h1>}
        {!isTokenFound && <h1> Need notification permission ❗️ </h1>}
        </div> */}
       <MyRoutes/>
       <Main_footer/>  
     </>
    );
  }

}

export default App;
