import { SvgIcon } from "@material-ui/core";
import React from "react";

const EndCall = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 28 9.598">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="9.598"
        viewBox="0 0 28 9.598"
      >
        <path
          id="Path_5701"
          data-name="Path 5701"
          d="M14,16.167a18.6,18.6,0,0,0-5.367.78V20.31a1.087,1.087,0,0,1-.653.975,13.6,13.6,0,0,0-3.109,2,1.213,1.213,0,0,1-.817.309,1.185,1.185,0,0,1-.822-.32L.344,20.6a1.032,1.032,0,0,1,0-1.538A20.593,20.593,0,0,1,14,14a20.593,20.593,0,0,1,13.656,5.059,1.035,1.035,0,0,1,.344.769,1.023,1.023,0,0,1-.344.764l-2.888,2.681a1.21,1.21,0,0,1-.823.32,1.224,1.224,0,0,1-.817-.309,13.6,13.6,0,0,0-3.109-2,1.087,1.087,0,0,1-.653-.975V16.941A18.782,18.782,0,0,0,14,16.167Z"
          transform="translate(0 -14)"
        />
      </svg>
    </SvgIcon>
  );
};

export default EndCall;
