import React,{useState} from "react";
import Popup from 'reactjs-popup';
import {
	Routes,
	Route,
	NavLink,
	HashRouter,
	useNavigate
} from "react-router-dom";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SearchIcon from "../../Assets/call-a-person-search-img.svg";
import Contact_img from "../../Assets/call-a-person-contact-img.svg";
import Contact_img2 from "../../Assets/call-a-person-contact-img2.svg";
import Contact_img3 from "../../Assets/call-a-person-contact-img3.svg";
import Conference_user from "../../Assets/conference-user.svg";
import Conference_user_remove from "../../Assets/conference-user-remove.svg";
import Two_user from "../../Assets/two_user.svg";
import Two_userwhite from "../../Assets/two-user-white.svg";
import Multiple_userwhite from "../../Assets/multiple-user-white.svg";
import Interpreter_profile from "../../Assets/interpreter-profile.svg";
import Connecting_interpreter_1 from "../../Assets/connecting-interpreter-1.svg";
import Connecting_interpreter_2 from "../../Assets/connecting-interpreter-2.svg";
import {ReactComponent as Iocn_Callback} from "../../Assets/callback.svg";
import {ReactComponent as Iocn_Cancel}  from "../../Assets/cancel.svg";
import Connecting from "../../Assets/connecting.svg";
import {ReactComponent as Icon_whitevideocall} from "../../Assets/white-video-call.svg";
import {ReactComponent as Icon_teluguicon} from "../../Assets/telugu-icon.svg";
import {ReactComponent as Icon_time} from "../../Assets/time.svg";

const ProccessingCall = (props) => {
	const navigate =useNavigate()
	console.log(props.selectedmember)
	function callbackrequest(){
		navigate('/Home')
	}
	return (
			<div className="processing-call-tab-content">
				<div className="processing-call-tab-inner">
				   <div className="calling-names-dash">
				      <div className="inte-profile">
                         <img src={Interpreter_profile} />
				      </div>
				      <div className="inte-profile-details">
				         <span>Dashing Manbros</span>
				         <div>
				            <span className="connecting_person">{props.selectedmember.member.length} <img src={Multiple_userwhite} /></span>
				            <span className="connecting_person_names"><img src={Two_userwhite} /> {props.selectedmember.contactdetails[0].contact_name}{props.selectedmember.member.length > 1 ? '+' : ''}{props.selectedmember.member.length > 1 ?  props.selectedmember.member.length - 1 : ''}</span>
				         </div>
				      </div>
				   </div>
				   <div className="connect-interpreter">
				      <img src={Connecting} />
				      <Typography>Connecting with an interpreter</Typography>
				      <div className="connect-anim">
					        <div className="connect-anim-1">
					        	<img src={Connecting_interpreter_1} className="Connecting_interpreter" />
					        </div>
					        <div className="connect-anim-2">
					        	<div className="dootedline" >
									<marquee direction="right" scrollamount="15">
									 	<i className="fa fa-caret-right"></i>
									</marquee>
								</div>
								<i className="fa fa-hourglass-2 fa-spin floating-icon"></i>
					        </div>
					        <div className="connect-anim-3">
					        	<img src={Connecting_interpreter_2} className="Connecting_interpreter" />
					        </div>
				        </div>
				      <div className="two_buttons">
				      <Button className="secondary" variant="text" onClick={()=>props.setNotconnected(true)} ><Iocn_Cancel /> Cancel</Button>
				       <Popup className="processingcall-modal"
						    trigger={<Button className="primary" variant="text"  ><Iocn_Callback />Request Callback</Button>
				       }
						    modal
						    nested
						  >
						    {close => (
						      <div className="modal interpreter">
						        <div className="header"> We will connect you to an
                                    interpreter soon. </div>
						        <div className="content special-svg">
						          {' '}
						            <Icon_time />
						        </div>
						        <div className="actions">
						          <Button className="primary" onClick={()=>callbackrequest()}>Ok</Button>
						        </div>
						      </div>
						    )}
						 </Popup>
				      </div>
				   </div>
				</div>
			</div>
		)	
}

export default ProccessingCall;