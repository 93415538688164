import React,{useEffect,useState} from "react";
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TabsContents from './TabsContents';
import { ReactComponent as TabImg_1 } from "../../Assets/call-a-person-tab-1.svg";
import { ReactComponent as TabImg_2 } from "../../Assets/call-a-person-tab-2.svg";
import { ReactComponent as TabImg_3 } from "../../Assets/call-a-person-tab-3.svg";
import { ReactComponent as TabImg_4 } from "../../Assets/call-a-person-tab-4.svg";
import Flag_Ind from "../../Assets/call-a-person-ind-flag.svg";
import Submit_btn from "../../Assets/call-a-person-main-form-v-call.png";




const Call_page = () => {

	const [tabvalue, setTabvalue] = useState(0);
	const [languages,setlanguages]=useState([])
	const tabchange=(newValue)=>{
		setTabvalue(newValue);
	}
	return (
		<div className="page-main">
			<div className="page-main-inner-for-call-page">
				<Box>
					<Grid>
						<Tabs
							value={tabvalue}
							onChange={(event, newValue) => tabchange(newValue)}
							className="call-person-tab-header custom_scrollbar">
							<Tab label="Call"
								icon={<TabImg_1 />}
							/>
							<Tab label="Recent Calls"
								icon={<TabImg_2 />}
							/>
							<Tab label="Contacts"
								icon={<TabImg_3 />}
							/>
							<Tab label="My Groups"
							icon={<TabImg_4/>}
							/>
						</Tabs>
						<TabsContents tabvalue={tabvalue}/>	
					</Grid>
				</Box>
				{/* <h1>Call Page</h1> */}
			</div>
		</div>
	)
}

export default Call_page;