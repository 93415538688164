
export const getprofile = (fname="",lname="") => {
    var name=""
    var flater=""
    var llater="";
    if(lname==""){
    	var spname=fname.split(' ')
    	lname=spname.length > 0 ? spname[1] : ''
    	if(/^[a-zA-Z0-9]*$/.test(lname) == false) {
    		lname=""
    	}
    }
    flater=fname ? fname.charAt(0) : "";
    llater=lname ? lname.charAt(0) : "";
    name=flater+''+llater
    return name;
};

export const getAuth =()=>{
    return localStorage.getItem("userData") ?  JSON.parse(localStorage.getItem("userData")) : "";
}