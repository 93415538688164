import React, { useEffect, useState, useRef } from "react";
import moment from 'moment';
import ProfilePic from "../../Assets/home-user-profile.svg";
import HomeChange from "../../Assets/home-profile-change.svg";
import MyConProfile from "../../Assets/my-contact-profile.svg";
import MultipleUser from "../../Assets/multiple-user.svg";
import HomeCallHistory1 from "../../Assets/home-call-history-1.svg";
import HomeCallHistory2 from "../../Assets/home-call-history-1.svg";
import HomeCallHistory3 from "../../Assets/home-call-history-1.svg";
import style from '../../Assets/css/style.css'
import stylenew from '../../Assets/css/substyle.css'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { MdLocationOn, MdPhone, MdStayCurrentPortrait, MdEmail, MdOutlineLanguage } from "react-icons/md";
import { postDataFromApi, getDataFromApi, putDataFromApi, patchDataFromApi } from '../../Service/CommonService';
import { getprofile, getAuth } from '../../Service/Helper';
import Spinner_150 from "../../Assets/Spinner-1s-150px.svg";
import SearchIcon from "../../Assets/call-a-person-search-img.svg";
import Contact_img from "../../Assets/call-a-person-contact-img.svg";
import EditBtn from "../../Assets/edit-btn.svg"
import swal from "sweetalert";
import {
	Routes,
	Route,
	NavLink,
	HashRouter
} from "react-router-dom";

const Profile_Page = (props) => {
	const [is_loaded, setLoaded] = useState(false)
	const [is_grouploaded, setgroupLoaded] = useState(false)
	const [mycontacts, setmycontacts] = useState([])
	const [InterpreterProfile, setInterpreterProfile] = useState([])
	const [formdata, setFormData] = useState({ fileName: "" })
	const authdata = getAuth()
	// const userid=8;
	const interpreterid = authdata['_id'];
	const contactDetails = [];
	const interpeter_id = 77
	const fileInput = useRef(null)
	useEffect(() => {
		getInterpreterProfile();
		// ProfileImageUpload();

	}, []);
	const getInterpreterProfile = async () => {
		setLoaded(true);
		var query = ""
		const response = await getDataFromApi('find/interpreterProfile/' + interpreterid);
		if (response.data.code && response.data.data != null) {
			console.log('Hii',response.data.code)
			setInterpreterProfile(response.data.data);
		}
		setLoaded(false);

	}
	const ProfileImageUpload = async (selectedFile="") => {
		var query = {
			file_name: formdata.fileName ? formdata.fileName : selectedFile,

		}
		const response = await patchDataFromApi('interpreterProfileImage/update/' + interpreterid, query);
		if (response.data.code) {
			console.log(response.data.message)

			swal({
				// title: "client added!",
				text: response.data.message,
				icon: "success",
			}).then(function () {
				// navigate('/home')
			});
		} else {
			swal({
				// title: "client added!",
				text: response.data.message,
				icon: "error",
			}).then(function () {
				//navigate('/home')
			});
		}

	}

	const filechange = (event) => {
		let files = event.target.files
		console.log("files",files[0])
		let reader = new FileReader()
		reader.readAsDataURL(files[0])
		var selectedFile = ''
		reader.onload = (e) => {
			console.log("hii")
			selectedFile = e.target.result

			console.log("file",selectedFile)
			// var values = formdata['file']
			// values[event.target.name] = selectedFile 
			ProfileImageUpload(selectedFile)
		}
		
	}
	//   interpreterProfileImage/update/:userId
	//   file_name: base_64


	// const getMyContact = async () => {
	// 	setgroupLoaded(true);
	//     var query = "user_id="+userid+'&contactDetails=[]'
	//     const response = await postDataFromApi('add/contactDetailsNew', query);
	//     if(response.data.code && response.data.data!=null){
	//     	setmycontacts(response.data.data);	
	//     }
	//     setgroupLoaded(false);
	// }
	const handleFileClick = () => {
        fileInput.current.click()
    }
	return (
		<div className="page-main">
			<div className="page-main-inner">
				<img className="edit-img" src={EditBtn} />  <NavLink to="/InterpreterProfileEdit">  <span className="edit">Edit</span></NavLink>
				<Box>

					<Grid container spacing={3} className="main-grid home-grid">
						{
							InterpreterProfile.map(function (data,) {
								return (
									<>
										<Grid item md={4} className="home-user-profile">
											<div className="box-shadow text-center radius-8px">
												<div className="home-user-profile-inner">
													<div className="home-user-profile-top-txt">
														<p className="blue-title">Profile</p>
													</div>
													<div className="home-user-profile-img">
														{/* <p className="profile_intial">{getprofile(authdata.first_name,authdata.last_name)}</p> */}
														{/* <img src={Contact_img} className="profile_intial" /> */}
														{/* <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" className="profile_intial" /> */}
														<img 
														src="https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255710-stock-illustration-avatar-vector-male-profile-gray.jpg" 
														className="profile_intial" />
														<img className="intsa_icon" src={HomeChange} onClick={(e)=>handleFileClick(e)}/>
														<input
															type="file"
															onChange={(event) => filechange(event)}
															ref={fileInput}
															name={"fileName"}
															// value={formdata.fileName}
															style={{display:"none"}}
														/>
													</div>
													<div className="profile_details">
														<span>Hello</span>
														<span className="prof-user-name">{data.first_name + data.last_name}</span>
													</div>
												</div>
											</div>
										</Grid>
										<Grid item md={8} className="contact-info">
											<div className="box-shadow radius-8px">
												<div className="contact-info-inner add-padding-bott">
													<div className="blue-title">Contact Info</div>
													<div className="contact-info-detail">

														{/* <div><span>Phone:</span><span className="normal-gery-text">3456767678</span></div> */}
														<div><MdPhone size="25px" color="#515151" /><span className="normal-gery-text">{data.mobile_number ? data.mobile_number : "-"}</span></div>
														<div><MdEmail size="25px" color="#515151" /><span className="normal-gery-text">{data.email ? data.email : "-"}</span></div>
														<div><MdOutlineLanguage size="25px" color="#515151" /><span className="normal-gery-text">{data.user_details_data.map((lang, i) => {
															return lang.language_masters[0] ? lang.language_masters[0].language && lang.language_masters[0].language + (((i+1)==data.user_details_data.length)?'':", ") : '-'
														})}</span></div>
													</div>
												</div>
											</div>
										</Grid>
									</>
								)
							})
						}


					</Grid>

				</Box>
			</div>
		</div>
	)
}

export default Profile_Page;