import React  , { useState,useEffect } from 'react'
import { ReactComponent  as Vector} from "../../Assets/icon.svg";
import {NavLink} from "react-router-dom";
import Input from '../../CommonComponent/Input'
import { Typography, Paper, Grid, Box, TextField, Select,Radio, RadioGroup,FormControlLabel, Button, Modal,makeStyles, } from "@material-ui/core";

import {postDataFromApi,getDataFromApi} from '../../Service/CommonService'
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import style from '../../Assets/css/style.css'
import {getprofile,getAuth} from '../../Service/Helper';

import {
  HashRouter as Router,
  Link,
  Route,
  Routes,
  useNavigate
} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
      flex: 1,
      backgroundColor: '#fff',
      justifyContent: 'center',
      paddingHorizontal: 40,
  },
  inputContainer: {
      backgroundColor: '#FFFFFF',
      paddingHorizontal: 15,
      borderRadius: 8,
      borderColor: '#D0E1F4',
      border: '2px solid ',
      borderWidth: 2,
      
      // height: 60,
      // width: '80%'
  },
  row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      border: "1px solid rgb(42, 84, 250)",
      width:"136px",
      height:"86px",
      borderRadius:"5px",
      paddingTop:"2px",
      justifyContent:"center",
      alignItems:"center",
      marginTop:"45px",
      marginRight:"8px",
      marginLeft:"6px",
     
     
  },
  inputLabel: {
      paddingTop: 5,
      fontSize: 13,
      color: '#5490D6',
     marginBottom:"2px",
     textAlign:"center",
     marginTop:"30px"
  },
  input: {
      color: '#515151',
      fontWeight: 'bold',
      fontSize: 14,
      flex: 1,
      // width:"397px",
      textAlign:"center"
     
  },
  mainDiv:{
      justifyContent:"center",
      alignItems:"center",
      display: "flex",
     backgroundColor:"white",
     opacity:"1",
     padding:"20",
     height:"341px",
     width:"825px"
    },
    div1:{
      height:"190px",
      width:"343px",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
     

    },
    div2:{
      height:"190px",
      width:"343px",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
    },
   image:{
      position: "absolute",
      width: "50px",
      height: "60px",
       top:"-39px",
       marginLeft:"38px"
       
      // zIndex: -1,
      
    },
    
      signText:{
        fontWeight:"bolder",
        fontSize:"38px", 
        color:"#2760A3",
        textAlign:"center"
      },
      ableText:{
        display:"center",
        fontSize:"38px",
        color:"#FF9D00",
        fontWeight:"bolder",
        textAlign:"center"
      },
    btn_nt_blur:{
      width:"302px",
      borderRadius:"8px",
      backgroundColor:"#2760A3"
    },
    btn_blur:{
      width:"302px",
      borderRadius:"8px",
      backgroundColor:"#D0E1F4 !important"
    },
    button:{
      display:"flex",
      justifyContent:"center",
     

    }


}))


export default function Login() {
const [isOpen, setIsOpen] = useState(false);
const [showerror, setshowerror] = useState(false);
const [loginData,setloginData]=useState([])
const [mycontacts,setmycontacts]=useState([]);
const navigate=useNavigate();
const [errormessage, seterrormessage] = useState('');
const [is_show,setShow]=useState(false);
const [breakId,setbreakId]=useState('')


let validationSchema = Yup.object({
  email: Yup.string().email('Must be a valid email').trim().max(255)
  .required('Email is required'),
  password: Yup.string().max(255).required("Password is required"),
})

var notificationToken= localStorage.getItem('NotificationToken','currentToken')
console.log('noti',notificationToken)
const FormData = useFormik({
  initialValues: {
      email: "",
      password:""
  },
  validationSchema:validationSchema,
  

  onSubmit:async (value)=>{

    var query={email: value.email,password:value.password ,fcm_token:notificationToken,device_id:"",source: "Web"}
    const response = await postDataFromApi('login-interpreter', query);
    
    if(response.data && response.data.code){
     setloginData(response.data.data)
     console.log("login response",response.data.data)
     localStorage.setItem('userData', JSON.stringify(response.data.data));
     localStorage.setItem('accessToken','123')
     navigate('/home')
     endbreak()
       
    }else{
    //  alert('test')
       setshowerror(true)
       seterrormessage(response.data.message)
       console.log('responses',response.data.message);
    }
    
  }
})
// const getBreakList = async () => {
//   const authdata=getAuth()
//   const interpreterid=authdata['_id'];
//   var inputData = "model=interpretorAvailibilityLogMaster&interpeter_id="+interpreterid
//       const response = await postDataFromApi('all/getAllMaster', inputData);
//       // console.log('breackresponse----->',response)
//       console.log('breackresponse----->',response.data.data[0]._id)
//       if(response.data.code   ){
//         setmycontacts(response.data.data);
//         console.log('list',response.data.data)
//         if (response.data.data[0].type === 1) {
//           // alert('hi')
//          // setIsBreaktime(true)
//           //setOpen(false)
//           setbreakId(response.data.data[0]._id)
//          // startTimer(response.data)
//         }
       
//       }
//   }
  
function displaybtn(e){
  if(FormData.email!="" && FormData.password!=""){
    setshowerror(false)
    seterrormessage('')
    // if(FormData.email.test(e.target.value)){
    //   setShow(true)
    // }else{
    //   setShow(false)
    // }
    setShow(true)
  }
  else{
    setShow(false)
  }
}
const checkredirectpage=()=>{
    // const pathname=window.location.pathname
    // var ex=pathname.split('/');
    // if(ex[ex.length-1]=='InterpreterFeedbackform'){
    //   navigate('/InterpreterFeedbackform')
    //   // window.history.replaceState(null, '', '/#/InterpreterFeedbackform');
    // }
    if(localStorage.getItem("callSetupid")!=null){
      navigate('/InterpreterFeedbackform')
    }
  }

  const endbreak= async () =>{
    const authdata=getAuth()
    const interpreterid=authdata['_id'];
    var inputReceived = "model=interpretorAvailibilityLogMaster&interpeter_id="+interpreterid
      const getAllMasterResponse = await postDataFromApi('all/getAllMaster', inputReceived);

    var inputData ={
      interpeter_id:interpreterid,
      type:2,
      // id:breakId
      id:(getAllMasterResponse.data.data[0]._id)? getAllMasterResponse.data.data[0]._id:""
    }

    const response = await postDataFromApi('update/interpretorAvailibilityLogChangeStatus', inputData);
    console.log('breackresponse',response)
    if(response.code){
      
      // clearCurrentInterval()
      // setBreakStartedTime('')
      // setbreakId('')
      // getBreakList()
      
    }
  }
  useEffect(() => {
   
   checkredirectpage() 
   if(localStorage.getItem("accessToken")!=null){
      
    navigate('/home')
  }
    
  }, []);
  const classes=useStyles()
  return (
    <Grid container className="login-container interpretlogin" spacing={0}
       direction="column"
       alignItems="center"
       justifyContent="center"
      >
          <Grid style={{ whiteSpace: "nowrap",textAlign: "center",marginTop:"20px"}}>
         <Vector  style={{width: '120px', height: '110px'}}/>
      
        <div >  
           <span className={classes.signText}>Sign</span> 
           <span className={classes.ableText}>Able</span>
       </div> 
        </Grid>
<Grid className='header-login'>
  <div className='login-text-top'>
   <span className='login-text-interpreter'>Login</span> 
    </div>
<form onSubmit={FormData.handleSubmit}>
<Grid item sx={10} style={{marginTop:"15px",marginLeft:"4%"}} className='phone-input-container'>
<Input
        label=" Email*"
        id="email"
        className='input-box'
        value={FormData.values.email}
        onChange={FormData.handleChange}
        onKeyUp={(e)=>displaybtn(e)}
         error={Boolean(FormData.touched.email && FormData.errors.email)}
          helperText={FormData.touched.email && FormData.errors.email}
         onBlur={FormData.handleBlur}
       placeholder="Enter your Email"
      
       />
      </Grid>
      <Grid item sx={10} style={{marginTop:"15px",marginLeft:"4%"}} className='phone-input-container'>
       <Input
        label="Password*"
        type="password"
        id="password"
        className='input-box'
        placeholder="Enter your Password"
        onChange={FormData.handleChange}
        onKeyUp={(e)=>displaybtn(e)}
        error={Boolean(FormData.touched.password && FormData.errors.password)}
        helperText={FormData.touched.password && FormData.errors.password}
         onBlur={FormData.handleBlur}
      
       />
      </Grid>
      <Grid className={classes.button}>
      
      <Button variant="filled"
       type="submit" 
       disabled={!is_show}
       className={is_show ? classes.btn_nt_blur : classes.btn_blur}
       style={{ color: "white",
        width: "325px", borderRadius: "8px", 
        height: "43px",
         backgroundColor: "#2760A3", 
         marginTop: "4%" }} 

         >
        Login
         </Button>
      
         </Grid>
         <Grid  className="forgot-password-grid">
         <NavLink  style={{alignContent:"center",alignItems:"center",display:"flex"}} to="/Forgetpassword"> Forgot Password ?</NavLink>
         </Grid>
         {showerror?

         <Grid style={{display:"flex",justifyContent:"center",marginTop: "3%" }}><span className='Responseerr'>{errormessage}</span></Grid>:''}
      <Grid>
     
      </Grid>
       </form>
      </Grid>

    

      </Grid>
    
  )
}

