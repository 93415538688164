
import {mainURL}  from './CommonService'
import {getprofile,getAuth} from '../Service/Helper';
/*export const sdkAPIKey ="98b74378-ec25-4809-9924-a9d1200d588f"*/
export const sdkAPIKey ="378c5971-9f73-494e-ba84-1d46661a5947"
const BaseUrl=mainURL

const authdata=getAuth()
const userid=authdata['_id'];

export const getToken = async () => {
    try {
        const response = await fetch(mainURL + `/get-token`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const { token } = await response.json();
        return token;
    } catch (e) {
        console.log(e);
    }
};
export const createMeeting = async (token) => {
    try {
        const VIDEOSDK_API_ENDPOINT = BaseUrl + `/create-meeting`;
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ token }),
        };
        const response = await fetch(VIDEOSDK_API_ENDPOINT, options)
        .then(async (result) => {
            const response = await result.json();
            return response;
        })
        .catch((error) => console.log("error", error));
        return response;
    } catch (e) {
        console.log(e);
    }
};

export const CallStatusUpdateData = async (inputData,is_from_feedback="",call_setup_id="",call_status="",call_attendee_status="") => {
    if(is_from_feedback){
          inputData = JSON.stringify({
            "call_setup_id": call_setup_id,
            "user_type": "1",
            "signable_user_type": "1",
            "user_id": userid,
            "is_call_end": "1",
            "call_end_type": "4",
            "call_status": call_status,
            "call_attendee_status": call_attendee_status
        })
    }
    try {
        const options = {
            method: "POST",
            headers: {
                 Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: inputData,
        };
        const response = await fetch(BaseUrl + '/add/CallStatusUpdate', options)
        .then(async (result) => {
            const response = await result.json();
            return response;
        })
        .catch((error) => console.log("error", error));
        return response;
    } catch (e) {
        console.log(e);
    }
};


// export const callSetup = async (data) => {
//     try {
//         const options = {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",
//             },
//             body: data,
//         };
//         const response = await fetch(BaseUrl + `add/CallSetup`, options)
//         .then(async (result) => {
//             const response = await result.json();
//             return response;
//         })
//         .catch((error) => console.log("error", error));
//         return response;
//     } catch (e) {
//         console.log(e);
//     }
// };
export const callSetup = async (data) => {
    try {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: data,
        };
        const response = await fetch(BaseUrl + `/add/CallSetup`, options)
        .then(async (result) => {
            const response = await result.json();
            return response;
        })
        .catch((error) => console.log("error", error));
        return response;
    } catch (e) {
        console.log(e);
    }
};

// export const startTimerToGetCallDetails = (callSetupId, userId, userName, userType, token) => {
//     var interval_Id = setInterval(() => {
//             //console.log('callSetupId', callSetupId);
//             fetch(BaseUrl + "getcallDetails/" + callSetupId, {
//                 method: 'GET',
//             }).then(data => {
//                 return data.json();
//             }).catch(error => {
//                 console.log("error_calldetails", error)
//             }).then(response => {
//                 //console.log('response_calldetails', response);
//                 if (response.data !== "data not found") {
//                     if (response.data.status === 2) {
//                         // clearCurrentInterval()
//                         // clearCurrentIntervalCall()
//                         // CallStatusUpdateData(callSetupId, '3', '3')
//                         navigation.navigate("MeetingInitializer", {
//                             meetingConfig: {
//                                 call_setup_id: callSetupId,
//                                 userType,
//                                 userId,
//                                 userName,
//                                 meetingId: response.data.meetingId,
//                                 token,
//                                 videoOn,
//                                 micOn,
//                             },
//                         });
//                     }
//                 }
//             })
//         }, 2000);
//     replaceCurrentIntervalCAll(interval_Id)
// }

export const startTimerToGetCallDetails = async (callSetupId, userId, userName, userType, token) => {
    
    //console.log('callSetupId', callSetupId);
/*const response  = fetch(BaseUrl + "/getcallDetails/" + callSetupId, {
        method: 'GET',
    }).then(response => {
       return response;
        console.log('response_calldetails', response);
        if (response.data !== "data not found") {
            if (response.data.status === 2) {*/
                // clearCurrentInterval()
                // clearCurrentIntervalCall()
                // CallStatusUpdateData(callSetupId, '3', '3')
                /*navigation.navigate("MeetingInitializer", {
                    meetingConfig: {
                        call_setup_id: callSetupId,
                        userType,
                        userId,
                        userName,
                        meetingId: response.data.meetingId,
                        token,
                        videoOn,
                        micOn,
                    },
                });*/
            //}
       /* }
    }).catch(error => {
        console.log("error_calldetails", error)
    })
     return response;*/
/*replaceCurrentIntervalCAll(interval_Id)*/

try {
const response = await fetch(BaseUrl + `/getcallDetails/` + callSetupId,  {
    method: "GET",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
}).then(async (result) => {
    const res = await result.json();
    return res;
})
.catch((error) => console.log("error", error));;

return response;
} catch (e) {
console.log(e);
}
}