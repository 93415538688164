import React, { useEffect, useState } from "react";
import moment from "moment";
import ProfilePic from "../../Assets/home-user-profile.svg";
import HomeChange from "../../Assets/home-profile-change.svg";
import MyConProfile from "../../Assets/my-contact-profile.svg";
import MultipleUser from "../../Assets/multiple-user.svg";
import HomeCallHistory1 from "../../Assets/home-call-history-1.svg";
import HomeCallHistory2 from "../../Assets/home-call-history-1.svg";
import HomeCallHistory3 from "../../Assets/home-call-history-1.svg";
import style from "../../Assets/css/style.css";
import { ReactComponent as Icon_Call } from "../../Assets/home-call-icon.svg";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {
  MdLocationOn,
  MdPhone,
  MdStayCurrentPortrait,
  MdEmail,
  MdOutlineLanguage,
} from "react-icons/md";
import {
  postDataFromApi,
  getDataFromApi,
  putDataFromApi,
} from "../../Service/CommonService";
import { getprofile, getAuth } from "../../Service/Helper";
import Spinner_150 from "../../Assets/Spinner-1s-150px.svg";
import SearchIcon from "../../Assets/call-a-person-search-img.svg";
import Contact_img from "../../Assets/call-a-person-contact-img.svg";
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
import $ from "jquery";
import {
  sdkAPIKey,
  CallStatusUpdateData,
  getToken,
} from "../../Service/videoskp";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import IncomingCall from "../HomeComponent/IncomingCallPage";
import Button from "@material-ui/core/Button";
import Logo from "../../Assets/IncomingcallLogo.svg";
import iconVideo from "../../Assets/icon-video-call.svg";
import Contact_img2 from "../../Assets/call-a-person-contact-img2.svg";
import Popup from "reactjs-popup";
import { ReactComponent as Icon_closegrey } from "../../Assets/close-grey.svg";
import VideosdkApp from "../../VideoSdkComponents/VideosdkApp";
import VideosdkApp1 from "../../VideoSdkComponents/VideosdkApp1";
import generateMuiTheme from "../../VideoSdkComponents/mui/theme";
import { ThemeProvider } from "@material-ui/styles";

const Home_page = (props) => {
  const [is_loaded, setLoaded] = useState(false);
  const [is_grouploaded, setgroupLoaded] = useState(false);
  const [mycontacts, setmycontacts] = useState([]);
  const [mycallHistory, setmycallHistory] = useState([]);
  const [onBreak, setonBreak] = useState("");
  const authdata = getAuth();
  // const userid=8;
  const interpreterid = authdata["_id"];
  const userid = authdata["_id"];
  const userName = authdata["first_name"];
  // const InterpreterId=77
  const contactDetails = [];
  const [isvideocallstarted, setisvideocallstarted] = useState(false);
  const [IncomingCallPagestatus, setIncomingCallPagestatus] = useState(false);
  const [selectedCall, setselectedCall] = useState([]);
  const [getAllParticipants, setgetAllParticipants] = useState([]);
  const [Open, setOpen] = useState(true);
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [callHistoryQuery, setCallHistoryQuery] = useState("");
  const [configstate, setconfigstate] = useState({});

  const [newsetmeetingid, setNewsetmeetingid] = useState("");

  useEffect(() => {
    // getMissedCall();
    const intervalCall = setInterval(() => {
      console.log("data", intervalCall);
      getMyCall();
    }, 3000);
    return () => {
      clearInterval(intervalCall);
    };
  }, []);

  useEffect(() => {
    console.log("userName", userName);
    console.log("configstate", configstate);
    // console.log("authdata", authdata);
  });

  const closeModal = () => {
    setOpen(false);
  };
  const openModal = () => {
    setOpen(true);
  };
  const getMyCall = async () => {
    setLoaded(true);
    var query = "";
    const response = await getDataFromApi("checkCall/" + interpreterid, query);
    // console.log("checkCall details==>",response.data.data)
    if (response.data.code && response.data.data != null) {
      setmycallHistory(response.data.data);
      if (response.data.data.length != 0) {
        // console.log("checkCall details==>", response.data.data[0]._id);
        // localStorage.setItem("callSetupid", response.data.data[0]._id);
        sessionStorage.setItem("callSetupid", response.data.data[0]._id);
      }
    }
    if (response.data.code === 2) {
      setonBreak(response.data.message);
      console.log("on break", response.data.message);
    }
    setLoaded(false);
  };
  const getMissedCall = async () => {
    // setgroupLoaded(true);
    var query = "";
    const response = await getDataFromApi("missedCall/" + interpreterid, query);
    try{
      if (response.data.code && response.data.data != null) {
        setmycontacts(response.data.data);
      }

    }catch{

    }
   
    // setgroupLoaded(false);
  };

  const getparticipants = async (call_setup_id) => {
    const response = await getDataFromApi(
      "getcallAttendeeDetails/" + call_setup_id
    );
    if (response.data.code && response.data.data != null) {
      setgetAllParticipants(response.data.data);
      console.log("participants", response.data.data);
    }
  };

  useEffect(() => {
    // alert(localStorage.getItem("callSetupid"))
    if (localStorage.getItem("callSetupid")) {
      navigate("/InterpreterFeedbackform");
    }
    getMissedCall();
  }, []);

  const receiveCall = async (call, index) => {
    // console.log('mycontacts',call)
    // console.log(call)
    call = selectedCall;
    getparticipants(call._id);
    var inputData = JSON.stringify({
      call_setup_id: call._id,
      user_type: "1",
      signable_user_type: "1",
      user_id: userid,
      is_call_end: "3",
      call_end_type: "0",
      call_status: "2",
      call_attendee_status: "3",
      internet_bandwidth: "",
      interpreter_sequence: call.interpreter_sequence,
    });
    const response = await CallStatusUpdateData(inputData);
    console.log("CallStatusUpdate", response);
    console.log("forhelpline meetingId", response.data.meetingId);
    localStorage.setItem("meetingId_flag",response.data.meetingId)

    if(response.initiatorData.client_id==null){
      console.log("forhelpline client_id is null",response.initiatorData);
      localStorage.setItem("client_id_flag",response.initiatorData.client_id)
      localStorage.setItem("domain_id_flag",null)
      localStorage.setItem("domain_name_flag",null)
      
    }else{
      localStorage.setItem("client_id_flag",response.initiatorData.client_id._id)
      localStorage.setItem("domain_id_flag",response.domainData._id)
      localStorage.setItem("domain_name_flag",response.domainData.domain)
      console.log("forhelpline client_id else",response.domainData);
    }

    

    localStorage.setItem("callSetupid", call._id);
    setNewsetmeetingid(call.meetingId);
    console.log("setNewsetmeetingid------>", call.meetingId);

    if (response && response.code == 1) {
      const config = {
        name: authdata["first_name"], // add lastname
        meetingId: call.meetingId,
        apiKey: sdkAPIKey,
        // joinScreen: {
        // 	visible: false,

        // },
        containerId: "meeting-container-id",
        micEnabled: true,
        webcamEnabled: true,
        participantCanToggleSelfWebcam: true,
        participantCanToggleSelfMic: true,

        chatEnabled: true,
        screenShareEnabled: true,
        canChangeLayout: true,
        // redirectOnLeave: window.location.origin + '/InterpreterFeedbackform',
        redirectOnLeave: window.location.origin,
        joinScreen: {
          visible: false,
        },
      };
      // const meeting = new VideoSDKMeeting();
      // meeting.init(config);
      // $(".full-screen").insertAfter(".page-main");
      console.log("config------>", config);
      // navigate('/VideoSdkCalling',{config:config})
      setisvideocallstarted(true);
      setconfigstate(config);
    } else {
      swal({
        text: response.message,
        icon: "error",
      }).then(function () {
        navigate("/home");
      });
    }

    /*console.log(call)
		console.log(call.meetingId)
		*/
  };

  const selectcalldetails = (call, i) => {
    console.log("calldetails", call);
    setselectedCall(call);
    setIncomingCallPagestatus(true);
  };

  const callhistory_querydata = mycallHistory.filter((mycallHistory) =>
    mycallHistory.initiator_full_name
      .toLowerCase()
      .includes(callHistoryQuery.toLowerCase())
  );
  // console.log("missedCall----->",mycontacts)
  const querydata = mycontacts.filter((mycontacts) =>
    mycontacts.initiator_full_name.toLowerCase().includes(query.toLowerCase())
  );

  if (!isvideocallstarted) {
    // <IncomingCall setIncomingCallPagestatus={IncomingCallPagestatus} receiveCall={receiveCall} selectcalldetails={selectcalldetails}/>
    if (IncomingCallPagestatus) {
      return (
        <Grid
          item
          sm={8}
          className="my-contacts"
          style={{
            marginTop: "3%",
            marginLeft: "2%",
            backgroundColor: "#1D487A",
          }}
        >
          <div className="IncomingCallLogo">
            <img src={Logo} style={{ width: "65px", height: "65px" }} />
          </div>
          <div className="calltext">
            <span>Incoming video call</span>
          </div>
          <div className="home-user-profile-img">
            <img
              // src={Contact_img2}
              src="https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255710-stock-illustration-avatar-vector-male-profile-gray.jpg"
              className="incoming-call-profile-img"
            />
          </div>
          <div className="calltext">
            <span className="video-call-person-name">
              {authdata.first_name + " " + authdata.last_name}
            </span>
          </div>

          <div className="incoming-call-button">
            <Button
              variant="contained"
              className="video-button"
              onClick={() => receiveCall(selectcalldetails)}
            >
              {" "}
              <img src={iconVideo} />
              Join with video
            </Button>
            {/* <Button>decline</Button> */}
          </div>
          <div className="decline-button">
            <Button
              className="decline"
              onClick={() => setIncomingCallPagestatus(false)}
            >
              Decline
            </Button>
            {/* <Button>decline</Button> */}
          </div>
        </Grid>
      );
    } else {
      return (
        <div className="page-main">
          <div className="page-main-inner">
            <Box>
              {onBreak ? <span className="onbreak-text">{onBreak}</span> : ""}
              <Grid container spacing={3} className="main-grid home-grid">
                <Grid item md={5} className="my-contacts">
                  <div className="box-shadow radius-8px">
                    <div className="my-contacts-inner">
                      <div className="blue-title">Incoming Calls</div>
                      <div className="call-person-search-form">
                        <form>
                          <label>
                            <img src={SearchIcon} />
                            <input
                              type="text"
                              name="search-contacts"
                              className="search-contacts"
                              id="search-contacts"
                              placeholder="Search from Calls"
                              onChange={(e) => {
                                setCallHistoryQuery(e.target.value);
                              }}
                            />
                          </label>
                          <input type="submit" id="search-contacts-btn" />
                        </form>
                      </div>
                      {is_grouploaded ? (
                        <img className="loading_spinner" src={Spinner_150} />
                      ) : (
                        ""
                      )}
                      <div className="mycontacts-detail vertical_scrollbar">
                        {callhistory_querydata.length > 0 ? (
                          callhistory_querydata.map(function (call, i) {
                            return (
                              // ()=>receiveCall(call,i)
                              <div
                                className="single_contact"
                                onClick={() => selectcalldetails(call, i)}
                              >
                                {/* <p className="single_profinitial">ST</p> */}
                                <img
                                  // src={Contact_img}
                                  src="https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255710-stock-illustration-avatar-vector-male-profile-gray.jpg"
                                  className="group-person-img"
                                />
                                <span className="names">
                                  {call.initiator_full_name}{" "}
                                  {call.call_initiate_at}
                                </span>
                                {/* <span className="call_count">0<img src={MultipleUser} /></span> */}
                              </div>
                              // {call.first_name +''+call.last_name}
                            );
                          })
                        ) : (
                          <div className="single_contact">
                            <p className="nodata_found">No Incoming calls</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item md={7} className="call-history">
                  <div className="box-shadow radius-8px">
                    <div className="call-history-inner">
                      <div className="blue-title">Missed Call</div>
                      <div className="call-person-search-form">
                        <form>
                          <label>
                            <img src={SearchIcon} />
                            <input
                              type="text"
                              name="search-contacts"
                              className="search-contacts"
                              id="search-contacts"
                              placeholder="Search from Missed Call "
                              onChange={(e) => {
                                setQuery(e.target.value);
                              }}
                            />
                          </label>
                          <input type="submit" id="search-contacts-btn" />
                        </form>
                      </div>
                      <div className="call-history-detail vertical_scrollbar">
                        {/* {is_loaded ? <img className="loading_spinner" src={Spinner_150} /> : ''} */}

                        {querydata.length > 0 ? (
                          querydata.map(function (call, i) {
                            return (
                              <div className="single_callhistory">
                                <Grid item md={2}>
                                  <img
                                    // src={Contact_img}
                                    src="https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255710-stock-illustration-avatar-vector-male-profile-gray.jpg"
                                    className="group-person-missed-call"
                                  />
                                </Grid>
                                <Grid item md={8}>
                                  <span className="call_names_inter">
                                    <span className="missedcall-names">
                                      {call.initiator_full_name}
                                    </span>
                                    <span className="callhistory_inter">
                                      {moment(call.call_initiate_at).format(
                                        "DD MMMM YYYY"
                                      )}
                                    </span>
                                  </span>
                                </Grid>
                                <Grid item md={2}>
                                  <span className="call_date">
                                    {moment(call.call_initiate_at).format(
                                      "HH:mm:ss"
                                    )}
                                  </span>
                                </Grid>
                              </div>
                            );
                          })
                        ) : (
                          <div className="single_contact">
                            <p className="nodata_found">No Data Found</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      );
    }
  }
  // getcallAttendeeDetails/:call_setup_id
  else {
    return (
      <Box className="full-screen">
        <ThemeProvider theme={generateMuiTheme()}>
          {/* <VideosdkApp1 configstate={configstate} participantsprop={getAllParticipants} newsetmeetingid={newsetmeetingid}/> */}
          <VideosdkApp
            configstate={configstate}
            participantsprop={getAllParticipants}
            newsetmeetingid={newsetmeetingid}
            userName={userName}
          />
        </ThemeProvider>
        {/* <div id="meeting-container-id"><Popup className="processingcall-modal contact-detail-popup"
							modal
							open={Open}
							closeOnDocumentClick 
							onClose={closeModal}
						>
						<div className="modal interpreter ">
							 <button className="close" onClick={closeModal}>
							    <Icon_closegrey />
								
							</button>
							   { getAllParticipants.map(function (participants, i) {
								//    style={{backgroundColor:"#1D487A",width:"100px"}}
							   return (<div className="header" >
							      <Grid container className="participants-contact">
									  <Grid item md={2}>
									  <Icon_Call alt="call" style={{width:"20px",height:"20px",float:"left",marginTop:"15%"}}/>
									  </Grid>
									  <Grid item md={9}>
									  <span className="participants-name">{participants.user_docs.length > 0 ? participants.user_docs[0].first_name+' '+participants.user_docs[0].last_name : participants.hearing_person_name} </span> 
									  <span className="participants-mobile">{participants.user_docs.length > 0 ? participants.user_docs[0].mobile_number : participants.hearing_person_contact_number} </span>
									  </Grid>
								  </Grid>
								  
								   </div>)
							 })}
						</div>
						</Popup></div> */}
      </Box>
    );
  }
};

export default Home_page;

// position: relative;
//     /* margin: auto; */
//     pointer-events: auto;
//     width: 20%;
//     margin-left: auto;
//     margin-top: auto;
//     margin-right: 20px;
// }
