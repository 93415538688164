import React,{useEffect,useState} from "react";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography, TextField, Select, Radio, RadioGroup, FormControlLabel, Button, Modal,  } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TabsContents from './TabsContents';
import ProccessingCall from './ProccessingCall';
import ViewSelectedTabs from './ViewSelectedTabs';
import NotconnectedCall from './NotconnectedCall';
import {ReactComponent as TabImg_1} from "../../Assets/call-a-person-tab-1.svg";
import {ReactComponent as TabImg_2} from "../../Assets/call-a-person-tab-2.svg";
import {ReactComponent as TabImg_3} from "../../Assets/call-a-person-tab-3.svg";
import {ReactComponent as TabImg_4} from "../../Assets/call-a-person-tab-4.svg";
import Flag_Ind from "../../Assets/call-a-person-ind-flag.svg";
import Icon_user_plus from "../../Assets/Icon-user-plus.svg";
import {ReactComponent as Icon_multipleuser} from "../../Assets/vector-multiple-user.svg";
import {postDataFromApi,getDataFromApi,putDataFromApi} from '../../Service/CommonService';

const Conference_page = () => {
	const [tabvalue, setTabvalue] = useState(0);
	const [is_selected,setSelected]=useState(false)
	const [is_preccessed,setProccessed]=useState(false)
	const [is_notconnected,setNotconnected]=useState(false)
	const [languages,setlanguages]=useState([])
	const tabchange=(newValue)=>{
		setTabvalue(newValue);
	}
	const [selectedmember,setselectedmember]=useState({member:[],contactdetails:[]})
	useEffect(() => {
	    getlanguages();
	}, []);
	const getlanguages = async () => {
	    var query = "model=LanguageMasterData"
	    const response = await postDataFromApi('all/getAllMaster', query);
	    if(response.data.code && response.data.data!=null){
	    	setlanguages(response.data.data);
	    }

	}
	const doselectedmember=(contact,index)=>{
    	console.log('contact',contact)
    	
		let selected=selectedmember.member
		let singlecontactdetails=selectedmember.contactdetails
		if(selected.includes(contact._id)){
			const i = selected.indexOf(contact._id);
			if (i > -1) {
				selected.splice(i, 1);
				singlecontactdetails.splice(i,1)
			}
		}
		else{
			selected.push(contact._id)
			singlecontactdetails.push(contact)
		}
		console.log(selected)
		setselectedmember((formData) => ({
            ...formData,
			member:selected,
			contactdetails:singlecontactdetails
        }));

		console.log('selectedmember',selectedmember)

	}
	return (
		<div className="page-main" >
			<div className="page-main-inner conference">
			   <h2 style={{color:"gray"}}>Under Development</h2>
			</div>
		</div>
	)
}

export default Conference_page;