import React,{useEffect,useState,useRef} from "react";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
// import ListItemButton from '@mui/material/ListItemButton'
import SearchIcon from "../Assets/call-a-person-search-img.svg";
import Contact_img from "../Assets/call-a-person-contact-img.svg";
import Contact_img2 from "../Assets/call-a-person-contact-img2.svg";
import Contact_img3 from "../Assets/call-a-person-contact-img3.svg";
import {ReactComponent as Clock} from "../Assets/clock 1.svg";
import Spinner_150 from "../Assets/Spinner-1s-150px.svg";
import { ReactComponent as Icon_closegrey } from "../Assets/close-grey.svg";
import {postDataFromApi,getDataFromApi,putDataFromApi} from '../Service/CommonService';
import {getprofile,getAuth} from '../Service/Helper';
import moment from 'moment';
import {TextField,Box } from "@material-ui/core";

import Popup from 'reactjs-popup';
const InterpreterBreakPage = (props) => {
	const authdata=getAuth()
	const [is_Callloaded,setCallLoaded]=useState(false)
	const [onBreak ,setonBreak]=useState(false)
	const [open, setOpen] = useState(false);
	const interpreterid=authdata['_id'];
	const [mycontacts,setmycontacts]=useState([]);
	const [mybreak,setmybreak]=useState([])
	const [formdata, setfromdata] = useState({ interpreter_id:interpreterid, reason: "" })
	const [is_dataloaded,setdataloaded]=useState(false)
	const [IsBreaktime,setIsBreaktime]=useState(false)
	const [breakId,setbreakId]=useState('')
	const contactDetails=[];
	const [breakStartedTime, setBreakStartedTime] = useState('');
	// const interpreterid=77
	const breakReasons = ['Lunch', 'Restroom', 'Coffee', 'Meeting', 'Feeling unwell', 'Technical issue', 'Other']
	const time=''
	useEffect(() => {
		getBreakList()
	    // getMyContact();
		endbreak()
		// getAvailableInterpreter();
	}, []);
	var interval_ID=""
	const closeModal = () => {
		setOpen(false);
	}

	const openModal = () => {
		setOpen(true);
	}
	// const getMyContact = async () => {
	// 	setdataloaded(true);
	//     var query = "model=interpretorAvailibilityLogMaster&interpeter_id="+interpreterid
		
	//     const response = await postDataFromApi('all/getAllMaster', query);
	//     if(response.data.code && response.data.data!=null){
	//     	setmycontacts(response.data.data);
	//     }
	// 	setdataloaded(false);
		
	// } 
    
	const getBreakList = async () => {
		var inputData = "model=interpretorAvailibilityLogMaster&interpeter_id="+interpreterid
				const response = await postDataFromApi('all/getAllMaster', inputData);
				console.log('breackresponse',response)
				if(response.data.code   ){
					setmycontacts(response.data.data);
					console.log('list',response.data.data)
					if (response.data.data[0].type === 1) {
						// alert('hi')
						setIsBreaktime(true)
						setOpen(false)
						setbreakId(response.data.data[0]._id)
						startTimer(response.data)
					}
					else{
						setIsBreaktime(false)
						clearCurrentInterval()
						
					}
				}
		}
		
	
	const getAvailableInterpreter = async (newreason) => {
		// console.log('newreason',newreason)
	    // var query ="reason="+newreason+"&interpreter_id="+interpreterid
		var query={
			reason:newreason,
			interpeter_id:interpreterid
		}
		console.log('query',query)
		const response = await postDataFromApi('add/interpretorAvailibilityLog', query);
	    if(response.code){
	    	setmybreak(response.data.data);
			getBreakList()
	    }
       
	} 

	const ongrpchange = (item) => {
		const newreason=item
		console.log('newreason',item)
		setTimeout(function(){
			setfromdata((formData) => ({
				...formData,
				'reason': newreason,
			
			}));
			console.log('formdata',formdata)
			getAvailableInterpreter(newreason)
			// getMyContact()
			
		},1000)
		
	}


     


const endbreak= async () =>{
	var inputData ={
		interpeter_id:interpreterid,
		type:2,
		id:breakId
	}
	const response = await postDataFromApi('update/interpretorAvailibilityLogChangeStatus', inputData);
	console.log('breackresponse',response)
	if(response.code){
		
		clearCurrentInterval()
		setBreakStartedTime('')
		setbreakId('')
		getBreakList()
		
	}
}



const _interval = useRef(null);
const intervalFun = () => _interval.current;
const clearCurrentInterval = () => {
	clearInterval(intervalFun());
};
const replaceCurrentInterval = (newInterval) => {
	clearCurrentInterval();
	_interval.current = newInterval;
};

const startTimer = (response) => {
	interval_ID = setInterval(() => {
		const startDate = moment(response.data[0].created_at)
		const endDate = moment(new Date(new Date()))
		var diffDurationSeconds = endDate.diff(startDate, 'seconds')
		var diffDurationMinutes = new Date(diffDurationSeconds * 1000).toISOString().substr(11, 8)
		//console.log('diffDurationMinutes', diffDurationMinutes)
		var split_time = diffDurationMinutes.split(':')
		const time = split_time[0] + ' hr ' + split_time[1] + ' min ' + split_time[2] + ' sec'
		//console.log('time', time)
		setBreakStartedTime(time)
	}, 1000);

	replaceCurrentInterval(interval_ID);
}
	return (
        <Grid item md={5} className="my-contacts" style={{marginTop:"3%",marginLeft:"2%"}}>
        <div className="box-shadow radius-8px vertical_scrollbar">
          <div className="my-contacts-inner">
             
                
              { is_dataloaded ? <img className="loading_spinner" src={Spinner_150} />   : ''}
              {/* <div className="mycontacts-detail vertical_scrollbar"> */}
			  {
				(mycontacts.length>0) ?
				mycontacts.map(function(contact, i){
					// let name = getInitials(contact.item.reason)
					const created_date = moment(contact.created_at).format('DD MMMM YYYY')
					const breakStartedTime = moment(contact.created_at).format('HH:mm')
					const break_started_time = moment(contact.created_at)
					var breakEndedTime = ''
					var time = ''
					if (contact.type === 2) {
						breakEndedTime = moment(contact.updated_at).format('HH:mm')
						const break_ended_time = moment(contact.updated_at)
						var diffDurationSeconds = break_ended_time.diff(break_started_time, 'seconds')
						var diffDurationMinutes = new Date(diffDurationSeconds * 1000).toISOString().substr(11, 8)
						var split_time = diffDurationMinutes.split(':')
						time = split_time[0] + ' hr ' + split_time[1] + ' min ' + split_time[2] + ' sec'
						console.log('time', time)
					}
				return (
                
                    <Paper elevation={2} className="call-person-details " style={{margin:"2%"}}>
							<Grid className="call-person-img">
								<p className="single_profinitial-interpreter">AP</p>
							</Grid>
						
                            <Grid  className="call-person-inner-details">
								<span className="missedcall-names">{contact.reason}</span>
                               <div className="interpreter-time"> {moment(contact.created_at).format('DD MMMM YYYY')}</div>
                            </Grid>
							<Grid md={4} className="call-person-inner-details-new">
								<span className="missedcall-names-1"><Clock className='interpreter-clock'/>{breakStartedTime}-{breakEndedTime}</span>
                               <div className="interpreter-time-1">{time} </div>
                            </Grid>
                 </Paper>
				
			 );
			}) :	(<div className="single_contact">
			     <p className="nodata_found">No Data Found</p>
		   
		      </div>)
		}
       </div>
        </div>
		<Grid md={12} >
			{IsBreaktime ? (<Button className="primary" variant="text"  style={{ marginTop: "4%" }} onClick={() => endbreak()}
				>End Break </Button>) : (<Button className="primary" variant="text"  style={{ marginTop: "4%" }} onClick={() => openModal()}
				>Start Break </Button>)}
				
				 </Grid>
                   
                 <Grid md={12} >
				 <h3 className='text'>{breakStartedTime}</h3>
			
				 </Grid>

    <Popup className="processingcall-modal language"
     modal
     open={open}
     closeOnDocumentClick
     onClose={closeModal}
    >

<div className="modal interpreter">
	<button className="close" onClick={closeModal}>
		<Icon_closegrey />
	</button>
	
	<div className="content special-svg">
		{' '}
		{/* <div className="form-div"> */}
		
			{/* <TextField
				style={{ width: "80%" }}
				disableUnderline
				placeholder="Enter a Group Name"
				
				name="circle_title"
				// onChange={(e) => ongrpchange(e)}
			/> */}
			<Box  justifyContent="space-between">
				
			{breakReasons.map((item, key) => (
			<Button variant='outlined' className="break-reason"
			 onClick={(e)=> ongrpchange(item)} >
			  {item}
			</Button>
			 ))}
			</Box>
            

		{/* </div> */}
	</div>
	
</div>

</Popup>
   </Grid>
   
	)
}


export default InterpreterBreakPage;