import React from "react";

const Notification_page = () => {
	return (
		<div className="page-main">
			<div className="page-main-inner">
				<h2 style={{color:"gray"}}>Under Development</h2>
			</div>
		</div>
	)
}

export default Notification_page;