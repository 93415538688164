import React from "react";
import "../../Assets/css/style.css";
import { Grid } from "@material-ui/core";
import BasicTabs from "./BasicTabs"

function CheckAudioVideomain() {
  return (
    <>
      <div className="audiovideo-main box-shadow">
        <Grid container className="audiovideo-grid1">
          <Grid item md={12} className="audiovideo-grid11">
            <BasicTabs />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default CheckAudioVideomain;
