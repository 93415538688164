import React,{useEffect,useState} from "react";
import Home from './../HomeComponent/Home';
import { Typography, Paper, Grid, Box, TextField, Select,Radio, RadioGroup,FormControlLabel, Button, Modal,makeStyles, } from "@material-ui/core";
import {
	Routes,
	Route,
	NavLink,
	useNavigate ,
	HashRouter
} from "react-router-dom";
import Popup from 'reactjs-popup';
import Side_2 from "../../Assets/sidebar-2.svg";
import {ReactComponent as Icon_closegrey} from "../../Assets/close-grey.svg";
import {postDataFromApi,getDataFromApi,putDataFromApi} from '../../Service/CommonService';
import NotconnectedCall from './../ConferenceComponent/NotconnectedCall';
import ProccessingCall from './../ConferenceComponent/ProccessingCall';
const GetInterpreter_page = () => {
	const [languages,setlanguages]=useState([])
	const [is_preccessed,setProccessed]=useState(false)
	const [is_notconnected,setNotconnected]=useState(false)
	useEffect(() => {
	    getlanguages();
	}, []);
	const getlanguages = async () => {
	    var query = "model=LanguageMasterData"
	    const response = await postDataFromApi('all/getAllMaster', query);
	    if(response.data.code && response.data.data!=null){
	    	setlanguages(response.data.data);
	    }

	}

	const chosedlanguage=()=>{
		setProccessed(true)
	}
	const navigate = useNavigate();
	const [open, setOpen] = useState(true);
	const closeModal =()=>{
		navigate("/Home");
		setOpen(false);
	}
	return (
		<>
		{!is_notconnected ? 
			<>
			{!is_preccessed ?
			<>
			<Home/>
			<Popup className="processingcall-modal language"
				
				modal
				open={open}
				closeOnDocumentClick 
				onClose={closeModal}
				>
				
				<div className="modal interpreter">
				   <button className="close" onClick={closeModal}>
				      <Icon_closegrey />
				   </button>
				   <div className="header"> Select language </div>
				   <div className="content special-svg">
				      {' '}
				      <div className="form-div">
				         <label>Language</label>
				         <select
				            name="language"
				            className="language"
				            id="language">
				            <option value="default" selected disabled>Select language of the person</option>
				            {
				            languages.map(function(language,i){
				            return (
				            <option value={language._id}>{language.language}</option>
				            )
				            })
				            }
				         </select>
				      </div>
				   </div>
				   <div className="actions">
				      <Button className="primary" onClick={()=>chosedlanguage()}>Done</Button>
				   </div>
				</div>
				
				</Popup>
			</>
			:
				<div className="page-main" >
					<div className="page-main-inner conference"> 
						<ProccessingCall setNotconnected={setNotconnected} />
					</div>
				</div>

			}
			</>
			:
			<div className="page-main" >
				<div className="page-main-inner conference">
				 	<NotconnectedCall />
				</div>
			</div>
		}
		</>
	)
}

export default GetInterpreter_page;