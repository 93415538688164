import React, { useEffect, useMemo, useRef, useState } from "react";
import { makeStyles, styled, useTheme } from "@material-ui/core/styles";
import {
  IconButton,
  Box,
  MenuItem,
  TextField,
  Popover,
  Tooltip,
  InputLabel,
  MenuList,
  Typography,
  Link,
  SwipeableDrawer,
  Grid,
  Select,
  Radio,
  FormControl,
  FormGroup,
  NativeSelect,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";
import OutlineIconButton from "../components/OutlineIconButton";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AddIcon from "@mui/icons-material/Add";
import ShareIcon from '@mui/icons-material/Share';
import copy from "copy-to-clipboard";
import {
  Constants,
  createMicrophoneAudioTrack,
  useMeeting,
  usePubSub,
} from "@videosdk.live/react-sdk";
import {
  sideBarModes,
  appThemes,
  useMeetingAppContext,
  sideBarNestedModes,
} from "../MeetingAppContextDef";
import useIsTab from "../utils/useIsTab";
import useIsMobile from "../utils/useIsMobile";
import recordingBlink from "../animations/recording-blink.json";
import liveBlink from "../animations/live-blink.json";
import liveHLS from "../animations/live-hls.json";
import stoppingHLS from "../animations/hls_stop_blink.json";
import LiveIcon from "../icons/LiveIcon";
import RaiseHand from "../icons/RaiseHand";
// import  AddUser from "../../Assets/AddUserIcon.png"
import AddUser from "../../Assets/AddUserIcon.jpeg";
import { ReactComponent as Icon_whitevideocall } from "../../Assets/white-video-call.svg";
import {
  Activities,
  Chat,
  EndCall,
  Participants,
  ScreenRecording,
  ScreenShare,
  LeaveMeetingIcon,
  EndCallIcon,
  MicOn,
} from "../icons";
import {
  MoreHoriz as MoreHorizIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Gesture,
} from "@material-ui/icons";
import {
  isMobile as RDDIsMobile,
  isTablet as RDDIsTablet,
} from "react-device-detect";
import ConfirmBox from "../components/ConfirmBox";
import OutlineIconTextButton from "../components/OutlineIconTextButton";
import MobileIconButton from "../components/MobileIconButton";
import AddLiveStreamIcon from "../icons/AddLiveStreamIcon";
import useIsLivestreaming from "./useIsLivestreaming";
import useIsRecording from "./useIsRecording";
import useIsHls from "./useIsHls";
import { meetingModes } from "../CONSTS";
import WebCamOnIcon from "../icons/WebCamOnIcon";
import WebCamOffIcon from "../icons/WebCamOffIcon";
import ConfigIcon from "../icons/ConfigIcon";
import MicOffIcon from "../icons/MicOffIcon";
import MicrophoneIcon from "../icons/MicrophoneIcon";
import SpeakerMenuIcon from "../icons/SpeakerMenuIcon";
import SelectedIcon from "../icons/SelectedIcon";
import { useSnackbar } from "notistack";
import { VideoSDKNoiseSuppressor } from "@videosdk.live/videosdk-media-processor-web";
import Popup from "reactjs-popup";
import styles from "../Assets/style.css";
import { getprofile, getAuth } from "../../Service/Helper";
import {
  getToken,
  createMeeting,
  sdkAPIKey,
  callSetup,
  startTimerToGetCallDetails,
  CallSetupForSupport,
  custoclearinterval,
  BaseUrl,
  CallStatusUpdateData,
} from "../../Service/videoskp";
import style from "../../Assets/css/style.css";
import {
  mainURL,
  postDataFromApi,
  getDataFromApi,
  putDataFromApi,
  helplineUrl
} from "../../Service/CommonService";
import User from "../../Assets/user.jpeg";
import add from "../../Assets/plus.jpeg";
import swal from "sweetalert";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 12,
  padding: 0,
  margin: 0,
  width: 10,
  height: 10,
  border: `2px solid ${theme.palette.text.secondary}`,
  "input:disabled ~ &": {
    boxShadow: "none",
    background: theme.palette.text.secondary,
  },
}));

const BpCheckedIcon = styled(SelectedIcon)({
  "&:before": {
    display: "block",
    width: 10,
    height: 10,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
});

const BpCheckedLightIcon = styled(SelectedIcon)({
  "&:before": {
    display: "block",
    width: 10,
    height: 10,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
});

function BpCheckbox(CheckboxProps) {
  const { appTheme } = useMeetingAppContext();
  return (
    <Checkbox
      disableRipple
      disableFocusRipple
      color="default"
      checkedIcon={
        appTheme === appThemes.LIGHT || appTheme === appThemes.DARK ? (
          <BpCheckedLightIcon />
        ) : (
          <BpCheckedIcon />
        )
      }
      icon={<BpIcon />}
      style={{
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        backgroundColor: "transparent",
      }}
      {...CheckboxProps}
    />
  );
}

const useStyles = makeStyles({
  row: { display: "flex", alignItems: "center" },
  borderRight: { borderRight: "1ps solid #ffffff33" },
  popover: { backgroundColor: "transparent" },
  popoverBorder: {
    borderRadius: "12px",
    backgroundColor: "#212032",
    marginTop: 8,
    width: 300,
  },
  popoverHover: {
    "&:hover": {
      backgroundColor: "#CCD2D899",
    },
  },
  popoverHoverDark: {
    "&:hover": {
      backgroundColor: "#2B303499",
    },
  },
  popoverHoverDefault: {
    "&:hover": {
      backgroundColor: "#43425399",
    },
  },
  menuItemHover: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  menuItemDark: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  menuItemDefault: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  singleMenuItemGutters: {
    padding: "6px 2px",
  },
  singleMenuItemGuttersAfterSelect: {
    padding: "6px 12px",
  },
  menuItemGutters: {
    padding: "6px 28px",
  },
  menuItemGuttersAfterSelect: {
    padding: "6px 12px",
  },
});

const RaiseHandBTN = ({ onClick, isMobile, isTab }) => {
  const { publish } = usePubSub("RAISE_HAND");
  const classes = useStyles();
  const onRaiseHand = () => {
    if (isMobile || isTab) {
      onClick();
      typeof onClick === "function" && onClick();
      publish("Add");
    } else {
      publish("Raise Hand");
    }
  };

  return isMobile || isTab ? (
    <Tooltip>
      <MobileIconButton
        id="RaiseHandBTN"
        tooltipTitle={"Raise hand"}
        Icon={RaiseHand}
        onClick={onRaiseHand}
        buttonText={"Raise Hand"}
      />
    </Tooltip>
  ) : (
    <Tooltip>
      <OutlineIconButton
        tooltipTitle={"Raise hand"}
        Icon={RaiseHand}
        onClick={onRaiseHand}
      />
    </Tooltip>
  );
};
const AddBTN = ({ onClick, isMobile, isTab }) => {
  const { publish } = usePubSub("Add");
  const classes = useStyles();

  return isMobile || isTab ? (
    <Tooltip>
      <MobileIconButton
        id="Add"
        tooltipTitle={"Add"}
        // Icon={Add}
        // onClick={onRaiseHand}
        buttonText={"Add"}
      />
    </Tooltip>
  ) : (
    <Tooltip>
      <OutlineIconButton
        tooltipTitle={"Add"}
        // Icon={Add}
        // onClick={Add}
      />
    </Tooltip>
  );
};
const ParticipantsBTN = ({ onClick, isMobile, isTab }) => {
  const {
    sideBarMode,
    setSideBarMode,
    meetingMode,
    canToggleParticipantTab,
    appTheme,
  } = useMeetingAppContext();

  const mMeeting = useMeeting();
  const theme = useTheme();
  const participants = mMeeting?.participants;
  const participantsCount = participants ? new Map(participants).size : 0;

  return isMobile || isTab ? (
    <MobileIconButton
      tooltipTitle={"Participants"}
      isFocused={sideBarMode === sideBarModes.PARTICIPANTS}
      buttonText={"Participants"}
      disabledOpacity={1}
      Icon={Participants}
      disabled={meetingMode === meetingModes.VIEWER || !canToggleParticipantTab}
      onClick={() => {
        typeof onClick === "function" && onClick();
        setSideBarMode((s) =>
          s === sideBarModes.PARTICIPANTS ? null : sideBarModes.PARTICIPANTS
        );
      }}
      badge={participantsCount}
    />
  ) : (
    <OutlineIconButton
      tooltipTitle={"Participants"}
      isFocused={sideBarMode === sideBarModes.PARTICIPANTS}
      Icon={Participants}
      disabledOpacity={1}
      focusBGColor={
        appTheme === appThemes.LIGHT && theme.palette.lightTheme.contrastText
      }
      disabled={meetingMode === meetingModes.VIEWER || !canToggleParticipantTab}
      onClick={() => {
        typeof onClick === "function" && onClick();
        setSideBarMode((s) =>
          s === sideBarModes.PARTICIPANTS ? null : sideBarModes.PARTICIPANTS
        );
      }}
      badge={participantsCount}
    />
  );
};
const ConfigBTN = ({ isMobile, isTab }) => {
  const { sideBarMode, setSideBarMode, appTheme, setSideBarNestedMode } =
    useMeetingAppContext();
  const theme = useTheme();

  return isMobile || isTab ? (
    <MobileIconButton
      tooltipTitle={"Configuration"}
      buttonText={"Configuration"}
      Icon={ConfigIcon}
      isFocused={sideBarMode === sideBarModes.CONFIGURATION}
      onClick={() => {
        setSideBarNestedMode(null);
        setSideBarMode((s) =>
          s === sideBarModes.CONFIGURATION ? null : sideBarModes.CONFIGURATION
        );
      }}
    />
  ) : (
    <OutlineIconButton
      tooltipTitle={"Configuration"}
      Icon={ConfigIcon}
      focusBGColor={
        appTheme === appThemes.LIGHT && theme.palette.lightTheme.contrastText
      }
      isFocused={sideBarMode === sideBarModes.CONFIGURATION}
      onClick={() => {
        setSideBarNestedMode(null);
        setSideBarMode((s) =>
          s === sideBarModes.CONFIGURATION ? null : sideBarModes.CONFIGURATION
        );
      }}
    />
  );
};

const ChatBTN = ({ isMobile, isTab }) => {
  const { sideBarMode, setSideBarMode, appTheme, setSideBarNestedMode } =
    useMeetingAppContext();
  const theme = useTheme();

  return isMobile || isTab ? (
    <MobileIconButton
      tooltipTitle={"Chat"}
      buttonText={"Chat"}
      Icon={Chat}
      isFocused={sideBarMode === sideBarModes.CHAT}
      onClick={() => {
        setSideBarNestedMode(null);
        setSideBarMode((s) =>
          s === sideBarModes.CHAT ? null : sideBarModes.CHAT
        );
      }}
    />
  ) : (
    <OutlineIconButton
      tooltipTitle={"Chat"}
      Icon={Chat}
      focusBGColor={
        appTheme === appThemes.LIGHT && theme.palette.lightTheme.contrastText
      }
      isFocused={sideBarMode === sideBarModes.CHAT}
      onClick={() => {
        setSideBarNestedMode(null);
        setSideBarMode((s) =>
          s === sideBarModes.CHAT ? null : sideBarModes.CHAT
        );
      }}
    />
  );
};
// const ActivitiesBTN = ({ onClick, isMobile, isTab }) => {
//   const { sideBarMode, setSideBarMode, setSideBarNestedMode, appTheme } =
//     useMeetingAppContext();
//   const theme = useTheme();

//   return isMobile || isTab ? (
//     <MobileIconButton
//       Icon={Activities}
//       tooltipTitle={"More Options"}
//       buttonText={"More Options"}
//       isFocused={sideBarMode === sideBarModes.ACTIVITIES}
//       onClick={() => {
//         typeof onClick === "function" && onClick();

//         setSideBarMode((s) =>
//           s === sideBarModes.ACTIVITIES ? null : sideBarModes.ACTIVITIES
//         );

//         setSideBarNestedMode(null);
//       }}
//     />
//   ) : (
//     <OutlineIconButton
//       tooltipTitle={"More Options"}
//       Icon={Activities}
//       focusBGColor={
//         appTheme === appThemes.LIGHT && theme.palette.lightTheme.contrastText
//       }
//       isFocused={sideBarMode === sideBarModes.ACTIVITIES}
//       onClick={() => {
//         typeof onClick === "function" && onClick();

//         setSideBarMode((s) =>
//           s === sideBarModes.ACTIVITIES ? null : sideBarModes.ACTIVITIES
//         );
//         setSideBarNestedMode(null);
//       }}
//     />
//   );
// };
const WhiteBoardBTN = ({ onClick, isMobile, isTab }) => {
  const {
    whiteboardStarted,
    whiteboardEnabled,
    canToggleWhiteboard,
    appTheme,
  } = useMeetingAppContext();
  const theme = useTheme();

  const mMeeting = useMeeting({});

  const presenterId = mMeeting?.presenterId;

  return (
    <>
      {whiteboardEnabled &&
        (isMobile || isTab ? (
          <MobileIconButton
            disabled={presenterId || !canToggleWhiteboard}
            tooltipTitle={"Whiteboard"}
            buttonText={"Whiteboard"}
            Icon={Gesture}
            isFocused={whiteboardStarted}
            focusIconColor={
              appTheme === appThemes.LIGHT &&
              theme.palette.lightTheme.contrastText
            }
            onClick={() => {
              typeof onClick === "function" && onClick();

              whiteboardStarted
                ? mMeeting.meeting.stopWhiteboard()
                : mMeeting.meeting.startWhiteboard();
            }}
          />
        ) : (
          <OutlineIconButton
            disabled={presenterId || !canToggleWhiteboard}
            tooltipTitle={"Whiteboard"}
            Icon={Gesture}
            isFocused={whiteboardStarted}
            focusBGColor={
              appTheme === appThemes.LIGHT &&
              theme.palette.lightTheme.contrastText
            }
            onClick={() => {
              typeof onClick === "function" && onClick();

              whiteboardStarted
                ? mMeeting.meeting.stopWhiteboard()
                : mMeeting.meeting.startWhiteboard();
            }}
          />
        ))}
    </>
  );
};
const ScreenShareBTN = ({ onClick, isMobile, isTab }) => {
  const mMeeting = useMeeting({});
  const { whiteboardStarted, appTheme } = useMeetingAppContext();
  const theme = useTheme();

  const localScreenShareOn = mMeeting?.localScreenShareOn;
  const toggleScreenShare = mMeeting?.toggleScreenShare;
  const presenterId = mMeeting?.presenterId;

  return isMobile || isTab ? (
    <MobileIconButton
      tooltipTitle={
        presenterId
          ? localScreenShareOn
            ? "Stop Presenting"
            : null
          : "Present Screen"
      }
      buttonText={
        presenterId
          ? localScreenShareOn
            ? "Stop Presenting"
            : null
          : "Present Screen"
      }
      isFocused={localScreenShareOn}
      Icon={ScreenShare}
      onClick={() => {
        typeof onClick === "function" && onClick();
        toggleScreenShare();
      }}
      disabled={
        RDDIsMobile || RDDIsTablet
          ? true
          : whiteboardStarted
          ? true
          : presenterId
          ? localScreenShareOn
            ? false
            : true
          : false
      }
    />
  ) : (
    <OutlineIconButton
      tooltipTitle={
        presenterId
          ? localScreenShareOn
            ? "Stop Presenting"
            : null
          : "Present Screen"
      }
      isFocused={localScreenShareOn}
      focusBGColor={
        appTheme === appThemes.LIGHT && theme.palette.lightTheme.contrastText
      }
      Icon={ScreenShare}
      onClick={() => {
        typeof onClick === "function" && onClick();
        toggleScreenShare();
      }}
      disabled={
        RDDIsMobile || RDDIsTablet
          ? true
          : whiteboardStarted
          ? true
          : presenterId
          ? localScreenShareOn
            ? false
            : true
          : false
      }
    />
  );
};
const AddLiveStreamBTN = ({ isMobile, isTab }) => {
  const { sideBarMode, setSideBarMode } = useMeetingAppContext();

  return isMobile || isTab ? (
    <MobileIconButton
      tooltipTitle={"Add Live Streams"}
      Icon={AddLiveStreamIcon}
      buttonText={"Add Live Streams"}
      isFocused={sideBarMode === sideBarModes.ADD_LIVE_STREAM}
      onClick={() => {
        setSideBarMode((s) =>
          s === sideBarModes.ADD_LIVE_STREAM
            ? null
            : sideBarModes.ADD_LIVE_STREAM
        );
      }}
    />
  ) : (
    <OutlineIconTextButton
      tooltipTitle={"Add Live Streams"}
      buttonText="Add Live Streams"
      isFocused={sideBarMode === sideBarModes.ADD_LIVE_STREAM}
      onClick={() => {
        setSideBarMode((s) =>
          s === sideBarModes.ADD_LIVE_STREAM
            ? null
            : sideBarModes.ADD_LIVE_STREAM
        );
      }}
    />
  );
};
const RecordingBTN = ({ isMobile, isTab }) => {
  const mMeeting = useMeeting({});
  const theme = useTheme();

  const startRecording = mMeeting?.startRecording;
  const stopRecording = mMeeting?.stopRecording;
  const recordingState = mMeeting?.recordingState;

  const isRecording = useIsRecording();

  const { isRequestProcessing } = useMemo(
    () => ({
      isRequestProcessing:
        recordingState === Constants.recordingEvents.RECORDING_STARTING ||
        recordingState === Constants.recordingEvents.RECORDING_STOPPING,
    }),
    [recordingState]
  );

  const {
    recordingWebhookUrl,
    recordingAWSDirPath,
    participantCanToggleRecording,
    appMeetingLayout,
    recordingTheme,
    appTheme,
  } = useMeetingAppContext();

  const { type, priority, gridSize } = useMemo(
    () => ({
      type: appMeetingLayout.type,
      priority: appMeetingLayout.priority,
      gridSize: appMeetingLayout.gridSize,
    }),
    [appMeetingLayout]
  );

  const typeRef = useRef(type);
  const priorityRef = useRef(priority);
  const gridSizeRef = useRef(gridSize);
  const isRecordingRef = useRef(isRecording);

  useEffect(() => {
    typeRef.current = type;
  }, [type]);

  useEffect(() => {
    priorityRef.current = priority;
  }, [priority]);

  useEffect(() => {
    gridSizeRef.current = gridSize;
  }, [gridSize]);

  useEffect(() => {
    isRecordingRef.current = isRecording;
  }, [isRecording]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: recordingBlink,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    height: 64,
    width: 160,
  };

  const _handleStartRecording = () => {
    const type = typeRef.current;
    const priority = priorityRef.current;
    const gridSize = gridSizeRef.current;

    const layout = { type, priority, gridSize };

    startRecording(recordingWebhookUrl, recordingAWSDirPath, {
      layout,
      theme: recordingTheme,
    });
  };

  const _handleClick = () => {
    const isRecording = isRecordingRef.current;

    if (isRecording) {
      stopRecording();
    } else {
      _handleStartRecording();
    }
  };

  return isMobile || isTab ? (
    <MobileIconButton
      Icon={ScreenRecording}
      onClick={_handleClick}
      tooltipTitle={
        recordingState === Constants.recordingEvents.RECORDING_STARTED
          ? "Stop Recording"
          : recordingState === Constants.recordingEvents.RECORDING_STARTING
          ? "Starting Recording"
          : recordingState === Constants.recordingEvents.RECORDING_STOPPED
          ? "Start Recording"
          : recordingState === Constants.recordingEvents.RECORDING_STOPPING
          ? "Stopping Recording"
          : "Start Recording"
      }
      isFocused={isRecording}
      disabled={!participantCanToggleRecording}
      lottieOption={isRecording ? defaultOptions : null}
      bgColor={
        appTheme === appThemes.LIGHT &&
        (recordingState === Constants.recordingEvents.RECORDING_STARTED ||
          recordingState === Constants.recordingEvents.RECORDING_STOPPING) &&
        "#EEF0F2"
      }
      buttonText={
        recordingState === Constants.recordingEvents.RECORDING_STARTED
          ? "Stop Recording"
          : recordingState === Constants.recordingEvents.RECORDING_STARTING
          ? "Starting Recording"
          : recordingState === Constants.recordingEvents.RECORDING_STOPPED
          ? "Start Recording"
          : recordingState === Constants.recordingEvents.RECORDING_STOPPING
          ? "Stopping Recording"
          : "Start Recording"
      }
      isRequestProcessing={isRequestProcessing}
    />
  ) : (
    <OutlineIconButton
      Icon={ScreenRecording}
      onClick={_handleClick}
      focusBGColor={
        appTheme === appThemes.LIGHT && theme.palette.lightTheme.contrastText
      }
      tooltipTitle={
        recordingState === Constants.recordingEvents.RECORDING_STARTED
          ? "Stop Recording"
          : recordingState === Constants.recordingEvents.RECORDING_STARTING
          ? "Starting Recording"
          : recordingState === Constants.recordingEvents.RECORDING_STOPPED
          ? "Start Recording"
          : recordingState === Constants.recordingEvents.RECORDING_STOPPING
          ? "Stopping Recording"
          : "Start Recording"
      }
      isFocused={isRecording}
      bgColor={
        appTheme === appThemes.LIGHT &&
        (recordingState === Constants.recordingEvents.RECORDING_STARTED ||
          recordingState === Constants.recordingEvents.RECORDING_STOPPING) &&
        "#EEF0F2"
      }
      disabled={!participantCanToggleRecording}
      lottieOption={isRecording ? defaultOptions : null}
      isRequestProcessing={isRequestProcessing}
    />
  );
};
const GoLiveBTN = ({ isMobile, isTab }) => {
  const mMeeting = useMeeting({});

  const [isPopupShown, setIsPopupShown] = useState(false);
  const [open, setopen] = useState(false);
  const startLivestream = mMeeting?.startLivestream;
  const stopLivestream = mMeeting?.stopLivestream;
  const livestreamState = mMeeting?.livestreamState;

  const isLiveStreaming = useIsLivestreaming();

  const { isRequestProcessing } = useMemo(
    () => ({
      isRequestProcessing:
        livestreamState === Constants.livestreamEvents.LIVESTREAM_STARTING ||
        livestreamState === Constants.livestreamEvents.LIVESTREAM_STOPPING,
    }),
    [livestreamState]
  );

  const {
    participantCanToggleLivestream,
    liveStreamConfig,
    setSideBarMode,
    setSideBarNestedMode,
    appMeetingLayout,
    liveStreamTheme,
  } = useMeetingAppContext();

  const { type, priority, gridSize } = useMemo(
    () => ({
      type: appMeetingLayout.type,
      priority: appMeetingLayout.priority,
      gridSize: appMeetingLayout.gridSize,
    }),
    [appMeetingLayout]
  );

  const typeRef = useRef(type);
  const priorityRef = useRef(priority);
  const gridSizeRef = useRef(gridSize);
  const isLiveStreamingRef = useRef(isLiveStreaming);
  const liveStreamConfigRef = useRef(liveStreamConfig);

  useEffect(() => {
    typeRef.current = type;
  }, [type]);

  useEffect(() => {
    priorityRef.current = priority;
  }, [priority]);

  useEffect(() => {
    gridSizeRef.current = gridSize;
  }, [gridSize]);

  useEffect(() => {
    isLiveStreamingRef.current = isLiveStreaming;
  }, [isLiveStreaming]);

  useEffect(() => {
    liveStreamConfigRef.current = liveStreamConfig;
  }, [liveStreamConfig]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: liveBlink,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    height: 64,
    width: 170,
  };

  const _handleStartLivestream = () => {
    const type = typeRef.current;
    const priority = priorityRef.current;
    const gridSize = gridSizeRef.current;

    const layout = { type, priority, gridSize };

    startLivestream(liveStreamConfig, { layout, theme: liveStreamTheme });
  };

  const _handleClick = () => {
    const isLiveStreaming = isLiveStreamingRef.current;

    if (isLiveStreaming) {
      stopLivestream();
    } else {
      if (liveStreamConfigRef.current.length > 0) {
        _handleStartLivestream();
      } else {
        setIsPopupShown(true);
      }
    }
  };

  return (
    <>
      {isMobile || isTab ? (
        <MobileIconButton
          bgColor={"#D32F2F"}
          onClick={_handleClick}
          tooltipTitle={
            livestreamState === Constants.livestreamEvents.LIVESTREAM_STARTED
              ? "Stop Live"
              : livestreamState ===
                Constants.livestreamEvents.LIVESTREAM_STARTING
              ? "Starting Livestream"
              : livestreamState ===
                Constants.livestreamEvents.LIVESTREAM_STOPPED
              ? "Go Live"
              : livestreamState ===
                Constants.livestreamEvents.LIVESTREAM_STOPPING
              ? "Stopping Livestream"
              : "Go Live"
          }
          Icon={LiveIcon}
          buttonText={
            livestreamState === Constants.livestreamEvents.LIVESTREAM_STARTED
              ? "Stop Live"
              : livestreamState ===
                Constants.livestreamEvents.LIVESTREAM_STARTING
              ? "Starting Livestream"
              : livestreamState ===
                Constants.livestreamEvents.LIVESTREAM_STOPPED
              ? "Go Live"
              : livestreamState ===
                Constants.livestreamEvents.LIVESTREAM_STOPPING
              ? "Stopping Livestream"
              : "Go Live"
          }
          isFocused={isLiveStreaming}
          lottieOption={isLiveStreaming ? defaultOptions : null}
          disabled={!participantCanToggleLivestream}
          isRequestProcessing={isRequestProcessing}
        />
      ) : (
        <OutlineIconTextButton
          bgColor={"#D32F2F"}
          onClick={_handleClick}
          tooltipTitle={
            livestreamState === Constants.livestreamEvents.LIVESTREAM_STARTED
              ? "Stop Live"
              : livestreamState ===
                Constants.livestreamEvents.LIVESTREAM_STARTING
              ? "Starting Livestream"
              : livestreamState ===
                Constants.livestreamEvents.LIVESTREAM_STOPPED
              ? "Go Live"
              : livestreamState ===
                Constants.livestreamEvents.LIVESTREAM_STOPPING
              ? "Stopping Livestream"
              : "Go Live"
          }
          buttonText="Go Live"
          textColor="#fff"
          lottieOption={isLiveStreaming ? defaultOptions : null}
          disabled={!participantCanToggleLivestream}
          isRequestProcessing={isRequestProcessing}
        />
      )}
      <ConfirmBox
        open={isPopupShown}
        title={"Add live stream configuration"}
        subTitle={
          "Please add live stream configuration to start live streaming"
        }
        successText={"Proceed"}
        onSuccess={() => {
          setSideBarMode((s) => sideBarModes.ACTIVITIES);
          setSideBarNestedMode((s) => sideBarNestedModes.ADD_LIVE_STREAM);
          setIsPopupShown(false);
        }}
        rejectText={"Cancel"}
        onReject={() => {
          setIsPopupShown(false);
        }}
      />
    </>
  );
};
const HlsBTN = ({ isMobile, isTab }) => {
  const mMeeting = useMeeting({});
  const theme = useTheme();

  const startHls = mMeeting?.startHls;
  const stopHls = mMeeting?.stopHls;
  const hlsState = mMeeting?.hlsState;

  const isHls = useIsHls();

  const { isRequestProcessing } = useMemo(
    () => ({
      isRequestProcessing:
        hlsState === Constants.hlsEvents.HLS_STARTING ||
        hlsState === Constants.hlsEvents.HLS_STOPPING,
    }),
    [hlsState]
  );

  const { appMeetingLayout, participantCanToggleHls, hlsTheme, appTheme } =
    useMeetingAppContext();

  const { type, priority, gridSize } = useMemo(
    () => ({
      type: appMeetingLayout.type,
      priority: appMeetingLayout.priority,
      gridSize: appMeetingLayout.gridSize,
    }),
    [appMeetingLayout]
  );

  const typeRef = useRef(type);
  const priorityRef = useRef(priority);
  const gridSizeRef = useRef(gridSize);
  const isHlsRef = useRef(isHls);

  useEffect(() => {
    typeRef.current = type;
  }, [type]);

  useEffect(() => {
    priorityRef.current = priority;
  }, [priority]);

  useEffect(() => {
    gridSizeRef.current = gridSize;
  }, [gridSize]);

  useEffect(() => {
    isHlsRef.current = isHls;
  }, [isHls]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: liveHLS,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    height: 64,
    width: 170,
  };

  const defaultOptionsStoppingHls = {
    loop: true,
    autoplay: true,
    animationData: stoppingHLS,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    height: 64,
    width: 170,
  };

  const _handleStartHLS = () => {
    const type = typeRef.current;
    const priority = priorityRef.current;
    const gridSize = gridSizeRef.current;

    const layout = { type, priority, gridSize };

    startHls({ layout, theme: hlsTheme });
  };

  const _handleClick = () => {
    const isHls = isHlsRef.current;

    if (isHls) {
      stopHls();
    } else {
      _handleStartHLS();
    }
  };

  return isMobile || isTab ? (
    <MobileIconButton
      onClick={_handleClick}
      focusBGColor={
        appTheme === appThemes.LIGHT && theme.palette.lightTheme.contrastText
      }
      tooltipTitle={
        hlsState === Constants.hlsEvents.HLS_STARTED
          ? "Stop HLS"
          : hlsState === Constants.hlsEvents.HLS_STARTING
          ? "Starting HLS"
          : hlsState === Constants.hlsEvents.HLS_STOPPED
          ? "Start HLS"
          : hlsState === Constants.hlsEvents.HLS_STOPPING
          ? "Stopping HLS"
          : "Start HLS"
      }
      Icon={LiveIcon}
      buttonText={
        hlsState === Constants.hlsEvents.HLS_STARTED
          ? "Stop HLS"
          : hlsState === Constants.hlsEvents.HLS_STARTING
          ? "Starting HLS"
          : hlsState === Constants.hlsEvents.HLS_STOPPED
          ? "Start HLS"
          : hlsState === Constants.hlsEvents.HLS_STOPPING
          ? "Stopping HLS"
          : "Start HLS"
      }
      bgColor={
        appTheme === appThemes.LIGHT &&
        (hlsState === Constants.hlsEvents.HLS_STARTED ||
          hlsState === Constants.hlsEvents.HLS_STOPPING) &&
        "#EEF0F2"
      }
      isFocused={isHls}
      disabled={!participantCanToggleHls}
      lottieOption={isHls ? defaultOptions : null}
      isRequestProcessing={isRequestProcessing}
    />
  ) : (
    <OutlineIconTextButton
      onClick={_handleClick}
      tooltipTitle={
        hlsState === Constants.hlsEvents.HLS_STARTED
          ? "Stop HLS"
          : hlsState === Constants.hlsEvents.HLS_STARTING
          ? "Starting HLS"
          : hlsState === Constants.hlsEvents.HLS_STOPPED
          ? "Start HLS"
          : hlsState === Constants.hlsEvents.HLS_STOPPING
          ? "Stopping HLS"
          : "Start HLS"
      }
      buttonText={
        hlsState === Constants.hlsEvents.HLS_STARTED
          ? "Stop HLS"
          : hlsState === Constants.hlsEvents.HLS_STARTING
          ? "Starting HLS"
          : hlsState === Constants.hlsEvents.HLS_STOPPED
          ? "Start HLS"
          : hlsState === Constants.hlsEvents.HLS_STOPPING
          ? "Stopping HLS"
          : "Start HLS"
      }
      lottieOption={isHls ? defaultOptions : null}
      disabled={!participantCanToggleHls}
      isRequestProcessing={isRequestProcessing}
    />
  );
};

const SingleMicMenu = ({
  micArr,
  Icon,
  label,
  classes,
  selectMicDeviceId,
  setSelectMicDeviceId,
  changeMic,
  appTheme,
  theme,
  handleClose,
}) => {
  return (
    <Box>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          padding: 12,
          paddingBottom: 0,
        }}
      >
        <Icon />

        <Typography
          style={{
            marginLeft: 12,
            fontSize: 14,
            color: theme.palette.darkTheme.contrastText,
          }}
        >
          {label}
        </Typography>
      </Box>
      <MenuList
        disableRipple
        disableFocusRipple
        style={{
          backgroundColor:
            appTheme === appThemes.DARK
              ? theme.palette.darkTheme.slightLighter
              : appTheme === appThemes.LIGHT
              ? theme.palette.lightTheme.two
              : "",
          color:
            appTheme === appThemes.DARK
              ? theme.palette.common.white
              : appTheme === appThemes.LIGHT
              ? theme.palette.lightTheme.contrastText
              : "",
        }}
      >
        {micArr.map(({ deviceId, label }, index) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: 12,
              paddingRight: 12,
              backgroundColor:
                deviceId === selectMicDeviceId
                  ? appTheme === appThemes.DARK
                    ? "#3F4046"
                    : appTheme === appThemes.LIGHT
                    ? theme.palette.lightTheme.three
                    : "#6D6E71"
                  : "",
            }}
            classes={{
              root:
                appTheme === appThemes.LIGHT
                  ? classes.popoverHover
                  : appTheme === appThemes.DARK
                  ? classes.popoverHoverDark
                  : classes.popoverHoverDefault,
            }}
          >
            {deviceId === selectMicDeviceId && <SelectedIcon />}

            <MenuItem
              disableRipple
              style={{
                display: "flex",
                flex: 1,
                backgroundColor:
                  deviceId === selectMicDeviceId
                    ? appTheme === appThemes.DARK
                      ? "#3F4046"
                      : appTheme === appThemes.LIGHT
                      ? theme.palette.lightTheme.three
                      : "#6D6E71"
                    : "",
              }}
              key={`mics_${deviceId}`}
              selected={deviceId === selectMicDeviceId}
              onClick={() => {
                handleClose();
                setSelectMicDeviceId(deviceId);
                changeMic(deviceId);
              }}
              classes={{
                root:
                  appTheme === appThemes.LIGHT
                    ? classes.menuItemHover
                    : appTheme === appThemes.DARK
                    ? classes.menuItemDark
                    : classes.menuItemDefault,
                gutters:
                  deviceId === selectMicDeviceId
                    ? classes.menuItemGuttersAfterSelect
                    : classes.menuItemGutters,
              }}
            >
              {label || `Mic ${index + 1}`}
            </MenuItem>
          </Box>
        ))}
      </MenuList>
    </Box>
  );
};

const MicMenu = ({
  selectMicDeviceId,
  setSelectMicDeviceId,
  localMicOn,
  downArrow,
  mics,
  outputmics,
  classes,
  _handleNoiseClick,
  handleClose,
  isNoiseRemovalChecked,
  theme,
  appTheme,
  tollTipEl,
  changeMic,
}) => {
  return (
    <Popover
      container={tollTipEl.current}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={tollTipEl.current}
      open={Boolean(downArrow)}
      onClose={handleClose}
    >
      <Box
        style={{
          backgroundColor:
            appTheme === appThemes.DARK
              ? theme.palette.darkTheme.slightLighter
              : appTheme === appThemes.LIGHT
              ? theme.palette.lightTheme.two
              : "",
        }}
      >
        <SingleMicMenu
          micArr={mics}
          label={"MICROPHONE"}
          Icon={MicrophoneIcon}
          selectMicDeviceId={selectMicDeviceId}
          setSelectMicDeviceId={setSelectMicDeviceId}
          changeMic={changeMic}
          classes={classes}
          appTheme={appTheme}
          theme={theme}
          handleClose={handleClose}
        />
        {/* <Box
          style={{
            height: 1,
            width: "100%",
            borderTop: `1px solid ${theme.palette.darkTheme.contrastText}`,
          }}
        ></Box>

        <SingleMicMenu
          micArr={outputmics}
          label={"SPEAKER"}
          Icon={SpeakerMenuIcon}
          selectMicDeviceId={selectMicDeviceId}
          setSelectMicDeviceId={setSelectMicDeviceId}
          classes={classes}
          changeMic={changeMic}
          appTheme={appTheme}
          theme={theme}
          handleClose={handleClose}
        /> */}

        {localMicOn && (
          <>
            <Box
              style={{
                height: 1,
                width: "100%",
                borderTop: "1px solid #9FA0A7",
              }}
            ></Box>
            <Box>
              <MenuList
                disableRipple
                disableFocusRipple
                style={{
                  backgroundColor:
                    appTheme === appThemes.DARK
                      ? theme.palette.darkTheme.slightLighter
                      : appTheme === appThemes.LIGHT
                      ? theme.palette.lightTheme.two
                      : "",
                  color:
                    appTheme === appThemes.DARK
                      ? theme.palette.common.white
                      : appTheme === appThemes.LIGHT
                      ? theme.palette.lightTheme.contrastText
                      : "",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: isNoiseRemovalChecked ? 12 : 6,
                    paddingRight: 6,
                    backgroundColor: isNoiseRemovalChecked
                      ? appTheme === appThemes.DARK
                        ? "#3F4046"
                        : appTheme === appThemes.LIGHT
                        ? theme.palette.lightTheme.three
                        : "#6D6E71"
                      : "",
                  }}
                  classes={{
                    root:
                      appTheme === appThemes.LIGHT
                        ? classes.popoverHover
                        : appTheme === appThemes.DARK
                        ? classes.popoverHoverDark
                        : classes.popoverHoverDefault,
                  }}
                >
                  {isNoiseRemovalChecked ? (
                    <SelectedIcon />
                  ) : (
                    <BpCheckbox
                      value={isNoiseRemovalChecked}
                      checked={isNoiseRemovalChecked}
                      onClick={(e) => {
                        _handleNoiseClick({ e, selectMicDeviceId });
                      }}
                    />
                  )}

                  <MenuItem
                    disableRipple
                    style={{
                      display: "flex",
                      flex: 1,
                      backgroundColor: isNoiseRemovalChecked
                        ? appTheme === appThemes.DARK
                          ? "#3F4046"
                          : appTheme === appThemes.LIGHT
                          ? theme.palette.lightTheme.three
                          : "#6D6E71"
                        : "",
                    }}
                    key={`noise_removal`}
                    selected={isNoiseRemovalChecked}
                    onClick={(e) => {
                      handleClose();
                      _handleNoiseClick({ e, selectMicDeviceId });
                    }}
                    classes={{
                      root:
                        appTheme === appThemes.LIGHT
                          ? classes.menuItemHover
                          : appTheme === appThemes.DARK
                          ? classes.menuItemDark
                          : classes.menuItemDefault,
                      gutters: isNoiseRemovalChecked
                        ? classes.singleMenuItemGuttersAfterSelect
                        : classes.singleMenuItemGutters,
                    }}
                  >
                    AI Noise Removal
                  </MenuItem>
                </Box>
              </MenuList>
            </Box>
          </>
        )}
      </Box>
    </Popover>
  );
};

const WebcamBTN = () => {
  const theme = useTheme();
  const classes = useStyles();
  const mMeeting = useMeeting({});
  const { appTheme, selectWebcamDeviceId, setSelectWebcamDeviceId } =
    useMeetingAppContext();

  const [downArrow, setDownArrow] = useState(null);
  const [webcams, setWebcams] = useState([]);

  const localWebcamOn = mMeeting?.localWebcamOn;
  const toggleWebcam = mMeeting?.toggleWebcam;
  const changeWebcam = mMeeting?.changeWebcam;

  const handleClick = (event) => {
    setDownArrow(event.currentTarget);
  };

  const handleClose = () => {
    setDownArrow(null);
  };

  const getWebcams = async (mGetWebcams) => {
    const webcams = await mGetWebcams();

    webcams && webcams?.length && setWebcams(webcams);
  };

  const tollTipEl = useRef();

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      ref={tollTipEl}
    >
      <OutlineIconButton
        btnID={"btnWebcam"}
        tooltipTitle={localWebcamOn ? "Turn off webcam" : "Turn on webcam"}
        isFocused={localWebcamOn}
        Icon={localWebcamOn ? WebCamOnIcon : WebCamOffIcon}
        onClick={() => {
          toggleWebcam();
        }}
        focusBGColor={
          appTheme === appThemes.LIGHT
            ? theme.palette.lightTheme.contrastText
            : "#ffffff33"
        }
        focusIconColor={theme.palette.common.white}
        renderRightComponent={() => {
          return (
            <Tooltip placement="bottom" title={"Change webcam"}>
              <IconButton
                onClick={(e) => {
                  getWebcams(mMeeting?.getWebcams);
                  handleClick(e);
                }}
                size={"small"}
              >
                <ArrowDropDownIcon
                  fontSize={"small"}
                  style={{
                    color: localWebcamOn
                      ? "white"
                      : appTheme === appThemes.LIGHT &&
                        theme.palette.lightTheme.contrastText,
                  }}
                />
              </IconButton>
            </Tooltip>
          );
        }}
      />
      <Popover
        container={tollTipEl.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={tollTipEl.current}
        open={Boolean(downArrow)}
        onClose={handleClose}
      >
        <MenuList
          style={{
            backgroundColor:
              appTheme === appThemes.DARK
                ? theme.palette.darkTheme.slightLighter
                : appTheme === appThemes.LIGHT
                ? theme.palette.lightTheme.two
                : "",
            color:
              appTheme === appThemes.DARK
                ? theme.palette.common.white
                : appTheme === appThemes.LIGHT
                ? theme.palette.lightTheme.contrastText
                : "",
          }}
        >
          {webcams.map(({ deviceId, label }, index) => (
            <MenuItem
              key={`output_webcams_${deviceId}`}
              selected={deviceId === selectWebcamDeviceId}
              onClick={() => {
                handleClose();
                setSelectWebcamDeviceId(deviceId);
                changeWebcam(deviceId);
              }}
              classes={{
                root:
                  appTheme === appThemes.LIGHT
                    ? classes.popoverHover
                    : appTheme === appThemes.DARK
                    ? classes.popoverHoverDark
                    : "",
              }}
            >
              {label || `Webcam ${index + 1}`}
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </Box>
  );
};
const MicBTN = () => {
  const {
    appTheme,
    notificationSoundEnabled,
    notificationAlertsEnabled,
    selectMicDeviceId,
    setSelectMicDeviceId,
  } = useMeetingAppContext();

  const [isNoiseRemovalChecked, setIsNoiseRemovalChecked] = useState(false);
  const [downArrow, setDownArrow] = useState(null);
  const [mics, setMics] = useState([]);
  const [outputmics, setOutputMics] = useState([]);
  const mMeeting = useMeeting({});
  const theme = useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event) => {
    setDownArrow(event.currentTarget);
  };

  const handleClose = () => {
    setDownArrow(null);
  };

  const localMicOn = mMeeting?.localMicOn;
  const toggleMic = mMeeting?.toggleMic;
  const changeMic = mMeeting?.changeMic;

  const getMics = async (mGetMics) => {
    const mics = await mGetMics();

    mics && mics?.length && setMics(mics);
  };

  const tollTipEl = useRef();

  const getOutputDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const outputMics = devices.filter((d) => d.kind === "audiooutput");

    outputMics && outputMics?.length && setOutputMics(outputMics);
  };

  const _handleNoiseClick = async ({ e, selectMicDeviceId }) => {
    e.stopPropagation();
    let _isNoiseRemovalChecked;
    setIsNoiseRemovalChecked((s) => {
      const notS = !s;
      _isNoiseRemovalChecked = notS;
      return notS;
    });

    try {
      const processor = new VideoSDKNoiseSuppressor();

      const stream = await createMicrophoneAudioTrack({
        microphoneId: selectMicDeviceId,
      });
      const processedStream = await processor.getNoiseSuppressedAudioStream(
        stream
      );

      changeMic(processedStream);
    } catch (error) {
      console.log(error);
    }

    if (_isNoiseRemovalChecked) {
      if (notificationSoundEnabled) {
        new Audio(
          `https://static.videosdk.live/prebuilt/notification.mp3`
        ).play();
      }

      if (notificationAlertsEnabled) {
        enqueueSnackbar("Noise removal activated");
      }
    }
  };

  return (
    <Box
      ref={tollTipEl}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <OutlineIconButton
        btnID={"btnMic"}
        tooltipTitle={
          isNoiseRemovalChecked
            ? "Noise Removal Activated"
            : localMicOn
            ? "Turn off mic"
            : "Turn on mic"
        }
        isFocused={localMicOn}
        Icon={localMicOn ? MicOn : MicOffIcon}
        onClick={toggleMic}
        focusBGColor={
          appTheme === appThemes.LIGHT
            ? theme.palette.lightTheme.contrastText
            : "#ffffff33"
        }
        focusBorderColor={isNoiseRemovalChecked && localMicOn ? "#7dcc7d" : ""}
        focusIconColor={theme.palette.common.white}
        renderRightComponent={() => {
          return (
            <Tooltip placement="bottom" title={"Change microphone"}>
              <IconButton
                onClick={(e) => {
                  getMics(mMeeting.getMics);
                  getOutputDevices();
                  handleClick(e);
                }}
                size={"small"}
              >
                <ArrowDropDownIcon
                  fontSize={"small"}
                  style={{
                    color: localMicOn
                      ? "white"
                      : appTheme === appThemes.LIGHT &&
                        theme.palette.lightTheme.contrastText,
                  }}
                />
              </IconButton>
            </Tooltip>
          );
        }}
      />
      <MicMenu
        selectMicDeviceId={selectMicDeviceId}
        setSelectMicDeviceId={setSelectMicDeviceId}
        isNoiseRemovalChecked={isNoiseRemovalChecked}
        localMicOn={localMicOn}
        theme={theme}
        appTheme={appTheme}
        downArrow={downArrow}
        tollTipEl={tollTipEl}
        changeMic={changeMic}
        mics={mics}
        outputmics={outputmics}
        classes={classes}
        _handleNoiseClick={_handleNoiseClick}
        handleClose={handleClose}
      />
    </Box>
  );
};
const EndCallBTN = () => {
  const mMeeting = useMeeting({});
  const classes = useStyles();

  const [isEndMeeting, setIsEndMeeting] = useState(false);
  const {
    endCallContainerRef,
    participantCanEndMeeting,
    participantCanLeave,
    meetingMode,
    appTheme,
  } = useMeetingAppContext();

  const sendChatMessage = mMeeting?.sendChatMessage;

  const leave = mMeeting?.leave;
  const end = mMeeting?.end;

  const tollTipEl = useRef();

  const theme = useTheme();

  const [downArrow, setDownArrow] = useState(null);

  const handleClick = (event) => {
    setDownArrow(event.currentTarget);
  };

  const handleClose = () => {
    setDownArrow(null);
  };

  return (
    <Box
      ref={tollTipEl}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <OutlineIconButton
        ref={endCallContainerRef}
        tooltipTitle={
          !participantCanLeave && meetingMode === meetingModes.CONFERENCE
            ? "End Call"
            : participantCanEndMeeting &&
              meetingMode === meetingModes.CONFERENCE
            ? "Open popup"
            : "Leave Call"
        }
        bgColor={theme.palette.error.main}
        color={theme.palette.common.white}
        Icon={EndCall}
        onClick={(e) => {
          !participantCanLeave && meetingMode === meetingModes.CONFERENCE
            ? setIsEndMeeting(true)
            : participantCanEndMeeting &&
              meetingMode === meetingModes.CONFERENCE
            ? handleClick(e)
            : leave();
        }}
      />
      {participantCanEndMeeting && (
        <>
          <Popover
            container={tollTipEl.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            anchorEl={tollTipEl.current}
            open={Boolean(downArrow)}
            onClose={handleClose}
            classes={{
              paper: classes.popoverBorder,
            }}
          >
            <MenuList
              style={{
                backgroundColor:
                  appTheme === appThemes.DARK
                    ? theme.palette.darkTheme.slightLighter
                    : appTheme === appThemes.LIGHT
                    ? theme.palette.lightTheme.two
                    : "",
                color:
                  appTheme === appThemes.DARK
                    ? theme.palette.common.white
                    : appTheme === appThemes.LIGHT
                    ? theme.palette.lightTheme.contrastText
                    : "",
              }}
            >
              <MenuItem
                key={`leave`}
                onClick={() => {
                  leave();
                }}
                classes={{
                  root:
                    appTheme === appThemes.LIGHT
                      ? classes.popoverHover
                      : appTheme === appThemes.DARK
                      ? classes.popoverHoverDark
                      : "",
                }}
              >
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  <Box
                    style={{
                      backgroundColor:
                        appTheme === appThemes.DARK
                          ? theme.palette.darkTheme.seven
                          : appTheme === appThemes.LIGHT
                          ? theme.palette.lightTheme.three
                          : theme.palette.common.sidePanel,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 42,
                      width: 42,
                      borderRadius: 4,
                    }}
                  >
                    <LeaveMeetingIcon
                      height={22}
                      width={22}
                      fill={
                        appTheme === appThemes.LIGHT &&
                        theme.palette.lightTheme.contrastText
                      }
                    />
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      marginLeft: 12,
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 14,
                        color:
                          appTheme === appThemes.LIGHT &&
                          theme.palette.lightTheme.contrastText,
                      }}
                    >
                      Leave
                    </Typography>
                    <Typography
                      color={"textSecondary"}
                      style={{
                        fontSize: "0.9rem",
                        color:
                          appTheme === appThemes.DARK
                            ? theme.palette.darkTheme.four
                            : appTheme === appThemes.LIGHT
                            ? theme.palette.lightTheme.five
                            : "",
                      }}
                    >
                      Only you will leave the call.
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
              <MenuItem
                style={{ marginTop: 4 }}
                key={`end`}
                onClick={() => {
                  setIsEndMeeting(true);
                }}
                classes={{
                  root:
                    appTheme === appThemes.LIGHT
                      ? classes.popoverHover
                      : appTheme === appThemes.DARK
                      ? classes.popoverHoverDark
                      : "",
                }}
              >
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  <Box
                    style={{
                      backgroundColor:
                        appTheme === appThemes.DARK
                          ? theme.palette.darkTheme.seven
                          : appTheme === appThemes.LIGHT
                          ? theme.palette.lightTheme.three
                          : theme.palette.common.sidePanel,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 42,
                      width: 42,
                      borderRadius: 4,
                    }}
                  >
                    <EndCallIcon
                      fill={
                        appTheme === appThemes.LIGHT &&
                        theme.palette.lightTheme.contrastText
                      }
                    />
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      marginLeft: 12,
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 14,
                        lineHeight: 1.5,
                        color:
                          appTheme === appThemes.LIGHT &&
                          theme.palette.lightTheme.contrastText,
                      }}
                    >
                      End
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "0.9rem",
                        color:
                          appTheme === appThemes.DARK
                            ? theme.palette.darkTheme.four
                            : appTheme === appThemes.LIGHT
                            ? theme.palette.lightTheme.five
                            : "",
                      }}
                      color={"textSecondary"}
                    >
                      End call for all participants.
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
            </MenuList>
          </Popover>

          <ConfirmBox
            open={isEndMeeting}
            title={"Are you sure to end this call for everyone?"}
            successText={"End Call"}
            onSuccess={() => {
              sendChatMessage(
                JSON.stringify({ buttonType: "END_CALL", data: {} })
              );
              setTimeout(() => {
                end();
              }, 1000);
            }}
            rejectText="Cancel"
            onReject={() => {
              setIsEndMeeting(false);
            }}
          />
        </>
      )}
    </Box>
  );
};

const TopBar = ({ topBarHeight }) => {
  const [client_id_flag, setclient_id_flag] = useState(0);
  const [isClientIdNull, setisClientIdNul] = useState(true);
  const [domain_id_flag, setdomain_id_flag] = useState("");
  const [domain_name_flag, setdomain_name_flag] = useState("");
  const [meetingId_flag, setmeetingId_flag] = useState("");

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [popupOpen, setpopupOpen] = useState(false);
  const [defaultBrandLogoUrl, setDefaultBrandLogoUrl] = useState(null);
  const [getAllParticipants, setgetAllParticipants] = useState([]);
  const [issubmit, setIssubmit] = useState(false);
  const [formdata, setFormData] = useState({
    name: "",
    contact_number: "",
  });
  const authdata = getAuth();
  const userid = authdata["_id"];
  console.log("authdata...>", authdata);
  const [callDetails, setCallDetails] = useState([]);
  const [partcipantPopup, setpartcipantPopup] = useState(true);
  const [languages, setlanguages] = useState([]);
  const [UserType, setUserType] = useState("1");
  const [InterpreterLanguage, setInterpreterLanguage] = useState("");
  const [InterpreterLanguageFromDropdown, setInterpreterLanguageFromDropdown] =
    useState("Select Language");
  const [formErrors, setFormErrors] = useState({});
  const [Somevar, setSomevar] = useState(false);

  const getparticipants = async () => {
    var CallSetupIdvar = localStorage.getItem("callSetupid");
    const response = await getDataFromApi(
      "getcallAttendeeDetails/" + CallSetupIdvar
    );
    if (response.data.code && response.data.data != null) {
      setgetAllParticipants(response.data.data);
      console.log("participants", response.data.data);
    }
  };

  const BaseUrl = mainURL;

  const closeModal = () => {
    setpopupOpen(false);
  };
  const openModal = () => {
    setpopupOpen(true);
  };
  const {
    chatEnabled,
    screenShareEnabled,
    canChangeLayout,
    participantCanToggleLivestream,
    liveStreamEnabled,
    hlsEnabled,
    pollEnabled,
    whiteboardEnabled,
    participantCanToggleSelfWebcam,
    participantCanToggleSelfMic,
    raiseHandEnabled,
    recordingEnabled,
    brandingEnabled,
    brandLogoURL,
    brandName,
    participantCanLeave,
    poweredBy,
    participantCanEndMeeting,
    animationsEnabled,
    meetingMode,
    participantTabPanelEnabled,
    appTheme,
  } = useMeetingAppContext();

  const handleClickFAB = () => {
    setOpen(true);
  };

  const handleCloseFAB = () => {
    setOpen(false);
  };

  useEffect(()=>{
    var client_flag=localStorage.getItem("client_id_flag")
    console.log("client_flag==>",client_flag);
    var domain_flag=localStorage.getItem("domain_id_flag")
    var domain_n_flag=localStorage.getItem("domain_name_flag")
    var meetingId_f=localStorage.getItem("meetingId_flag")
    // console.log("flagdata===>",Boolean(client_flag))
    // if(client_flag !== null){
    if(client_flag !== "null"){
      console.log("inside if")
      // console.log("flagdata===>",client_flag,domain_flag,domain_n_flag,meetingId_f)
      console.log("flagdata===>",client_flag)
      setclient_id_flag(client_flag)
      setisClientIdNul(false)
      setdomain_id_flag(domain_flag)
      setdomain_name_flag(domain_n_flag)
      setmeetingId_flag(meetingId_f)
    } else{
      console.log("inside else")
      setisClientIdNul(true)
    }

  },[])

  const isMobile = useIsMobile();
  const isTab = useIsTab();
  const theme = useTheme();

  useEffect(() => {
    console.log("languages========>", languages);
  }, []);

  useEffect(() => {
    console.log("languages========>", languages);
  }, []);

  const OnDetailsChange = (e) => {
    var value = e.target.value;
    // console.log("onChangeLanguageId=====>",e.target.value)

    // console.log("onChangeLanguageId=====>",e.target.value)
    if (UserType == 1) {
      setFormData((formdata) => ({
        ...formdata,
        [e.target.name]: e.target.value,
      }));
      console.log("formdataa..", formdata);
    } else {
      setInterpreterLanguage(e.target.value);
      setInterpreterLanguageFromDropdown(
        languages[e.target.value - 1].language
      );
      console.log("language", e.target.value, languages[e.target.value - 1]);
      setInterpreterLanguageFromDropdown(
        languages[e.target.value - 1].language
      );
      console.log("language", e.target.value, languages[e.target.value - 1]);
    }
    console.log("OnDetailsChange====>", e);
    console.log("OnDetailsChange====>", e.target);
    console.log("OnDetailsChange====>", e.target.value);
  };

  const getlanguages = async () => {
    var query = "model=LanguageMasterData";
    var query = "model=LanguageMasterData";
    const response = await postDataFromApi("all/getAllMaster", query);
    if (response.data.code && response.data.data != null) {
      setlanguages(response.data.data);
    }
  };

  //   import { useParticipant } from "@videosdk.live/react-sdk";
  // /** localParticipant contains Participant object
  //   as displayed above local participant section */

  // const localParticipant = meeting.localParticipant;

  // const participants = meeting.participants;

  // /** Render All Participant including local participant */
  // {
  //   [...participants.keys()].map((k) => (
  //     <div style={{ display: "flex" }}>
  //       {k.map((l) => (
  //         <ParticipantView key={l} participantId={l} />
  //       ))}
  //     </div>
  //   ));
  // }

  // /* Participant View Component/

  // const ParticipantView = ({ participantId }) => {
  //   /* Define Refs/
  //   const webcamRef = useRef(null);
  //   const micRef = useRef(null);

  //   /** useParticipant Hooks which accept `participantId`
  //     as parameter then return participant properties such as displayName, webcamOn, micOn etc.  */
  //   const {
  //     displayName,
  //     webcamStream,
  //     micStream,
  //     webcamOn,
  //     micOn,
  //     isActiveSpeaker,
  //   } = useParticipant(participantId);

  //   useEffect(() => {
  //     if (webcamRef.current) {
  //       if (webcamOn) {
  //         const mediaStream = new MediaStream();
  //         mediaStream.addTrack(webcamStream.track);

  //         webcamRef.current.srcObject = mediaStream;
  //         webcamRef.current
  //           .play()
  //           .catch((error) =>
  //             console.error("videoElem.current.play() failed", error)
  //           );
  //       } else {
  //         webcamRef.current.srcObject = null;
  //       }
  //     }
  //   }, [webcamStream, webcamOn]);

  //   useEffect(() => {
  //     if (micRef.current) {
  //       if (micOn) {
  //         const mediaStream = new MediaStream();
  //         mediaStream.addTrack(micStream.track);

  //         micRef.current.srcObject = mediaStream;
  //         micRef.current
  //           .play()
  //           .catch((error) =>
  //             console.error("videoElem.current.play() failed", error)
  //           );
  //       } else {
  //         micRef.current.srcObject = null;
  //       }
  //     }
  //   }, [micStream, micOn]);

  //   return (
  //     <>
  //       <audio ref={micRef} autoPlay />
  //       <video height={"50%"} width={"50%"} ref={webcamRef} autoPlay />
  //     </>
  //   );
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData("");
    e.target.reset();
  };
  const dropdownchange = (e) => {
    setUserType(e.target.value);
  };
  // const validate = (values) => {
  //     const errors = {};
  //     if (!values.name) {
  //       errors.name = "Name is required";
  //     }
  //     if (!values.contact_number) {
  //       errors.contact_number = "Contact number is required";
  //     }
  //       console.log("hii")
  //     return errors;
  //   };
  // to add user in call
  function handleProceedToCall() {
    console.log("clicked handleProceedToCall");
    // (UserType == 2) ? (()=>{AddInterpreterInCall()}) : (()=>{AddUserCall()})
    if (UserType == 2) {
      AddInterpreterInCall();
    } else {
      AddUserCall();
    }
  }

  function handleShareClick() {
    var callsetid=sessionStorage.getItem("callSetupid")
    var text=helplineUrl+`${domain_name_flag}`+`/${client_id_flag}`+`/${domain_id_flag}`+`/${callsetid}`+`/${meetingId_flag}`
    copy(text)
    alert("Link Copied")
    
  }

  const AddUserCall = async () => {
    var contactDetailsArray = [];
    console.log("contactDetails..>", contactDetailsArray);
    contactDetailsArray = [
      {
        contact_number: formdata.contact_number,
        name: formdata.name,
      },
    ];

    var CallSetupIdvar = localStorage.getItem("callSetupid");
    var inputData = JSON.stringify({
      call_setup_id: CallSetupIdvar,
      contactDetails: contactDetailsArray, //put condition here
      contactDetails: contactDetailsArray, //put condition here
      interpreter_id: userid,
      internet_bandwidth: "networkBandwidth",
    });

    try {
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: inputData,
      };
      const response = await fetch(BaseUrl + "/add/CallSetupAttendee", options)
        .then(async (result) => {
          const response = await result.json();
          getparticipants();
          swal({
            // title: "client added!",
            text: "Joining Request Sent",
            text: "Joining Request Sent",
            icon: "success",
          }).then(function () {
            // navigate('/home')
          });
          // setFormErrors(validate(formdata));
          // setIssubmit(true)
          return response;
        })
        .catch((error) => console.log("error", error));
      return response;
    } catch (e) {
      console.log(e);
    }
  };

  const AddInterpreterInCall = async () => {
    var CallSetupIdvar = localStorage.getItem("callSetupid");
    var inputData = JSON.stringify({
      call_setup_id: CallSetupIdvar,
      // "language_id":  formdata.language ? formdata.language : authdata['language_id'],
      language_id: InterpreterLanguage,
      internet_bandwidth: "networkBandwidth",
      interpreter_id: userid,
    });

    try {
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: inputData,
      };
      const response = await fetch(
        BaseUrl + "/addInterpreterInCallSetup",
        options
      )
        .then(async (result) => {
          const response = await result.json();
          swal({
            // title: "client added!",
            text: "Joining Request Sent",
            text: "Joining Request Sent",
            icon: "success",
          }).then(function () {
            // navigate('/home')
          });

          getparticipants();
          return response;
        })
        .catch((error) => console.log("error", error));
      return response;
    } catch (e) {
      console.log(e);
    }
  };

  const AddUserToJoinCall = async (index) => {
    var CallSetupIdvar = localStorage.getItem("callSetupid");
    var inputData = JSON.stringify({
      call_setup_id: CallSetupIdvar,
      user_id: index,
    });

    try {
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: inputData,
      };
      const response = await fetch(
        BaseUrl + "/sendNotificationIfUserDidntJoin",
        options
      )
        .then(async (result) => {
          const response = await result.json();

          swal({
            // title: "client added!",
            text: "User Added Succesfully",
            text: "User Added Succesfully",
            icon: "success",
          }).then(function () {
            // navigate('/home')
          });

          return response;
        })
        .catch((error) => console.log("error", error));
      return response;
    } catch (e) {
      console.log(e);
    }
    console.log("index", index);
  };

  // [4:30 PM, 10/20/2022] Mahesh Sir: sendNotificationIfUserDidntJoin
  // [4:30 PM, 10/20/2022] Mahesh Sir: call_setup_id: '',
  //       user_id: ''
  const topBarButtonTypes = useMemo(
    () => ({
      END_CALL: "END_CALL",
      ACTIVITIES: "ACTIVITIES",
      CHAT: "CHAT",
      PARTICIPANTS: "PARTICIPANTS",
      SCREEN_SHARE: "SCREEN_SHARE",
      WEBCAM: "WEBCAM",
      MIC: "MIC",
      RAISE_HAND: "RAISE_HAND",
      RECORDING: "RECORDING",
      HLS: "HLS",
      WHITEBOARD: "WHITEBOARD",
      ADD_LIVE_STREAM: "ADD_LIVE_STREAM",
      CONFIGURATION: "CONFIGURATION",
      GO_LIVE: "GO_LIVE",
      CustomBtn: "CustomBtn",
    }),
    []
  );

  const { topBarIcons, firstFourElements, excludeFirstFourElements } =
    useMemo(() => {
      const arr = [];
      const mobileIconArr = [];

      if (participantCanLeave || participantCanEndMeeting) {
        arr.unshift([topBarButtonTypes.END_CALL]);
        mobileIconArr.unshift({
          buttonType: topBarButtonTypes.END_CALL,
          priority: 1,
        });
      }

      const arrSideBar = [];

      if (canChangeLayout && meetingMode === meetingModes.CONFERENCE) {
        arrSideBar.unshift(topBarButtonTypes.CONFIGURATION);
        mobileIconArr.unshift({
          buttonType: topBarButtonTypes.CONFIGURATION,
          priority: 7,
        });
      }
      if (chatEnabled) {
        arrSideBar.unshift(topBarButtonTypes.CHAT);
        mobileIconArr.unshift({
          buttonType: topBarButtonTypes.CHAT,
          priority: 5,
        });
      }
      if (participantTabPanelEnabled) {
        arrSideBar.unshift(topBarButtonTypes.PARTICIPANTS);
        mobileIconArr.unshift({
          buttonType: topBarButtonTypes.PARTICIPANTS,
          priority: 10,
        });
      }

      if (pollEnabled || whiteboardEnabled) {
        arrSideBar.unshift(topBarButtonTypes.ACTIVITIES);
        mobileIconArr.unshift({
          buttonType: topBarButtonTypes.ACTIVITIES,
          // priority: 10,
        });
      }

      arr.unshift(arrSideBar);

      const arrMedia = [];

      if (screenShareEnabled && meetingMode === meetingModes.CONFERENCE) {
        arrMedia.unshift(topBarButtonTypes.SCREEN_SHARE);
        mobileIconArr.unshift({
          buttonType: topBarButtonTypes.SCREEN_SHARE,
          priority: 6,
        });
      }
      if (
        participantCanToggleSelfWebcam &&
        meetingMode === meetingModes.CONFERENCE
      ) {
        arrMedia.unshift(topBarButtonTypes.WEBCAM);
        mobileIconArr.unshift({
          buttonType: topBarButtonTypes.WEBCAM,
          priority: 2,
        });
      }
      if (
        participantCanToggleSelfMic &&
        meetingMode === meetingModes.CONFERENCE
      ) {
        arrMedia.unshift(topBarButtonTypes.MIC);
        mobileIconArr.unshift({
          buttonType: topBarButtonTypes.MIC,
          priority: 3,
        });
      }

      if (arrMedia.length) {
        arr.unshift(arrMedia);
      }

      const utilsArr = [];

      if (raiseHandEnabled) {
        utilsArr.unshift(topBarButtonTypes.RAISE_HAND);
        mobileIconArr.unshift({
          buttonType: topBarButtonTypes.RAISE_HAND,
          priority: 13,
        });
      }

      if (recordingEnabled && meetingMode === meetingModes.CONFERENCE) {
        utilsArr.unshift(topBarButtonTypes.RECORDING);
        mobileIconArr.unshift({
          buttonType: topBarButtonTypes.RECORDING,
          priority: 4,
        });
      }

      // if (whiteboardEnabled && meetingMode === meetingModes.CONFERENCE) {
      //   utilsArr.unshift(topBarButtonTypes.WHITEBOARD);
      //   mobileIconArr.unshift({
      //     buttonType: topBarButtonTypes.WHITEBOARD,
      //     priority: 11,
      //   });
      // }

      if (
        liveStreamEnabled &&
        !participantCanToggleLivestream &&
        meetingMode === meetingModes.CONFERENCE
      ) {
        utilsArr.unshift(topBarButtonTypes.GO_LIVE);
        mobileIconArr.unshift({
          buttonType: topBarButtonTypes.GO_LIVE,
          priority: 9,
        });
      }

      if (hlsEnabled && meetingMode === meetingModes.CONFERENCE) {
        utilsArr.unshift(topBarButtonTypes.HLS);
        mobileIconArr.unshift({
          buttonType: topBarButtonTypes.HLS,
          priority: 14,
        });
      }

      if (
        participantCanToggleLivestream &&
        liveStreamEnabled &&
        meetingMode === meetingModes.CONFERENCE
      ) {
        //liveStreamIcon
        utilsArr.unshift(topBarButtonTypes.GO_LIVE);
        mobileIconArr.unshift({
          buttonType: topBarButtonTypes.GO_LIVE,
          priority: 9,
        });
        //AddLiveStreamIcon
        // utilsArr.unshift(topBarButtonTypes.ADD_LIVE_STREAM);
        // mobileIconArr.unshift({
        //   buttonType: topBarButtonTypes.ADD_LIVE_STREAM,
        //   priority: 8,
        // });
      }

      if (participantCanToggleLivestream && !liveStreamEnabled) {
      }

      if (utilsArr.length) {
        arr.unshift(utilsArr);
      }

      //sorting mobile icon
      mobileIconArr
        .sort((iconA, iconB) => iconA.priority - iconB.priority)
        .map((icon) => icon.buttonType);

      const firstFourElements = mobileIconArr.slice(0, 4);

      const excludeFirstFourElements = mobileIconArr.slice(4);

      return {
        topBarIcons: arr,
        mobileIcons: mobileIconArr,
        firstFourElements,
        excludeFirstFourElements,
      };
    }, [
      participantCanToggleSelfMic,
      participantCanToggleSelfWebcam,
      screenShareEnabled,
      pollEnabled,
      whiteboardEnabled,
      chatEnabled,
      raiseHandEnabled,
      topBarButtonTypes,
      recordingEnabled,
      meetingMode,
    ]);

  const [topBarVisible, setTopBarVisible] = useState(false);

  useEffect(() => {
    // getInCommingCall();
    // if (Object.keys(formErrors).length == 0 && issubmit) {
    //   setSomevar(true);
    getparticipants();
    getlanguages();
    setTimeout(() => {
      setTopBarVisible(true);
    }, 100);
    // }
    // }
  }, []);

  return isTab || isMobile ? (
    <Box
      style={{
        height: topBarHeight,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          appTheme === appThemes.DARK
            ? theme.palette.darkTheme.main
            : appTheme === appThemes.LIGHT
            ? theme.palette.lightTheme.main
            : theme.palette.background.default,
      }}
    >
      {firstFourElements.map((icon, i) => {
        return (
          <Box key={`topbar_controls_j_${i}`} ml={i === 0 ? 0 : 1.5}>
            {icon.buttonType === topBarButtonTypes.RAISE_HAND ? (
              <RaiseHandBTN />
            ) : icon.buttonType === topBarButtonTypes.CustomBtn ? (
              <AddBTN />
            ) : icon.buttonType === topBarButtonTypes.WEBCAM ? (
              <WebcamBTN />
            ) : icon.buttonType === topBarButtonTypes.SCREEN_SHARE ? (
              <ScreenShareBTN />
            ) : icon.buttonType === topBarButtonTypes.PARTICIPANTS ? (
              <ParticipantsBTN />
            ) : icon.buttonType === topBarButtonTypes.CHAT ? (
              <ChatBTN />
            ) : // ) : icon.buttonType === topBarButtonTypes.ACTIVITIES ? (
            //   <ActivitiesBTN />
            icon.buttonType === topBarButtonTypes.END_CALL ? (
              <EndCallBTN />
            ) : icon.buttonType === topBarButtonTypes.RECORDING ? (
              <RecordingBTN />
            ) : icon.buttonType === topBarButtonTypes.HLS ? (
              <HlsBTN />
            ) : icon.buttonType === topBarButtonTypes.GO_LIVE ? (
              <GoLiveBTN />
            ) : icon.buttonType === topBarButtonTypes.WHITEBOARD ? (
              <WhiteBoardBTN />
            ) : icon.buttonType === topBarButtonTypes.ADD_LIVE_STREAM ? (
              <AddLiveStreamBTN />
            ) : icon.buttonType === topBarButtonTypes.CONFIGURATION ? (
              <ConfigBTN />
            ) : null}
          </Box>
        );
      })}

      {excludeFirstFourElements.length >= 1 && (
        <Box ml={2}>
          <OutlineIconButton
            Icon={MoreHorizIcon}
            // Icon={Boolean(anchorEl) ? CloseIcon : MoreHorizIcon}
            // isFocused={anchorEl}
            onClick={handleClickFAB}
          />
        </Box>
      )}

      <SwipeableDrawer
        anchor={"bottom"}
        open={Boolean(open)}
        onClose={handleCloseFAB}
        onOpen={handleClickFAB}
        style={{ paddingBottom: "100px" }}
      >
        <Grid
          container
          style={{
            backgroundColor:
              appTheme === appThemes.DARK
                ? theme.palette.darkTheme.main
                : appTheme === appThemes.LIGHT
                ? theme.palette.lightTheme.main
                : theme.palette.background.default,
          }}
        >
          {excludeFirstFourElements.map((icon, i) => {
            return (
              <Grid
                item
                xs={4}
                sm={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {icon.buttonType === topBarButtonTypes.RAISE_HAND ? (
                  <RaiseHandBTN
                    onClick={handleCloseFAB}
                    isMobile={isMobile}
                    isTab={isTab}
                  />
                ) : icon.buttonType === topBarButtonTypes.MIC ? (
                  <MicBTN
                    onClick={handleCloseFAB}
                    isMobile={isMobile}
                    isTab={isTab}
                  />
                ) : icon.buttonType === topBarButtonTypes.WEBCAM ? (
                  <WebcamBTN
                    onClick={handleCloseFAB}
                    isMobile={isMobile}
                    isTab={isTab}
                  />
                ) : icon.buttonType === topBarButtonTypes.SCREEN_SHARE ? (
                  <ScreenShareBTN
                    onClick={handleCloseFAB}
                    isMobile={isMobile}
                    isTab={isTab}
                  />
                ) : icon.buttonType === topBarButtonTypes.PARTICIPANTS ? (
                  <ParticipantsBTN
                    onClick={handleCloseFAB}
                    isMobile={isMobile}
                    isTab={isTab}
                  />
                ) : icon.buttonType === topBarButtonTypes.CHAT ? (
                  <ChatBTN
                    onClick={handleCloseFAB}
                    isMobile={isMobile}
                    isTab={isTab}
                  />
                ) : // : icon.buttonType === topBarButtonTypes.ACTIVITIES ? (
                //   <ActivitiesBTN
                //     onClick={handleCloseFAB}
                //     isMobile={isMobile}
                //     isTab={isTab}
                //   />
                // )
                icon.buttonType === topBarButtonTypes.END_CALL ? (
                  <EndCallBTN
                    onClick={handleCloseFAB}
                    isMobile={isMobile}
                    isTab={isTab}
                  />
                ) : icon.buttonType === topBarButtonTypes.RECORDING ? (
                  <RecordingBTN
                    onClick={handleCloseFAB}
                    isMobile={isMobile}
                    isTab={isTab}
                  />
                ) : icon.buttonType === topBarButtonTypes.HLS ? (
                  <HlsBTN
                    onClick={handleCloseFAB}
                    isMobile={isMobile}
                    isTab={isTab}
                  />
                ) : icon.buttonType === topBarButtonTypes.GO_LIVE ? (
                  <GoLiveBTN
                    onClick={handleCloseFAB}
                    isMobile={isMobile}
                    isTab={isTab}
                  />
                ) : icon.buttonType === topBarButtonTypes.WHITEBOARD ? (
                  <WhiteBoardBTN
                    onClick={handleCloseFAB}
                    isMobile={isMobile}
                    isTab={isTab}
                  />
                ) : icon.buttonType === topBarButtonTypes.ADD_LIVE_STREAM ? (
                  <AddLiveStreamBTN
                    onClick={handleCloseFAB}
                    isMobile={isMobile}
                    isTab={isTab}
                  />
                ) : icon.buttonType === topBarButtonTypes.CONFIGURATION ? (
                  <ConfigBTN
                    onClick={handleCloseFAB}
                    isMobile={isMobile}
                    isTab={isTab}
                  />
                ) : null}
              </Grid>
            );
          })}
        </Grid>
      </SwipeableDrawer>
    </Box>
  ) : (
    <Box
      style={{
        height: topBarHeight,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor:
          appTheme === appThemes.DARK
            ? theme.palette.darkTheme.main
            : appTheme === appThemes.LIGHT
            ? theme.palette.lightTheme.main
            : theme.palette.background.default,
        borderBottom: `1px solid ${
          appTheme === appThemes.LIGHT
            ? theme.palette.lightTheme.outlineColor
            : "#ffffff33"
        }`,
        position: "relative",
        top: topBarVisible ? 0 : -topBarHeight,
        transition: `all ${400 * (animationsEnabled ? 1 : 0.5)}ms`,
        transitionTimingFunction: "ease-in-out",
      }}
    >
      <Box
        style={{
          display: "flex",
          height: topBarHeight,
          paddingLeft: theme.spacing(2),
          position: "relative",
          alignItems: "center",
        }}
      >
        {brandingEnabled && (
          <>
            <img
              alt={"App Logo"}
              style={{
                display: "inline-block",
                height: topBarHeight - theme.spacing(2),
              }}
              src={
                defaultBrandLogoUrl ||
                brandLogoURL ||
                `https://static.videosdk.live/prebuilt/videosdk_logo_circle.png`
              }
              onError={() => {
                setDefaultBrandLogoUrl(
                  `https://static.videosdk.live/prebuilt/videosdk_logo_circle.png`
                );
              }}
            />
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
              ml={1}
            >
              <Typography
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "600",
                  color:
                    appTheme === appThemes.LIGHT &&
                    theme.palette.lightTheme.contrastText,
                }}
              >
                {brandName}
              </Typography>
              {poweredBy ? (
                <Typography
                  style={{
                    fontSize: "0.9rem",
                    wordBreak: "break-all",
                    display: "flex",
                    alignItems: "center",
                    color:
                      appTheme === appThemes.LIGHT &&
                      theme.palette.lightTheme.contrastText,
                  }}
                  color={"textSecondary"}
                >
                  Powered by&nbsp;
                  <Link
                    style={{ textDecorationColor: "#fa3a57" }}
                    target={"_blank"}
                    href={"https://videosdk.live"}
                  >
                    <Typography
                      style={{
                        color: "#fa3a57",
                        textTransform: "lowercase",
                        fontSize: "0.9rem",
                      }}
                    >
                      videosdk.live
                    </Typography>
                  </Link>
                </Typography>
              ) : null}
            </Box>
          </>
        )}
      </Box>

      <Box className={classes.row} p={2}>
        {/* add new buttons in top bar here */}
        {/* <Box style={{ marginLeft: "700px", marginRight: "0px" }}> */}
        {console.log("isClientIdNull====>",isClientIdNull)}
        {!isClientIdNull && (
          <Box>
          <Button onClick={handleShareClick}>
            <ShareIcon
              style={{
                border: "2px solid #4d4d5b",
                padding: "8px",
                borderRadius: "10px",
              }}
            />
          </Button>
        </Box>
        )}
        {topBarIcons.map((row, i) => {
          return (
            <React.Fragment key={`topbar_controls_i_${i}`}>
              {i !== 0 && (
                <Box
                  style={{
                    backgroundColor:
                      appTheme === appThemes.LIGHT
                        ? theme.palette.lightTheme.outlineColor
                        : "#ffffff33",
                    width: 1,
                    height: topBarHeight - theme.spacing(1.5),
                    flex: 1,
                  }}
                />
              )}
              <Box
                ml={i === 0 ? 0 : 2}
                mr={i === topBarIcons.length - 1 ? 0 : 2}
                className={classes.row}
              >
                {/* {i == 0 ? <Button onClick={openModal}>Add</Button> : null} */}
                <Popup
                  // className="processingcall-modal contact-detail-popup"
                  // modal
                  open={popupOpen}
                  // closeOnDocumentClick
                  onClose={closeModal}
                  trigger={
                    i == 0 ? (
                      <Button>
                        {/* <img src={add} style={{ width: "30px" }} /> */}
                        <AddIcon
                          // sx={{ fontSize: "80px" }}
                          style={{
                            border: "2px solid #4d4d5b",
                            padding: "8px",
                            borderRadius: "10px",
                          }}
                        />
                      </Button>
                    ) : null
                  }
                  // trigger={i == 0 ?<AddUser/> : null}
                  position="bottom left"
                >
                  <div
                    className="user_interpreter_add_div"
                    style={{
                      width: "380px",
                      // height: "200px",
                      height: "fitContent",
                      marginTop: "auto",
                      marginBottom: "15px",
                      backgroundColor: "white",
                    }}
                  >
                    {/* <button className="close" onClick={closeModal}>
						</button> */}

                    <Grid
                      container
                      className="add_people_div_container"
                      style={{
                        marginTop: "10px",
                        marginLeft: "10px",
                        height: "fitContent",
                      }}
                    >
                      <Grid md={8}>
                        <div className="form-div">
                          <select
                            className="form-input"
                            onChange={dropdownchange}
                            style={{ border: "none" }}
                          >
                            <option value="1">User</option>
                            <option value="2">Interpreter</option>
                          </select>
                        </div>
                      </Grid>
                      {UserType == 2 ? (
                        <Grid md={8}>
                          <div className="form-div">
                            {/* <select
                              name="language_id"
                              //value={formdata.language_id}
                              value={InterpreterLanguage}
                              id="language_id"
                              onChange={(e) => OnDetailsChange(e)}
                            >
                              <option value="default" selected>
                                Select language of the person
                              </option>
                              {languages.map(function (language, i) {
                                return (
                                  <option
                                    className="category-link active"
                                    value={language._id}
                                  >
                                    {language.language}
                                  <option
                                    className="category-link active"
                                    value={language._id}
                                  >
                                    {language.language}
                                  </option>
                                );
                              })}
                            </select> */}
                            {/* <InputLabel id="demo-simple-select-label">
                              {InterpreterLanguageFromDropdown}
                            </InputLabel> */}
                            <NativeSelect
                              labelId="demo-simple-select-label"
                              label={InterpreterLanguageFromDropdown}
                              id="language_id"
                              className="NativeSelectDropdown"
                              onChange={(e) => OnDetailsChange(e)}
                            >
                              <option value="default" selected>
                                Select language
                              </option>
                              {languages.map(function (language, i) {
                                return language.availableInterprtercounter >
                                  0 ? (
                                  <option
                                    // className="category-link active"
                                    className="language_dropdown_elements"
                                    value={language._id}
                                  >
                                    &nbsp;&nbsp;&#128994;&nbsp;
                                    {language.language}
                                  </option>
                                ) : (
                                  <option
                                    // className="category-link active"
                                    className="language_dropdown_elements"
                                    value={language._id}
                                  >
                                    &nbsp;&nbsp;&#128308;&nbsp;
                                    {language.language}
                                  </option>
                                );
                              })}
                            </NativeSelect>
                          </div>
                        </Grid>
                      ) : (
                        ""
                      )}
                      {UserType == 1 ? (
                        <>
                          <Grid md={8}>
                            <form onSubmit={handleSubmit}>
                              <div className="form-div">
                                <input
                                  type="text"
                                  name="name"
                                  // value={formValues.name}
                                  className="form-input"
                                  id="name"
                                  value={formdata.name}
                                  placeholder="Enter name"
                                  required
                                  onChange={(e) => OnDetailsChange(e)}
                                />
                              </div>
                            </form>
                          </Grid>
                          <Grid md={8}>
                            <div className="form-div">
                              <input
                                type="text"
                                name="contact_number"
                                value={formdata.contact_number}
                                className="form-input"
                                id="contact_number"
                                placeholder="Enter Phone"
                                required
                                onChange={(e) => OnDetailsChange(e)}
                              />
                            </div>
                            {/* <p
                              style={{ color: "red" }}
                              className={formErrors.name ? "pform1" : "pform2"}
                            >
                              {formErrors.name}
                            </p> */}
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                      <Grid md={8}>
                        <div style={{ marginLeft: "85px" }}>
                          {/* <button onClick={userType == 2 ? interapi:AddUserCall()}> */}
                          {/* <button
                            type="submit"
                            className="proceedToAdd"
                            variant="filled"
                            onClick={
                              // (UserType == 2) ? (()=>{AddInterpreterInCall()}) : (()=>{AddUserCall()})
                              handleProceedToCall
                            }
                          >
                            Proceed to Call
                          </button> */}
                          <Button
                            variant="contained"
                            onClick={handleProceedToCall}
                          >
                            Proceed to Call
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Popup>
                <Popup
                  className="processingcall-modal contact-detail-popup"
                  trigger={
                    i == 0 ? (
                      <Button>
                        {/* <img src={User} style={{ width: "30px" }} /> */}

                        <PersonOutlineIcon
                          style={{
                            border: "2px solid #4d4d5b",
                            padding: "8px",
                            borderRadius: "10px",
                          }}
                        />
                      </Button>
                    ) : null
                  }
                >
                  <div className="modal interpreter ">
                    {getAllParticipants.map(function (participants, index) {
                      return (
                        <div className="header" key={index}>
                          <Grid container className="participants-contact">
                            <Grid item md={2}></Grid>
                            {/* <Grid item md={12} style={{border:"2px solid black"}}> */}
                            <Grid
                              container
                              xs={12}
                              style={{
                                // border: "2px solid black",
                                flexDirection: "row",
                              }}
                            >
                              <Grid
                                item
                                xs={8}
                                style={{
                                  justifyContent: "center",
                                }}
                              >
                                <span className="participants-name">
                                  {participants.user_docs.length > 0
                                    ? participants.user_docs[0].first_name +
                                      " " +
                                      participants.user_docs[0].last_name
                                    : participants.hearing_person_name}
                                </span>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                style={{
                                  justifyContent: "center",
                                }}
                              >
                                {/* check user_id !== null || user_id !== "" - > show video icon */}
                                {
                                  //  participants.user_type == 1 ||
                                  // participants.user_docs == [] ? (
                                  participants.user_id == null ||
                                  participants.user_id == "" ? (
                                    <PhoneEnabledIcon
                                      style={{
                                        marginLeft: "12px",
                                        color: "white",
                                      }}
                                    />
                                  ) : (
                                    //  <button key={index} onClick={()=>  AddUserToJoinCall(participants.user_id)}>h</button>}
                                    <Icon_whitevideocall
                                      style={{ marginLeft: "12px" }}
                                      key={index}
                                      onClick={() =>
                                        AddUserToJoinCall(participants.user_id)
                                      }
                                    />
                                  )
                                }
                              </Grid>
                              <Grid
                                item
                                xs={8}
                                style={{
                                  justifyContent: "center",
                                }}
                              >
                                <span
                                  className="participants-mobile"
                                  style={
                                    {
                                      // justifyContent:"center"
                                    }
                                  }
                                >
                                  {participants.user_docs.length > 0
                                    ? participants.user_docs[0].mobile_number
                                    : participants.hearing_person_contact_number}
                                </span>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                style={{
                                  justifyContent: "center",
                                }}
                              ></Grid>
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                  </div>
                </Popup>

                {row.map((buttonType, j) => {
                  return (
                    <>
                      <Box
                        key={`topbar_controls_j_${j}`}
                        ml={j === 0 ? 0 : 1.5}
                      >
                        {buttonType === topBarButtonTypes.RAISE_HAND ? (
                          <RaiseHandBTN />
                        ) : buttonType === topBarButtonTypes.CustomBtn ? (
                          <AddBTN />
                        ) : buttonType === topBarButtonTypes.MIC ? (
                          <MicBTN />
                        ) : buttonType === topBarButtonTypes.WEBCAM ? (
                          <WebcamBTN />
                        ) : buttonType === topBarButtonTypes.SCREEN_SHARE ? (
                          <ScreenShareBTN />
                        ) : buttonType === topBarButtonTypes.PARTICIPANTS ? (
                          <ParticipantsBTN />
                        ) : buttonType === topBarButtonTypes.CHAT ? (
                          <ChatBTN />
                        ) : //  : buttonType === topBarButtonTypes.ACTIVITIES ? (
                        //   <ActivitiesBTN />
                        // )
                        buttonType === topBarButtonTypes.END_CALL ? (
                          <EndCallBTN />
                        ) : buttonType === topBarButtonTypes.RECORDING ? (
                          <RecordingBTN />
                        ) : buttonType === topBarButtonTypes.HLS ? (
                          <HlsBTN />
                        ) : buttonType === topBarButtonTypes.GO_LIVE ? (
                          <GoLiveBTN />
                        ) : // ) : buttonType === topBarButtonTypes.WHITEBOARD ? (
                        //   <WhiteBoardBTN />
                        // ) : buttonType === topBarButtonTypes.ADD_LIVE_STREAM ? (
                        //   <AddLiveStreamBTN />
                        // ) : buttonType === topBarButtonTypes.CONFIGURATION ? (
                        //   <ConfigBTN />
                        null}
                      </Box>
                    </>
                  );
                })}
              </Box>
            </React.Fragment>
          );
        })}
      </Box>
    </Box>
  );
};

export default TopBar;
