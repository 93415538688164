import React,{useEffect,useState} from "react";
import moment from 'moment';
import ProfilePic from "../Assets/home-user-profile.svg";
import HomeChange from "../Assets/home-profile-change.svg";
import MyConProfile from "../Assets/my-contact-profile.svg";
import MultipleUser from "../Assets/multiple-user.svg";
import HomeCallHistory1 from "../Assets/home-call-history-1.svg";
import HomeCallHistory2 from "../Assets/home-call-history-1.svg";
import HomeCallHistory3 from "../Assets/home-call-history-1.svg";
import style from '../Assets/css/style.css'
import stylenew from '../Assets/css/substyle.css'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { MdLocationOn,MdPhone,MdStayCurrentPortrait,MdEmail ,MdOutlineLanguage} from "react-icons/md";
import {postDataFromApi,getDataFromApi,putDataFromApi} from '../Service/CommonService';
import {getprofile,getAuth} from '../Service/Helper';
import  Spinner_150 from "../Assets/Spinner-1s-150px.svg";
import SearchIcon from "../Assets/call-a-person-search-img.svg";
import Contact_img from "../Assets/call-a-person-contact-img.svg";
import EditBtn from "../Assets/edit-btn.svg"

const InterpreterProfilepage = (props) => {
	const [is_loaded,setLoaded]=useState(false)
	const [is_grouploaded,setgroupLoaded]=useState(false)
	const [mycontacts,setmycontacts]=useState([])
	const [mycallHistory,setmycallHistory]=useState([])
	const authdata=getAuth()
	// const userid=8;
	const userid=authdata['_id'];
	const contactDetails=[];
	useEffect(() => {
	    getMyContact();
	    getMyCalltHistory();
	}, []);
	const getMyCalltHistory = async () => {
		setLoaded(true);
	    var query = "user_id="+userid
	    const response = await postDataFromApi('callHistoryNew', query);
	    if(response.data.code && response.data.data!=null){
	    	setmycallHistory(response.data.data);
	    }
	    setLoaded(false);

	}
	const getMyContact = async () => {
		setgroupLoaded(true);
	    var query = "user_id="+userid+'&contactDetails=[]'
	    const response = await postDataFromApi('add/contactDetailsNew', query);
	    if(response.data.code && response.data.data!=null){
	    	setmycontacts(response.data.data);	
	    }
	    setgroupLoaded(false);
	}
	return(
		<div className="page-main">
			<div className="page-main-inner">
            <img  className="edit-img" src={EditBtn}/>    <span className="edit">Edit</span>
                <Box>
                 
			       <Grid container spacing={3} className="main-grid home-grid">
			          <Grid item md={4}  className="home-user-profile">
							<div className="box-shadow text-center radius-8px">
								<div className="home-user-profile-inner">
									<div className="home-user-profile-top-txt">
										<p className="blue-title">Profile</p>
									</div>
									<div className="home-user-profile-img">
									{/* <p className="profile_intial">{getprofile(authdata.first_name,authdata.last_name)}</p> */}
                                    <img src={Contact_img} className="profile_intial"/>
                                        <img className="intsa_icon" src={HomeChange}/>

									</div>
									<div className="profile_details">
									   <span>Hello</span>
									   <span className="prof-user-name">Amit Singh</span>
									</div>
								</div>
							</div>
				       </Grid>
				       <Grid item md={8} className="contact-info">
							<div className="box-shadow radius-8px">
								<div className="contact-info-inner add-padding-bott">
									<div className="blue-title">Contact Info</div>
									<div className="contact-info-detail">
									 
									   {/* <div><span>Phone:</span><span className="normal-gery-text">3456767678</span></div> */}
									   <div><MdPhone size="25px" color="#515151"/><span className="normal-gery-text">+1 999 9999 9999</span></div>
									   <div><MdEmail size="25px" color="#515151"/><span className="normal-gery-text">{authdata.email}</span></div>
									   <div><MdOutlineLanguage size="25px" color="#515151"/><span className="normal-gery-text">English</span></div>
									</div>
								</div>
							</div>
				       </Grid>
                      
				          
				    </Grid>
                    <Grid md={12} >
				<Button className="primary" variant="text"  style={{ marginTop: "4%" }}
				>Logout </Button>
				 </Grid>
				</Box>
			</div>
		</div>
	)
}

export default InterpreterProfilepage;