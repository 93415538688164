import React from "react";
import { Typography, Paper, Grid, Box, TextField, Select,Radio, RadioGroup,FormControlLabel, Button, Modal,makeStyles, } from "@material-ui/core";
import {
	Routes,
	Route,
	NavLink,
	HashRouter
} from "react-router-dom";
import Popup from 'reactjs-popup';
import Side_1 from "../../Assets/sidebar-6.svg";
import Side_2 from "../../Assets/sidebar-4.svg";
import Side_3 from "../../Assets/star.svg";
import Side_4 from "../../Assets/faq.svg";
import Side_5 from "../../Assets/ticket.svg";
import Side_6 from "../../Assets/break.svg";
import Style from "../../Assets/css/style.css";

const Main_sidebar= () =>{
	return (
		<div className="sidebar-main">
			<div className="sidebar-inner">
				<NavLink to="/CheckAudioVideo">
							<div className="sidebar-block">
								<div className="side-img">
									<img src={Side_1}/>
								</div>
								<div className="side-txt-bar">
									<p className="side-title">Check Audio and Video</p>
									{/* <p className="side-txt">Person I want to talk </p> */}
								</div>
							</div>
				</NavLink>
			
				<NavLink to="/CallHistory">
					<div className="sidebar-block">
						<div className="side-img">
							<img src={Side_2}/>
						</div>
						<div className="side-txt-bar">
							<p className="side-title">View Call History</p>
							<p className="side-txt">Check the calls you have made</p>
						</div>
					</div>
				</NavLink>
				<NavLink to="/Rating">
					<div className="sidebar-block">
						<div className="side-img">
							<img src={Side_3}/>
						</div>
						<div className="side-txt-bar">
							<p className="side-title">Ratings</p>
							<p className="side-txt">Check your ratings</p>
						</div>
					</div>
				</NavLink>
				<NavLink to="/Conference">
					<div className="sidebar-block">
						<div className="side-img">
							<img src={Side_5}/>
						</div>
						<div className="side-txt-bar">
							<p className="side-title">Tickets</p>
							{/* <p className="side-txt">Check your account balance</p> */}
						</div>
					</div>
				</NavLink>
				<NavLink to="/InterpreterBreak">
					<div className="sidebar-block">
						<div className="side-img">
							<img src={Side_6}/>
						</div>
						<div className="side-txt-bar">
							<p className="side-title">Break</p>
							<p className="side-txt">Need some break</p>
						</div>
					</div>
				</NavLink>
				<NavLink to="/CustomerSupport">
					<div className="sidebar-block">
						<div className="side-img">
							<img src={Side_4}/>
						</div>
						<div className="side-txt-bar">
							<p className="side-title">FAQ</p>
							<p className="side-txt">Need help with something?</p>
						</div>
					</div>
				</NavLink>
				
			</div>
		</div>
	)
}

export default Main_sidebar;