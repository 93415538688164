import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// import {
//  FormControlLabel,
//   Checkbox,
// } from '@mui/material/core'
import ReactStars from "react-rating-stars-component";
// import ListItemButton from '@mui/material/ListItemButton'
import SearchIcon from "../Assets/call-a-person-search-img.svg";
import Contact_img from "../Assets/call-a-person-contact-img.svg";
import Contact_img2 from "../Assets/call-a-person-contact-img2.svg";
import Contact_img3 from "../Assets/call-a-person-contact-img3.svg";
import { ReactComponent as Clock } from "../Assets/clock 1.svg";
import Spinner_150 from "../Assets/Spinner-1s-150px.svg";
import { ReactComponent as Icon_closegrey } from "../Assets/close-grey.svg";
import { Multiselect } from "multiselect-react-dropdown";
import {
  postDataFromApi,
  getDataFromApi,
  putDataFromApi,
} from "../Service/CommonService";
import { getprofile, getAuth } from "../Service/Helper";
import moment from "moment";
import { CallStatusUpdateData } from "../Service/videoskp";
import { TextField, Box } from "@material-ui/core";

import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const InterpreterFeedbackform = (props) => {
  const [is_Callloaded, setCallLoaded] = useState(false);
  const [is_show, setShow] = useState(false);
  const navigate = useNavigate();
  const authdata = getAuth();
  const userid = authdata["_id"];
  const callSetupid = localStorage.getItem("callSetupid");
  const [onBreak, setonBreak] = useState(false);
  const [open, setOpen] = useState(false);
  const [mycontacts, setmycontacts] = useState([]);
  const [mybreak, setmybreak] = useState([]);
  const [call_setup_id, setcall_setup_id] = useState("");
  /*const [formdata, setfromdata] = useState({ interpreter_id: 77, reason: "" })*/
  const [purpose, setpurpose] = useState([]);
  const [callFailureReason, setcallFailureReason] = useState([]);
  const [blockReason, setblockReason] = useState([]);
  const [langoptions, setlangoptions] = useState([]);
  const [failureReasonOpt, setfailureReasonOpt] = useState([]);
  const [selectedlanguages, setselectedlanguages] = useState([]);
  const [selectedPurpose, setSelectedPurpose] = useState([]);
  const [selectedcallFailureReasons, setselectedcallFailureReasons] = useState(
    []
  );
  const [popupOpen, setpopupOpen] = useState(false);
  const [Isblockchecked, setIsblockchecked] = useState(false);
  const [blockReasonCheckedList, setblockReasonCheckedList] = useState([]);
  const [blockReasonCheckedState, setblockReasonCheckedState] = useState(false);
  const [blockReasonIdList, setBlockReasonIdList] = useState([]);
  const [technicalIssueList, setTechnicalIssueList] = useState([
    { id: "1", label: "Technical Issue" },
    { id: "2", label: "Call" },
    { id: "3", label: "User" },
  ]);
  const [technicalIssueSelected, setTechnicalIssueSelected] = useState("");
  // const [formdata, setfrom data] = useState({ rate_video_quality: '', rate_interpreter: "", feedback: '', 'user_id': userid, user_type: authdata['user_type'], call_setup_id: call_setup_id ,purpose:''})
  const [formdata, setfromdata] = useState({
    rate_video_quality: "",
    rate_audio_quality: "",
    rate_user: "",
    feedback: "",
    user_id: userid,
    user_type: 1,
    call_setup_id: call_setup_id,
    purpose: "",
    callFailureReason: "",
    blockReason: "",
    issue: "",
  });

  const closeModal = () => {
    setpopupOpen(false);
    // setblockReasonCheckedList([])
    // setblockReasonCheckedState(false)
  };
  function CheckedFun() {
    setpopupOpen(true);
    setblockReasonCheckedList([]);
  }
  const CancelFun = () => {
    setpopupOpen(false);
    setblockReasonCheckedList([]);
    setBlockReasonIdList([]);
    setblockReasonCheckedState(false);
  };
  const BlockPermanentlyFun = () => {
    if (blockReasonCheckedList.length >= 1) {
      console.log(
        "blockReasonCheckedList.length",
        blockReasonCheckedList.length
      );
      setblockReasonCheckedState(true);
      setpopupOpen(false);
    } else {
      // swal({
      //   text:"please select atleast  one reason",
      //   icon: "success",
      // })
      alert("please select atleast  one reason");
    }
  };

  useEffect(() => {
    if (call_setup_id.length != 0) {
      console.log("call_setup_id_use_effect====>", call_setup_id);
    } else {
      console.log("call_setup_id_use_effect not provided");
      var id = sessionStorage.getItem("callSetupid")
      formdata["call_setup_id"] = id
    }
  }, []);

  const ratingChanged = (type, newRating) => {
    setfromdata((formdata) => ({
      ...formdata,
      [type]: newRating,
    }));

    console.log(formdata);
  };

  const sendfeedback = async () => {
    // var languages = [];
    // selectedlanguages.map((lang, i) => {
    //   languages.push(lang.label);
    //   console.log("selectedlanguages", selectedlanguages);
    // });
    var purposeList = [];
    selectedPurpose.map((purpose, i) => {
      purposeList.push(purpose.label);
      console.log("selectedPurpose", selectedPurpose);
    });
    var failureReason = [];
    selectedcallFailureReasons.map((f, i) => {
      failureReason.push(f.label);
      console.log("selectedcallFailureReasons", selectedcallFailureReasons);
    });
    var response = "";
    formdata["purpose"] = purposeList.join(",");
    // formdata["blockReason"] = blockReasonCheckedList.join(",");
    formdata["blockReason"] = blockReasonIdList;

    if (failureReason != []) {
      formdata["callFailureReason"] = failureReason.join(",");
    } else {
      formdata["callFailureReason"] = "";
    }
    console.log("technicalIssueSelected", technicalIssueSelected);

    if (technicalIssueSelected != "") {
      formdata["issue"] = technicalIssueSelected[0].label;
    } else {
      formdata["issue"] = "";
    }

    console.log("formdata", formdata);
    if (formdata["purpose"] !== "") {
      response = await postDataFromApi("add/callFeedbackReceived", formdata);
      if (response.data.code) {
        console.log(response.data.message);

        swal({
          // title: "client added!",
          text: response.data.message,
          icon: "success",
        }).then(function () {
          localStorage.setItem("callSetupid", "");
          sessionStorage.setItem("callSetupid", "");
          navigate("/home");
        });
      } else {
        swal({
          // title: "client added!",
          text: response.data.message,
          icon: "error",
        }).then(function () {
          navigate("/home");
        });
      }
    } else {
      alert("Please select a purpose");
    }
  };
  const updatestatus = async () => {
    const response = await CallStatusUpdateData([], 1, callSetupid, "4", "6");
    console.log("response", response);
    if (response.code) {
      console.log("response", response.data._id);
      setcall_setup_id(response.data._id);
      setfromdata((formdata) => ({
        ...formdata,
        call_setup_id: response.data._id,
      }));
    }
  };

  const getBlockReason = async () => {
    var query = { model: "blockReasonMasterData" };
    const response = await postDataFromApi("/all/getAllMaster", query);
    if (response.data.code && response.data.data != null) {
      setblockReason(response.data.data);

      // console.log("blockReason data", blockReason);
      console.log("blockReason", response.data.data);
    }
  };

  const getCallFailureReason = async () => {
    var query = { model: "callFailureReasonMasterData" };
    const response = await postDataFromApi("/all/getAllMaster", query);
    if (response.data.code && response.data.data != null) {
      setcallFailureReason(response.data.data);
      var callfail = [];
      response.data.data.map((lang, i) => {
        var reason = [];
        reason["id"] = lang._id;
        reason["label"] = lang.reason_name;
        callfail.push(reason);
      });
      setfailureReasonOpt(callfail);
      console.log("states data", response);
      console.log("purpose", response.data.data);
    }
  };
  const getpurpose = async () => {
    // var query = "model=LanguageMasterData"
    const response = await getDataFromApi("all/purpose");
    if (response.data.code && response.data.data != null) {
      setpurpose(response.data.data);
      var langopts = [];
      response.data.data.map((lang, i) => {
        var lg = [];
        lg["id"] = lang._id;
        lg["label"] = lang.purpose;
        langopts.push(lg);
      });
      setlangoptions(langopts);
      console.log("states data", response);
      console.log("purpose", response.data.data);
    }
  };
  useEffect(() => {
    console.log("langoptions", langoptions);
  }, [langoptions]);

  const handlechange = (e) => {
    var value = e.target.value;
    console.log("value", value);
    setfromdata((formdata) => ({
      ...formdata,
      [e.target.name]: value,
    }));
  };
  useEffect(() => {
    getBlockReason();
    getCallFailureReason();
    updatestatus();
    setselectedlanguages();
    setSelectedPurpose([]);
    window.history.replaceState(
      "InterpreterFeedbackform#",
      "",
      "/#/InterpreterFeedbackform"
    );
    // localStorage.setItem("callSetupid", "");
    getpurpose();
  }, []);

  useEffect(() => {
    console.log("blockReasonCheckedState", blockReasonCheckedState);
  }, [blockReasonCheckedState]);

  function checkboxChange(e, elemnttype = "") {
    var value = e.target.value;
    // var blockReasonsingleId=blockReason.findIndex(item=>item.reason_name==e.target.value)
    var blockReasonsingleId =
      blockReason[
        blockReason.findIndex((item) => item.reason_name == e.target.value)
      ]["_id"];
    // var blockReasonIdList=[]
    console.log("blockReasonsingleId new===>", blockReasonsingleId);
    console.log("blockReasonIdList new===>", blockReasonIdList);

    if (blockReasonCheckedList.includes(e.target.value)) {
      const index = blockReasonCheckedList.indexOf(e.target.value);
      if (index > -1) {
        blockReasonCheckedList.splice(index, 1); // 2nd parameter means remove one item only
        blockReasonIdList.splice(index, 1);
      }
    } else {
      blockReasonCheckedList[blockReasonCheckedList.length] = e.target.value;
      blockReasonIdList[blockReasonIdList.length] = blockReasonsingleId;
    }

    console.log("sample...>", blockReasonCheckedList, blockReasonIdList, e);
    if (elemnttype == "checkbox") {
      if (e.target.checked) {
        value = 1;
      } else {
        value = 0;
      }
    }
    setfromdata((formData) => ({
      ...formData,
      [e.target.name]: value,
    }));
    console.log("e.target.name", e.target.name);
  }
  return (
    <div className="page-main">
      <div style={{ padding: "25px" }}>
        <div className="feeback-title">Call With User Ended</div>

        <Grid
          elevation={1}
          className="call-person-details "
          style={{ marginTop: "2%", marginBottom: "2%" }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={1}
            md={11}
            spacing={4}
            className="contact-info"
            style={{ margin: "2%" }}
          >
            <Grid
              item
              md={4}
              className="contact-info"
              style={{ height: "125px" }}
            >
              <div className="interpreter-feedback-inner">
                <div className="feedbackinfo-inner add-padding-bott">
                  <div className="feedback-inner-title">
                    Rate the Video Quality
                  </div>
                  <div
                    className="contact-info-detail"
                    style={{ marginTop: "13px" }}
                  >
                    <div className="feedback-start-div">
                      <ReactStars
                        count={5}
                        onChange={(e) => ratingChanged("rate_video_quality", e)}
                        size={30}
                        // color= "#92B8E5"
                        activeColor="#ffd700"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              md={4}
              className="contact-info"
              style={{ height: "125px" }}
            >
              <div className="interpreter-feedback-inner">
                <div className="feedbackinfo-inner add-padding-bott">
                  <div className="feedback-inner-title">
                    Rate the Audio Quality
                  </div>
                  <div
                    className="contact-info-detail"
                    style={{ marginTop: "13px" }}
                  >
                    <div className="feedback-start-div">
                      <ReactStars
                        count={5}
                        onChange={(e) => ratingChanged("rate_audio_quality", e)}
                        size={30}
                        // color= "#92B8E5"
                        activeColor="#ffd700"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              md={4}
              className="contact-info"
              style={{ height: "125px" }}
            >
              <div className="interpreter-feedback-inner">
                <div className="feedbackinfo-inner add-padding-bott">
                  <div className="feedback-inner-title">Rate the user</div>
                  <div
                    className="contact-info-detail"
                    style={{ marginTop: "13px" }}
                  >
                    <div className="feedback-start-div">
                      <img src={Contact_img2} style={{ width: "30px" }} />
                      <ReactStars
                        count={5}
                        onChange={(e) => ratingChanged("rate_user", e)}
                        size={30}
                        //   color= "#92B8E5"
                        activeColor="#ffd700"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            {/* <Grid item md={4} className='report-dropdown-grid'>
							<div className="interpreter-feedback-inner-dropdown">
								<div className="form-div">
									<label>Report Issue</label>
									<select
										name="language"
										className="language"
										id="language">
										<option value="default" selected disabled>Report Issue</option>
										<option value="1">Technical Issue</option>
										<option value="2">Call</option>
										<option value="3">User</option>
									</select>

								</div>
							</div>
						</Grid> */}
            <Grid
              item
              md={4}
              className="report-dropdown-grid"
              style={{ height: "110px" }}
            >
              <div className="interpreter-feedback-inner">
                <div className="feedbackinfo-inner add-padding-bott">
                  <div className="feedback-inner-title">
                    Report Issue(optional)
                  </div>
                  <div className="textbox" style={{ marginTop: "8px" }}>
                    {/* <select name="reason" className="reason" id="reason" >
                      <option value="default" selected disabled>
                        Report Issue
                      </option>
                      <option value="1">Technical Issue</option>
                      <option value="2">Call</option>
                      <option value="3">User</option>
                    </select> */}
                    <Multiselect
                      showArrow
                      hidePlaceholder={true}
                      singleSelect={true}
                      displayValue="label"
                      showCheckbox={true}
                      // onSelect={(e) => setTechnicalIssueSelected(e, "label")}
                      onSelect={(e) => setTechnicalIssueSelected(e, "id")}
                      placeholder="Report Issue"
                      // onSearch={(event) => {}}
                      options={technicalIssueList}
                      selectedValues={technicalIssueSelected}
                      //selectedValues={(e) => purposechanged("purpose", e)}
                    />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              md={4}
              className="report-dropdown-grid"
              style={{ height: "120px" }}
            >
              <div className="interpreter-feedback-inner">
                <div className="feedbackinfo-inner add-padding-bott">
                  <div className="feedback-inner-title">
                    Enter Comments(optional)
                  </div>
                  <div className="textbox" style={{ marginTop: "8px" }}>
                    <TextField
                      name="feedback"
                      style={{ width: "90%" }}
                      disableUnderline
                      variant="outlined"
                      className="textbox-style"
                      placeholder="Enter Text"
                      onChange={(e) => handlechange(e)}
                    />
                  </div>
                </div>
              </div>
            </Grid>

            <Grid
              item
              md={4}
              className="report-dropdown-grid"
              style={{ height: "110px" }}
            >
              <div className="interpreter-feedback-inner">
                <div className="feedbackinfo-inner add-padding-bott">
                  <div className="feedback-inner-title">Purpose&nbsp;*</div>
                  <div className="textbox" style={{ marginTop: "8px" }}>
                    {/* <select
										name="purpose"
										className="purpose"
										onChange={(e)=>handlechange(e)}
										>
										<option value="default" selected disabled>purpose</option>
										
										{
											purpose.map(function(purpose,i){
												return (
													<option value={purpose.purpose}>{purpose.purpose}</option>
													)
											})
										}
										
									</select> */}
                    <Multiselect
                      showArrow
                      hidePlaceholder={true}
                      displayValue="label"
                      showCheckbox={true}
                      // onSelect={(e) => setselectedlanguages(e, "id")}
                      // onRemove={(e) => setselectedlanguages(e, "id")}
                      onSelect={(e) => setSelectedPurpose(e, "id")}
                      onRemove={(e) => setSelectedPurpose(e, "id")}
                      placeholder="Select Purpose"
                      onSearch={(event) => {}}
                      options={langoptions}
                      selectedValues={selectedPurpose}
                      //selectedValues={(e) => purposechanged("purpose", e)}
                    />
                  </div>
                </div>
              </div>
            </Grid>

            <Grid
              item
              md={4}
              className="report-dropdown-grid"
              style={{ height: "110px" }}
            >
              <div className="interpreter-feedback-inner">
                <div className="feedbackinfo-inner add-padding-bott">
                  <div className="feedback-inner-title">
                    Call Failure Reason(optional)
                  </div>
                  <div className="textbox" style={{ marginTop: "8px" }}>
                    <Multiselect
                      showArrow
                      hidePlaceholder={true}
                      displayValue="label"
                      showCheckbox={true}
                      onSelect={(e) => setselectedcallFailureReasons(e, "id")}
                      onRemove={(e) => setselectedcallFailureReasons(e, "id")}
                      placeholder="Select Reason"
                      onSearch={(event) => {}}
                      options={failureReasonOpt}
                      selectedValues={selectedcallFailureReasons}
                      //selectedValues={(e) => purposechanged("purpose", e)}
                    />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              md={4}
              className="report-dropdown-grid"
              style={{ height: "110px" }}
            >
              <div className="interpreter-feedback-inner">
                <div className="feedbackinfo-inner add-padding-bott">
                  <div
                    className="feedback-inner-title"
                    style={{ marginTop: "32px", marginLeft: "37px" }}
                  >
                    <input
                      type="checkbox"
                      id="need-inter"
                      name="need-inter"
                      className="need-inter"
                      value="need-inter"
                      //checked={blockReasonCheckedList.length>=1}
                      disabled={blockReasonCheckedState}
                      checked={blockReasonCheckedState}
                      // onClick={(e) =>setpopupOpen(e.target.checked)}
                      onClick={CheckedFun}
                      // onChange={(e) =>setpopupOpen(e.target.checked)}
                    />
                    Block Reason
                  </div>
                  <Popup
                    className="processingcall-modal blockReason"
                    modal
                    // open={popupOpen}
                    open={popupOpen}
                    closeOnDocumentClick
                    onClose={closeModal}
                  >
                    <div>
                      {blockReason.map(function (failure, i) {
                        return (
                          <FormControlLabel
                            control={<Checkbox color="primary" />}
                            label={failure.reason_name}
                            name=""
                            value={failure.reason_name}
                            onChange={(e) => checkboxChange(e, "checkbox")}
                          />
                        );
                      })}
                    </div>
                    <div>
                      <Button
                        onClick={CancelFun}
                        style={{ width: "100px" }}
                        className="primary"
                        variant="text"
                      >
                        Cancel
                      </Button>
                      <Button
                        size="small"
                        onClick={BlockPermanentlyFun}
                        style={{ width: "200px", marginLeft: "20px" }}
                        className="primary"
                        variant="text"
                      >
                        Block Permanently
                      </Button>
                    </div>
                  </Popup>
                  {/* <div className="textbox" style={{ marginTop: "8px" }}>
                    <select
                      name="reason"
                      className="reason"
                      onChange={(e) => handlechange(e)}
                    >
                      <option value="default" selected disabled>
                        Block Reason
                      </option>

                      {blockReason.map(function (failure, i) {
                        return (
                          <option value={failure.reason_name}>
                            {failure.reason_name}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid container  className="contact-info" >
					  
					</Grid> */}
        <Grid className="feeback-btn" md={4}>
          <Button
            className="primary"
            variant="text"
            style={{ marginTop: "4%" }}
            onClick={sendfeedback}
          >
            Submit{" "}
          </Button>
        </Grid>
      </div>
    </div>
  );
};

export default InterpreterFeedbackform;
