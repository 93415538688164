import React,{useEffect,useState} from "react";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography, TextField, Select, Radio, RadioGroup, FormControlLabel, Button, Modal,  } from "@material-ui/core";
import Icon_rupeesimage from "../../Assets/rupeesimg.svg";
import {postDataFromApi,getDataFromApi,putDataFromApi} from '../../Service/CommonService';
import {getprofile,getAuth} from '../../Service/Helper';
import { useNavigate } from 'react-router-dom'

const ViewAllplans_page = () => {
	//https://signabledevapi.disctesting.in/api/all/ActivesubscriptionPlansList
	const navigate = useNavigate()
	const authdata=getAuth()
	const user_balance_call_minutes=authdata['user_balance_call_minutes']
	
	const [subscriptionlist,setsubscriptionlist]=useState([])
	const getsubscriptionlist = async () => {
		    var query = ""
		    const response = await getDataFromApi('all/ActivesubscriptionPlansList', query);
		    console.log('subscriptionlist',response)
		    if(response.data.code && response.data.data!=null){
		    	setsubscriptionlist(response.data.data);
		    }
    }

    useEffect(() => {
		    getsubscriptionlist();
		}, []);
	return (
		<div className="page-main">
			<div className="page-main-inner viewaccount_balance">
			    <Box>
			       <Grid container spacing={3} className="main-grid">
			          <Grid item md={8}  className="view-balance">
			              <div className="box-shadow text-center radius-8px" style={{height: "100%"}}>
			                 <div className="walletbalance">
			                    <Grid container spacing={3}>
			                       <Grid item md={4}  className="view-balance">
                                       <div>Wallet Balance</div>
                                   </Grid>
                                   <Grid item md={4}  className="view-balance">
			                           <img src={Icon_rupeesimage} />
                                   </Grid>
                                   <Grid item md={4}  className="view-balance">
			                           <div className="amount">{user_balance_call_minutes}</div>
                                   </Grid>
			                    </Grid>
			                   
			                   
			                 </div>
			                 <div className="call-person-tab-header">
								<h3>Choose A Plan</h3>
							</div>
							{subscriptionlist.map((subscriptionlist, index) => (
								<div className="plans">
									<div className="plans-amt">{subscriptionlist.plan_title} {subscriptionlist.amount}/-</div>
									<div className="plansdetail">
									   <div className="plansdetail_inner">
									      <ul>
									        <li>{subscriptionlist.call_minutes} Mins</li>
									        <li>{subscriptionlist.subscription_duration} Days</li>
									      </ul>
									   </div>
									   <Button className="primary" variant="text"  >Buy Now</Button>
									</div>
								</div>
							))}
							
			              </div>
			          </Grid>
			       </Grid>
			    </Box>
				
			</div>
		</div>
	)
}

export default ViewAllplans_page;