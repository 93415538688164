import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "../../Assets/css/style.css";
import { Typography, Button, Box } from "@material-ui/core";
import Webcam from "react-webcam";

function Videotesting() {
  const [webconst, setWebconst] = useState(false);
  return (
    <>
      <div>
        <Typography variant="h6" className="video-text-css">Test Video</Typography>
      </div>
      <div className="cameratest-main">
        <div className="cameratest-inner1">
          <Button
          // color="primary"
          // color="#2760A3"
          style={{backgroundColor:"#2760A3",color:"white"}}
            variant="contained"
            className="video_micbutton1 buttonrad"
            onClick={() => {
              // setWebconst(!webconst);
              setWebconst(true);
            }}
          >
            Start Testing
          </Button>
          <Button
          // color="primary"
          // color="#2760A3"
          style={{backgroundColor:"#2760A3",color:"white"}}
            variant="contained"
            className="video_stop_micbutton1 buttonrad"
            onClick={() => {
              // setWebconst(!webconst);
              setWebconst(false);
            }}
          >
            Stop
          </Button>
        </div>
        
        <div className="cameratest-inner2">
          {webconst ? <Webcam height={"auto"} width={"70%"} className="webcamcss box-shadow"/> : null}
        </div>
      </div>
    </>
  );
}

export default Videotesting;
